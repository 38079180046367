import SIgnUpBox from "./SIgnUpBox";
import "./SignUpDesktop.css";
import React from "react";
import LEftGrfx from '../../images/LEftGrfx.png'
import summitLogo from './../../images/summitlogo@2x.png'
import mobileLogoFrame from '../../images/mobile_frame.svg'
const SignUpDesktop = (props) => {
    return (
        <div className="sign-up-desktop">
            <main className="frame-left-graphic">
                <img
                    className="leftgrfx-icon signup-leftgrfx-icon"
                    loading="eager"
                    alt=""
                    src={LEftGrfx}
                    style={{ minHeight: 'fit-content' }}
                />
                <img
                    className="mobile_logo_frame"
                    loading="eager"
                    alt=""
                    src={mobileLogoFrame}
                />
                <div className="signin-main">
                    <SIgnUpBox {...props} />
                    <div className="top-header-frame">
                        <img
                            className="logo-1-icon"
                            loading="eager"
                            alt=""
                            src={summitLogo}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default SignUpDesktop;