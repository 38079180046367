import React from "react";
import "./TitleOfStudy.css";
import * as appConstants from "../../AppConstants";
import { Study } from "../../Models/Study";
import { getLoggedUser } from "../../Storage/Getter";
import { getStudy } from "../../Api/StudyApi";
import { useNavigate, useParams } from "react-router-dom";
import get from "../../Api/Config/GetConfig";
import { ValidatorForm } from "react-material-ui-form-validator";
import * as validator from "../../Helper/Validator";
import NextPrevButton from './../../Components/NextPrevButton';
import SingleTextInput from "../../Components/SingleTextInput";
import Grid from '@mui/material/Grid';

const TitleOfStudy = (props) => {
    const formRef = React.useRef(null);
    const [expanded, setExpanded] = React.useState("");
    const navigate = useNavigate();
    const { studyId } = useParams();
    const [studyObj, setStudyObj] = React.useState(new Study());
    const loggedUser = getLoggedUser();
    const [pageList, setPageList] = React.useState({ data: [] });
    const currentPageCode = appConstants.study_title_pagecode;


    React.useEffect(() => {
        if (studyId) {
            get(getStudy(studyId)).then(function (res) {
                setStudyObj(res);
                if (res?.mode_of_data_entry) {
                    pageList["data"] = appConstants.project_flow[res.mode_of_data_entry];
                    setPageList({ "data": appConstants.project_flow[res.mode_of_data_entry] });
                }
            });
        } else {
            setStudyObj((prevState) => ({
                ...prevState,
                mode_of_data_entry: appConstants.manualValue,
                user_id: loggedUser.id,
            }));
        }
    }, []);

    const handleUserInput = (event) => {
        setStudyObj((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
        if (event.target.value) {
            pageList["data"] = appConstants.project_flow[appConstants.manualValue];
            setPageList({ "data": appConstants.project_flow[appConstants.manualValue] });
        }
    };
    return (
        <div className="title-frame">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                        <div className="back">
                            <NextPrevButton currentPageCode={currentPageCode} data={studyObj} pageList={pageList.data} showNext={false} />
                        </div>
                    }
                </Grid>
                <Grid item>
                    {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                        <div className="back-button-frame">
                            <NextPrevButton currentPageCode={currentPageCode} data={studyObj} pageList={pageList.data} showNext={true} triggerToastMessage={props.triggerToastMessage} />
                        </div>
                    }
                </Grid>
            </Grid>

            <ValidatorForm ref={formRef} style={{ width: '100%' }}>
                <div className="content-frame">
                    <div className="what-is-the">{appConstants.study_title}</div>
                    {/* <div className="a-brief-description">
                        A brief description of this section can go here
                    </div> */}
                </div>
                <div className="titleofstudy-inputfields">
                    <div className="username">
                        <div className="text-field-wrapper">
                            <div className="text-field" style={{ width: "100%" }}>
                                <div className="text-field1" style={{ width: "100%" }}>
                                    <SingleTextInput
                                        placeholder="Title"
                                        title=""
                                        name="title"
                                        type="text"
                                        value={studyObj.title}
                                        validators={validator.required.validators}
                                        errorMessages={validator.required.errorMessages}
                                        onChange={handleUserInput}
                                    />
                                </div>
                                <div className="text-field1" style={{ width: "100%" }}>
                                    <SingleTextInput
                                        placeholder="A brief description about your study,"
                                        title=""
                                        name="description"
                                        type="text"
                                        multiLine
                                        rows={4}
                                        value={studyObj.description}
                                        // validators={validator.required.validators}
                                        // errorMessages={validator.required.errorMessages}
                                        onChange={handleUserInput}
                                    />
                                </div>
                                <div className="text-field1" style={{ width: "100%" }}>
                                    <SingleTextInput
                                        placeholder="Mention the keywords about your research, which will make your research appear when people search"
                                        title=""
                                        name="key_words"
                                        type="text"
                                        value={studyObj.key_words}
                                        multiLine
                                        rows={2}
                                        // validators={validator.required.validators}
                                        // errorMessages={validator.required.errorMessages}
                                        onChange={handleUserInput}
                                    />
                                </div>
                                <div className="supporting-text">
                                    <div className="supporting-text1">Supporting text</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidatorForm>
        </div >
    );
};

export default TitleOfStudy;
