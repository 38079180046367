export class Study {
    disorder_research_question = "";
    disorder_diagnosis = "";
    disorder_settings_age_type = "";
    disorder_settings_age_type_from = "";
    disorder_settings_age_type_to = "";
    disorder_settings_date_of_registration_type = "";
    disorder_settings_date_of_registration_type_from = "";
    disorder_settings_date_of_registration_type_to = "";
    statistical_test_type = 2;
    statistical_test_normality_kolmogorov = false;
    statistical_test_missing_variables = false;
    statistical_test_demographic_frequency = false;
    statistical_test_demographic_median = false;
    statistical_test_demographic_mean = false;
    statistical_test_group = "";
    statistical_test_between_group = "";
    statistical_test_between_single_group = "";
    statistical_test_other_tests = "";
    output_type = 0;
    description = "";
    title = "";
    diagnosis = [];
    created_by = "";
    updated_by = "";
    user_id = 0;
    type = "";
    sub_type = "";
    no_of_times = "";
    no_of_times_examining = "";
    trial = "";
    open_trial = "";
    cross_sectional_design = "";
    longitudinal_design = "";
    timepoints = [];
    is_finished = false;
    is_deleted = false;
    mode_of_data_entry = "";
    test_independent = false;
    test_mannwhitney = false;
    test_anova_oneway = false;
    test_kruskal = false;
    test_chi = false;
    test_pearson = false;
    test_spearman = false;
    test_pairedttest = false;
    test_wilcoxon = false;
    test_anova_repeated = false;
    test_friedman = false;
    test_saphiro = false;
}
