//Screen 2
import React from "react";
import { FormControlLabel } from "@mui/material";
import * as appConstants from "../../AppConstants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Study } from "../../Models/Study";
import { getStudy } from "../../Api/StudyApi";
import { useParams } from "react-router-dom";
import get from "../../Api/Config/GetConfig";
import NextPrevButton from "../../Components/NextPrevButton";
import { Grid, Paper } from "@mui/material";

function StudySubTypePage(props) {
    const { studyId } = useParams();
    const [studyObj, setStudyObj] = React.useState(new Study());
    const currentPageCode = appConstants.study_subtype_pagecode;

    React.useEffect(() => {
        if (studyId) {
            get(getStudy(studyId)).then(function (res) {
                setStudyObj(res);
            });
        }
    }, []);


    const handleChange = (event) => {
        setStudyObj((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };



    return (
        <div className="title-frame">
            {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                            showNext={false}
                        />
                    </Grid>
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                            showNext={true}
                        />
                    </Grid>
                </Grid>
            }
            <div className="content-frame">
                <div className="what-is-the">{appConstants.crosssectional_question}</div>
                {/* <div className="a-brief-description">
                    A brief description of this section can go here
                </div> */}
            </div>

            <div className="study_type_testlist-cards">
                <div className="study_type_radio-parent">
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="sub_type"
                        onChange={handleChange}
                        value={studyObj.sub_type}
                    >
                        {appConstants.crosssectional_options?.map((option, index) => (
                            <div className="study_type_radio" key={index + 1}>
                                <FormControlLabel title={option?.content} value={option?.value} control={<Radio />} label={option?.label} />
                            </div>
                        ))}
                    </RadioGroup>
                </div>
            </div>
        </div >
    );
}
export default StudySubTypePage;
