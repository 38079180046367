import { MenuItem, Menu, Box, Divider, Stack, Button } from "@mui/material";
import "./LogoHeader.css";
import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import vector2 from './../../images/vector-2.svg';
import userProfileImg from './../../images/profilephoto@2x.png';
import { styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import { getLoggedUser } from "../../Storage/Getter";
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import MailIcon from '@mui/icons-material/Mail';
import get from "../../Api/Config/GetConfig"
import { getUserPointsTransactions } from "../../Api/UserApi";
import CircularProgress from '@mui/material/CircularProgress';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  color: "white",
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const LogoHeader = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorEl1);
  const [loggedUser, setLoggedUser] = useState({});
  const [pointsTransactions,setPointsTransactions] = React.useState([]);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickNotification = (event) => {
    setPointsTransactions([]);
    setAnchorEl1(event.currentTarget);
    get(getUserPointsTransactions(loggedUser.id)).then(function (res) {
      if(res.length > 0){
          setPointsTransactions(res);
      }
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    setLoggedUser(getLoggedUser())
  }, [])

  const handleCloseNotification = (action) => {
    setAnchorEl1(null)
  }

  const handleClose = (action) => {
    switch (action) {
      case 'upgrade_plan':
        navigate('/plans')
        break;
      case 'addPoints':
        props.handleClickOpenPointsDialog()
        break;
      case 'pointsTransactions':
        navigate('/pointsTransactions')
        break;
      case 'profile':
        navigate('/userprofile/' + loggedUser?.id)
        break;
      case 'change_password':
        navigate('/changePassword')
        break;
      case 'logout':
        props.logoutUser();
      default:
        break;
    }
    setAnchorEl(null);
  };

  const openServicesDialog = (row) => {
    let service = {"total_points":row.points_deducted,"services":row.services}
    if(row.transaction_type == "recharge"){
      service["total_points"] = row.points_added;
      service["services"] = [{"description":row.payment_id,"points":row.points_added}];
    }
    props.setSelectedService(service);
    props.handleClickOpenServices(true);
  }

  return (
    <header className="logoheader">
      <div className="priism-parent">
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            color: 'white',
            marginLeft: '-10px',
            px: [1],
          }}
        >
          {/* <IconButton onClick={toggleDrawer} style={{ color: 'white', marginTop: '-10px' }}>
            {toggleOpen ? <ChevronLeftIcon />
              : <MenuIcon />}
          </IconButton> */}
        </Toolbar>
        <b className="priism" style={{ cursor: 'pointer' }} onClick={() => navigate('/studyList')}>SHODH-AI</b>
        {/* <img
          className="frame-child"
          loading="eager"
          alt=""
          src={vector2}
        /> */}
      </div>
      <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <div className="profile-card">
            <img className="profilephoto-icon" alt="" src={loggedUser.google_profile_img} onClick={handleClick} />
            <div className="text">
              <div className="vivek-shastri" onClick={handleClick}>{(loggedUser?.first_name ?? '') + " " + (loggedUser?.last_name ?? '')}</div>
              <p style={{ margin: '0px' }}>
                  <Chip className={(props.pointsAvailable > 0)?'animate-positive-points':'animate-negative-points'} label={props.pointsAvailable} size="small" color={(props.pointsAvailable > 0)?'success':'warning'} onClick={() => handleClose('addPoints')}/> pts <ControlPointIcon onClick={() => handleClose('addPoints')}/>
                  <MailIcon sx={{"color": "white"}}
                    aria-controls={openNotification ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openNotification ? 'true' : undefined}
                    disableElevation
                    onClick={handleClickNotification}
                  />
              </p>
            </div>
            <div>
              <Menu
                id="points-menu"
                MenuListProps={{
                  'aria-labelledby': 'points-button',
                }}
                anchorEl={anchorEl1}
                open={openNotification}
                onClose={handleCloseNotification}
              >
                {(pointsTransactions.length < 1) &&
                  <MenuItem>
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress />
                    </Box>
                  </MenuItem>
                }
                {pointsTransactions.map((row,index) => (
                    <MenuItem key={index} onClick={() => {handleCloseNotification();openServicesDialog(row)}} disableRipple 
                    sx={{'opacity': '0.7','backgroundColor': 'darkcyan'}}>
                      <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity={(row.transaction_type == 'recharge')?'success':'error'}>
                          <AlertTitle>
                              {(row.transaction_type == 'recharge') ? 
                                  <Typography variant="subtitle2" gutterBottom>
                                    {row.points_added +' pts added'}
                                  </Typography>
                              :   <Typography variant="subtitle2" gutterBottom>
                                  {row.points_deducted +' pts deducted'}
                                  </Typography>}
                            </AlertTitle>
                            <Typography variant="caption" display="block" gutterBottom>
                              {moment(row.created_on).format('DD-MM-YYYY, h:mm a')}
                            </Typography>
                        </Alert>
                      </Stack>
                    </MenuItem>
                ))}
              </Menu>
            </div>
            <ExpandMore
              expand={anchorEl}
              aria-expanded={anchorEl}
              aria-label="show more"
              onClick={handleClick}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {/*<MenuItem className="menuitem" onClick={() => handleClose('profile')}>
            Profile
        </MenuItem>*/}
          <MenuItem className="menuitem" onClick={() => handleClose('addPoints')}>
          <ControlPointIcon/>&nbsp;Add Points
          </MenuItem>
          <MenuItem className="menuitem" onClick={() => handleClose('pointsTransactions')}>
            <ReceiptLongIcon/>&nbsp;Transaction History
          </MenuItem>
          <Divider style={{ padding: '0px !important' }} />
          <MenuItem className="menuitem" onClick={() => handleClose('logout')} style={{ justifyContent: 'flex-end' }}>
            <Stack direction="row" spacing={0.90}>
              <LogoutIcon fontSize="medium" />
              <div className="logout_menu">
                Logout
              </div>
            </Stack>
          </MenuItem>
        </Menu>
      </React.Fragment>
    </header>
  );
};

export default LogoHeader;
