
export const getShortName = (vn, selected) => {
    console.log(selected)
    vn = vn.trim()
    if (vn.length > 7) {
        if (vn.indexOf('_') > 0) {
            let with_us = vn.split('_')
            let mv = []
            if (with_us?.length > 0) {
                for (let c in with_us) {
                    if (with_us[c]?.length > 0) {
                        mv.push(with_us[c].slice(0, 3))
                    }
                }
                vn = String(mv.slice(0, 2)).slice(0, 7).replace(",", "_")
            }
        } else {
            vn = vn.slice(0, 7)
        }
    }
    let count = 0
    if (selected.has(vn)) {
        vn = vn.split('')
        vn.splice(-1, 1, 1);
        vn = vn.join("")
    }
    while (selected.has(vn)) {
        console.log(vn[vn.length - 1], count)
        count += 1
        if (vn[vn.length - 1] == count) {
            let tempVn = vn.split('')
            tempVn[tempVn.length - 1] = parseInt(tempVn[tempVn.length - 1]) + 1
            vn = tempVn.join('')
            // count += 1
        } else {
            vn += count
        }
    }
    return vn
}