import * as React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DrawerHeader from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import MailIcon from "@mui/icons-material/Mail";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { mainListItems, secondaryListItems } from "./listItems";
import shodhai_screen from '../../images/shodhai_screen.svg'
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import AboutPage from "../AboutPage";
import ContactPage from "../ContactPage";
import SignInDesktop from "../SignIn/SignInDesktop";
import ProtectedRoute from "../../ProtectedRoute";
import { getLoggedUser } from "../../Storage/Getter";
import { logoutUser } from "../../Api/UserApi";
import { removeAuthToken, removeLoggedUser } from "../../Storage/Clear";
import get from "../../Api/Config/GetConfig";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import StudyListScreen from "./../study-list/StudyListScreen";
import OutcomeVariablePage from "../outcome-variable/OutcomeVariablePage";
import ExplanatoryVariablePage from "../explanatory-variable/ExplanatoryVariablePage";
import StudySubTypePage from "../study-sub-type/StudySubTypePage";
import StudyTimesPage from "../study-times/StudyTimesPage";
import GroupVariablePage from "../group-variable/GroupVariablePage";
import StatisticalTestVariablesPage from "../statistical-test-variables/StatisticalTestVariablesPage";
import ReviewTestPage from "../ReviewTestPage";
import OutputPage from "../OutputPage";
import StudyTimesExaminedPage from "../study-times-examined-page/StudyTimesExaminedPage";
import ForgotPasswordPage from "../forgot-password/ForgotPasswordPage";
import ChangePasswordPage from "../ChangePasswordPage";
import SignUpDesktop from "../SignUp/SignUpDesktop"
import UserProfilePage from "../UserProfile";
import LogoHeader from "./LogoHeader";
import LeftMenuFrameComponent from "./LeftMenuFrameComponent";
import summitLogo from './../../images/summitlogo@2x.png';
import TitleOfStudy from "../title-of-study/TitleOfStudy";
import StatisticalTests from "../statistical-tests/StatisticalTests";
import TimePoints from "../time-points/TimePoints";
import Spreadsheet from "../spread-sheet/SpreadSheet";
import PrivacyPolicy from "../PrivacyPolicy";
import TermsOfUse from "../TermsOfUse";
import RefundPolicy from "../RefundPolicy";
import AddPointsPage from "../AddPointsPage";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PointsTransactionsPage from "../PointsTransactionsPage";
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import StudyTypePage from "../study-type/StudyTypeScreen";
import StudyVariableListPage from "../StudyVariableListPage";

const drawerWidth = 230;

// const settings = ["Profile", "Account", "Dashboard", "Logout"];

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        height: 'max-content',
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const mdTheme = createTheme();

function PaperComponent(props: PaperProps) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
}

function DashboardContent() {
    const [open, setOpen] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [showLeftNavBar, setShowLeftNavBar] = React.useState(false);
    const [pointsAvailable, setPointsAvailable] = React.useState(0);
    const [requiredPoints,setRequiredPoints] = React.useState(0);
    const [finalPoints,setFinalPoints] = React.useState(0);
    const [toastMessage, setToastMessage] = React.useState({
        isOpen: false,
        text: "",
        level: "success",
    });
    const navigate = useNavigate();

    const [openPointsDialog, setOpenPointsDialog] = React.useState(false);
    const [openInsufficient, setOpenInsufficient] = React.useState(false);
    const [runTests, setRunTests] = React.useState(false);
    const [selectedService, setSelectedService] = React.useState({"total_points":0,"services":[]});
    const [openServicesDialog, setOpenServicesDialog] = React.useState(false);

    const handleClickOpenPointsDialog = () => {
        setOpenPointsDialog(true);
    };

    const handleCloseOpenPointsDialog = () => {
        setOpenPointsDialog(false);
    };

    const handleClickOpenIns = () => {
        setOpenInsufficient(true);
    };

    const handleClickCloseIns = () => {
        setOpenInsufficient(false);
    };

    const handleClickOpenServices = () => {
        setOpenServicesDialog(true);
    }

    const handleClickCloseServices = () => {
        setOpenServicesDialog(false);
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const showNavBar = (val) => {
        setShowLeftNavBar(val);
    };

    const triggerToastMessage = (obj) => {
        setToastMessage(obj);
    };

    const closeToast = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setToastMessage((prevState) => ({
            ...prevState,
            isOpen: false,
        }));
    };

    const logout = () => {
        get(logoutUser()).then(function (result) {
            removeAuthToken();
            removeLoggedUser();
            showNavBar(false);
            navigate("/");
        });
    };

    const upgradePlan = () => {
        navigate("/plans");
    }

    const changePassword = () => {
        navigate("/changePassword");
    }

    const userProfile = () => {
        let loggedUser = getLoggedUser()
        navigate('/userprofile/' + loggedUser?.id)
    }

    return (
        <>
        <Dialog
            open={openPointsDialog}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Add points</DialogTitle>
            <DialogContent>
                <DialogContentText>
                <AddPointsPage
                    pointsAvailable={pointsAvailable}
                    setPointsAvailable={setPointsAvailable}
                    requiredPoints={requiredPoints}
                    setRequiredPoints={setRequiredPoints}
                    finalPoints={finalPoints}
                    setFinalPoints={setFinalPoints}
                    handleCloseOpenPointsDialog={handleCloseOpenPointsDialog}
                    handleClickOpenPointsDialog={handleClickOpenPointsDialog}
                    handleClickOpenIns={handleClickOpenIns}
                    handleClickCloseIns={handleClickCloseIns}
                    setRunTests={setRunTests}
                    triggerToastMessage={triggerToastMessage}
                />
                </DialogContentText>
            </DialogContent>
        </Dialog>
        <Dialog
            open={openInsufficient}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Insufficient balance
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        To run these tests, {requiredPoints} points are needed. But, you have only {pointsAvailable} points. Please click on 'add points' to buy additional points
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="success" onClick={() => {handleClickCloseIns();handleClickOpenPointsDialog();}} autoFocus>
                        Add points
                    </Button>
                    <Button variant="contained" color="error" onClick={() => {handleClickCloseIns();navigate('/studyList')}}>
                        Cancel
                    </Button>
                </DialogActions>
        </Dialog>
        <Dialog
            open={openServicesDialog}
            fullWidth={true}
            fullScreen
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClickCloseServices} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Services
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <DialogContentText>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Points</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedService.services.map((row,index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                        {row.description}
                                        </TableCell>
                                        <TableCell align="right">{row.points} pts</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">Total points : {selectedService.total_points} pts</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
        </Dialog>
        <Snackbar
            open={toastMessage.isOpen}
            autoHideDuration={6000}
            onClose={closeToast}
            key={"bottom center"}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            <Alert
                onClose={closeToast}
                severity={toastMessage.level}
                sx={{ width: "100%" }}
            >
                {toastMessage.text}
            </Alert>
        </Snackbar>
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex", height: '10vh' }}>
                <CssBaseline />
                {showLeftNavBar ? (
                    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        // open={open}
                        style={{ backgroundColor: "#fff" }}
                    >
                        {/* <Toolbar
                            style={{ color: "#04395ecc" }}
                            sx={{
                                pr: "24px", // keep right padding when drawer closed
                            }}
                        >*/}
                        <LogoHeader
                            toggleDrawer={toggleDrawer}
                            toggleOpen={open}
                            pointsAvailable={pointsAvailable}
                            handleClickOpenPointsDialog={handleClickOpenPointsDialog}
                            showLeftNavBar={(status) => showNavBar(status)}
                            selectedService={selectedService}
                            setSelectedService={setSelectedService}
                            handleClickOpenServices={handleClickOpenServices}
                            logoutUser={logout}
                        />
                        {/* <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Research Module
                            </Typography> */}
                        {/*<IconButton style={{ color: "#04395ecc" }}>
                                <Badge badgeContent={4}>
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            <IconButton style={{ color: "#04395ecc" }}>
                                <Badge
                                    style={{ color: "#04395ecc" }}
                                    badgeContent={99}
                                >
                                    <MailIcon />
                                </Badge>
                            </IconButton>*/}
                        {/* <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton
                                        onClick={handleOpenUserMenu}
                                        sx={{ p: 0 }}
                                    >
                                        <Avatar
                                            alt="Remy Sharp"
                                            src="/static/images/avatar/2.jpg"
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: "45px" }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem key={"profile"} onClick={userProfile}>
                                        <Typography textAlign="center">
                                            Profile
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem key={"change_password"} onClick={changePassword}>
                                        <Typography textAlign="center">
                                            Change Password
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem key={"upgrade"} onClick={upgradePlan}>
                                        <Typography textAlign="center">
                                            Upgrade Plan
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem key={"logout"} onClick={logout}>
                                        <Typography textAlign="center">
                                            Logout
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Box> */}
                        {/* </Toolbar> */}
                    </AppBar>
                ) : null}
                {/* {(showLeftNavBar && isSubscribed) ? (
                    <Drawer open={open}
                        variant="permanent" >
                        <Toolbar />
                        <LeftMenuFrameComponent />
                    </Drawer>
                ) : null} */}
                <Box
                    component="main" style={{ backgroundColor: '#fff' }}
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        // height: "100vh",
                        // overflow: "auto",
                    }}
                >
                    {showLeftNavBar &&
                        <Toolbar />
                    }
                    <Box className='body_box'
                    //     style={{
                    //     backgroundImage: `url(${shodhai_screen})`,
                    //     backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                    //     maxHeight: '90vh',
                    //     minHeight: '90vh'
                    // }}
                    >
                        <Routes>
                            <Route
                                exact
                                path="/"
                                element={
                                    // <SignInPage
                                    //     showNavBar={showNavBar}
                                    //     triggerToastMessage={
                                    //         triggerToastMessage
                                    //     }
                                    // />
                                    <SignInDesktop
                                        showNavBar={showNavBar}
                                        setPointsAvailable={setPointsAvailable}
                                        triggerToastMessage={
                                            triggerToastMessage
                                        }
                                    />
                                }
                            ></Route>
                            <Route
                                exact
                                path="/privacy"
                                element={<PrivacyPolicy />}
                            ></Route>
                            <Route
                                exact
                                path="/terms"
                                element={<TermsOfUse />}
                            ></Route>
                            <Route
                                exact
                                path="/refund"
                                element={<RefundPolicy />}
                            ></Route>
                            <Route
                                exact
                                path="/about"
                                element={<AboutPage />}
                            ></Route>
                            <Route
                                exact
                                path="/contact"
                                element={<ContactPage />}
                            ></Route>
                            <Route
                                exact
                                path="/studyList"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    path="/studyList"
                                    element={<StudyListScreen />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/signup"
                                element={
                                    <SignUpDesktop
                                        triggerToastMessage={
                                            triggerToastMessage
                                        }
                                    />

                                }
                            ></Route>
                            <Route
                                exact
                                path="/forgotPassword"
                                element={
                                    <ForgotPasswordPage
                                        triggerToastMessage={
                                            triggerToastMessage
                                        }
                                    />
                                }
                            ></Route>
                            <Route
                                exact
                                path="/changePassword"
                                element={
                                    <ChangePasswordPage
                                        triggerToastMessage={
                                            triggerToastMessage
                                        }
                                    />
                                }
                            ></Route>
                            <Route
                                exact
                                path="/userprofile/:userId"
                                element={
                                    <UserProfilePage
                                        triggerToastMessage={
                                            triggerToastMessage
                                        }
                                    />
                                }
                            ></Route>
                            <Route
                                exact
                                path="/createStudy"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/createStudy"
                                    element={<TitleOfStudy
                                        triggerToastMessage={
                                            triggerToastMessage
                                        }
                                    />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/study/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/study/:studyId"
                                    element={<TitleOfStudy />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/pointsTransactions"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    path="/pointsTransactions"
                                    element={<PointsTransactionsPage />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/studyType/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/studyType/:studyId"
                                    element={<StudyTypePage />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/studySubType/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/studySubType/:studyId"
                                    element={<StudySubTypePage />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/studyTimes/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/studyTimes/:studyId"
                                    element={<StudyTimesPage />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/studyExamining/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/studyExamining/:studyId"
                                    element={<StudyTimesExaminedPage />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/timepoints/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/timepoints/:studyId"
                                    element={<TimePoints />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/groupVariable/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/groupVariable/:studyId"
                                    element={<GroupVariablePage />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/outcomeVariable/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/outcomeVariable/:studyId"
                                    element={<OutcomeVariablePage
                                        triggerToastMessage={
                                            triggerToastMessage
                                        }
                                    />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/explanatoryVariable/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/explanatoryVariable/:studyId"
                                    element={<ExplanatoryVariablePage />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/spreadSheet/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/spreadSheet/:studyId"
                                    element={<Spreadsheet />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/statTests/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/statTests/:studyId"
                                    element={<StatisticalTests />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/statTestVariables/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/statTestVariables/:studyId"
                                    element={<StatisticalTestVariablesPage
                                        triggerToastMessage={
                                            triggerToastMessage
                                        }
                                    />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/reviewTests/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/reviewTests/:studyId"
                                    element={<ReviewTestPage />}
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/runTests/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/runTests/:studyId"
                                    element={<OutputPage
                                        pointsAvailable={pointsAvailable}
                                        setPointsAvailable={setPointsAvailable}
                                        requiredPoints={requiredPoints}
                                        setRequiredPoints={setRequiredPoints}
                                        finalPoints={finalPoints}
                                        setFinalPoints={setFinalPoints}
                                        handleCloseOpenPointsDialog={handleCloseOpenPointsDialog}
                                        handleClickOpenPointsDialog={handleClickOpenPointsDialog}
                                        handleClickOpenIns={handleClickOpenIns}
                                        handleClickCloseIns={handleClickCloseIns}
                                        runTests={runTests}
                                        setRunTests={setRunTests}
                                        triggerToastMessage={
                                            triggerToastMessage
                                        } />
                                    }
                                ></Route>
                            </Route>
                            <Route
                                exact
                                path="/studyVariableList/:studyId"
                                element={<ProtectedRoute />}
                            >
                                <Route
                                    exact
                                    path="/studyVariableList/:studyId"
                                    element={<StudyVariableListPage />}
                                ></Route>
                            </Route>
                        </Routes>
                    </Box>
                </Box>
            </Box>
            {/* {(showLeftNavBar === false) &&
                <footer className="logo-frame">
                    <img
                        className="summitlogo-icon"
                        loading="eager"
                        alt=""
                        src={summitLogo}
                    />
                </footer>} */}
        </ThemeProvider>
    </>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}
