//Screen 2
import React from "react";
import { FormControlLabel } from "@mui/material";
import * as appConstants from "../../AppConstants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Study } from "../../Models/Study";
import { getStudy } from "../../Api/StudyApi";
import { useParams } from "react-router-dom";
import get from "../../Api/Config/GetConfig";
import NextPrevButton from "../../Components/NextPrevButton";
import { Grid, Paper } from "@mui/material";

function StudyTimesPage() {
    const { studyId } = useParams();
    const [studyObj, setStudyObj] = React.useState(new Study());
    const [selectOptions, setSelectOptions] = React.useState([]);
    const currentPageCode = appConstants.study_groups_pagecode;

    React.useEffect(() => {
        if (studyId) {
            get(getStudy(studyId)).then(function (res) {
                setStudyObj(res);
                optionSetting(res);
            });
        }
    }, []);

    const optionSetting = (study) => {
        if (study.type == appConstants.study_type[0].value) {
            setSelectOptions(appConstants.case_control_study_options);
        }
        else if (study.type == appConstants.study_type[1].value) {
            setSelectOptions(appConstants.longitudional_study_options);
        }
    }

    const handleChange = (event) => {
        setStudyObj((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    return (
        <div className="title-frame">
            {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            showNext={false}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                        />
                    </Grid>
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            showNext={true}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                        />
                    </Grid>
                </Grid>
            }
            <div className="content-frame">
                <div className="what-is-the">{appConstants.case_control_study_question}</div>
                <div className="a-brief-description">
                    A brief description of this section can go here
                </div>
            </div>

            <div className="study_type_testlist-cards">
                <div className="study_type_radio-parent">
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="no_of_times"
                        onChange={handleChange}
                        value={studyObj.no_of_times}
                    >
                        {selectOptions.map((option, index) => (
                            <div className="study_type_radio">
                                <FormControlLabel title={option?.content} value={option?.value} control={<Radio />} label={option?.label} />
                            </div>
                        ))}
                    </RadioGroup>
                </div>
            </div>
        </div >
    );
}
export default StudyTimesPage;
