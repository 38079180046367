import React, { useCallback } from "react";
import { getLoggedUser} from "../Storage/Getter";
import get from "../Api/Config/GetConfig";
import { Button, Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getUserPointsTransactions } from "../Api/UserApi";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import moment from 'moment';
import { Box } from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

export default function PointsTransactionsPage(props) {

    const [open, setOpen] = React.useState(false);
    const loggedUser = getLoggedUser();
    const navigate = useNavigate("");
    const [pointsTransactions,setPointsTransactions] = React.useState([]);
    const [services,setServices] = React.useState([]);
    const [overallPoints,setOverallPoints] = React.useState(0);
    
    React.useEffect(() => {
        get(getUserPointsTransactions(loggedUser.id)).then(function (res) {
            if(res.length > 0){
                setPointsTransactions(res);
            }
        });
    }, []);

    const openDialog = (row) => {
        if(row.transaction_type == "recharge"){
            setOverallPoints(row.points_added);
            let service = [{"description":row.payment_id,"points":row.points_added}]
            setServices(service);
        }
        else{
            setOverallPoints(row.points_deducted);
            setServices(row.services);
        }
        setOpen(true);
    }

    const handleClose = () => {
        setServices([]);
        setOpen(false)
    }

    return (
        <> 
            <Dialog
                open={open}
                fullWidth={true}
                fullScreen
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Services
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Points</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {services.map((row,index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                            {row.description}
                                            </TableCell>
                                            <TableCell align="right">{row.points} pts</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right">Total points : {overallPoints} pts</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Transaction type</TableCell>
                                    <TableCell align="right">Points added</TableCell>
                                    <TableCell align="right">Points redeemed</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pointsTransactions.map((row,index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{moment(row.created_on).format('DD-MM-YYYY, h:mm a')}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {(row.transaction_type == 'recharge')?'Added':'Redeemed'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {(row.points_added) &&
                                                <Typography component="div" variant="body1">
                                                    <Box sx={{ color: 'success.main' }}>
                                                        <ControlPointIcon/> {row.points_added}
                                                    </Box>
                                                </Typography>
                                            }
                                        </TableCell>
                                        <TableCell align="right">
                                            {(row.points_deducted) &&
                                                <Typography component="div" variant="body1">
                                                    <Box sx={{ color: 'error.main' }}>
                                                        <RemoveCircleIcon/> {row.points_deducted}
                                                    </Box>
                                                </Typography>
                                            }
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" color="primary" onClick={() => openDialog(row)}>
                                                <VisibilityIcon/>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}