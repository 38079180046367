import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import get from '../Api/Config/GetConfig';
import { getStudies } from '../Api/StudyApi';
import { Button, IconButton } from '@mui/material';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { getLoggedUser } from '../Storage/Getter';
import { getStudyVariables } from '../Api/StudyVariableApi';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import put from '../Api/Config/PutConfig';
import { updateStudyVariable } from '../Api/StudyVariableApi';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import * as appConstants from "../AppConstants";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function StudyVariableListPage(props) {
  
    const [studyVariableList,setStudyVariableList] = React.useState([]);
    const navigate = useNavigate();
    const { studyId } = useParams();
    const [selectedIndex,setSelectedIndex] = React.useState(null);

    React.useEffect(() => {
        get(getStudyVariables(studyId)).then(function(result){
        if(result.length > 0)
        {
            setStudyVariableList(result);
        }
        })
    },[]);

    const sortSeq = (sv) => {
        sv.map((studyVariable,index) => {
            studyVariable.seq = parseInt(index+1);
        });
        setStudyVariableList(sv);
    }

    const moveUp = (index) => {
        let sv = [...studyVariableList];
        let downValue = sv[index];
        let topValue = sv[index-1];
        sv[index-1] = downValue;
        sv[index] = topValue;
        sortSeq(sv);
    }

    const moveDown = (index) => {
        let sv = [...studyVariableList];
        let topValue = sv[index];
        let downValue = sv[index+1];
        sv[index+1] = topValue;
        sv[index] = downValue;
        sortSeq(sv);
    }

    const gotoPreviousPage = (sId) => {
        navigate("/identifierVariable/" + sId);
    };

    const gotoNextPage = (sId) => {
        for (var i in studyVariableList) {
            saveStudyVariable(studyVariableList[i]);
        }
    };

    const saveStudyVariable = (studyVariable) => {
        put(updateStudyVariable(studyVariable.id), studyVariable).then(
            function (result) {
                if (result) {
                    navigate("/spreadSheet/" + studyId);
                }
            }
        );
    };

    const handleUserInput = (event, index) => {
        let sv = [...studyVariableList];
        sv[index][event.target.name] = event.target.value;
        if(event.target.name == "type" && event.target.value == appConstants.STUDY_TYPE_IDENTIFIER)
        {
            sv[index]["sub_type"] = "";
        }
        setStudyVariableList(sv);
    };

    const handleUserInputOptions = (event,index,subindex) => {
        let sv = [...studyVariableList];
        sv[index][event.target.name][subindex] = event.target.value;
        setStudyVariableList(sv);
    }
  
    return(
        <React.Fragment>
            <Paper sx={{ my: 1, p: 2 }} elevation={8}>
                <div className="wrapper">
                    <h2 className="question">{appConstants.study_variable_summary_title}</h2>
                </div>
                <Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Sub Type</TableCell>
                                    <TableCell>Options</TableCell>
                                    <TableCell>Sequence</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {studyVariableList.map((study,index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                    {(selectedIndex == index)
                                    ?
                                        <TextField
                                            label=""
                                            name="name"
                                            type="text"
                                            value={study.name}
                                            onChange={(event) => {handleUserInput(event,index)}}
                                            fullWidth
                                            autoFocus
                                        />  
                                    :
                                    study.name
                                    }</TableCell>
                                    <TableCell>
                                        {((selectedIndex == index) && study.type != appConstants.STUDY_TYPE_GROUP)
                                        ?
                                            <Select
                                                value={study.type}
                                                label=""
                                                name="type"
                                                onChange={(event) => {handleUserInput(event,index)}}
                                            >
                                                {appConstants.STUDY_TYPE.map((type) => (
                                                    <MenuItem
                                                        key={type}
                                                        value={type}
                                                    >
                                                    {type}
                                                    </MenuItem>
                                            ))}
                                            </Select>
                                        :study.type}
                                    </TableCell>
                                    <TableCell>
                                        {((selectedIndex == index) && (study.type != appConstants.STUDY_TYPE_GROUP) && (study.type != appConstants.STUDY_TYPE_IDENTIFIER))
                                        ?
                                            <Select
                                                value={study.sub_type}
                                                label=""
                                                name="sub_type"
                                                onChange={(event) => {handleUserInput(event,index)}}
                                            >
                                                {appConstants.STUDY_SUB_TYPE.map((type) => (
                                                    <MenuItem
                                                        key={type}
                                                        value={type}
                                                    >
                                                    {type}
                                                    </MenuItem>
                                            ))}
                                            </Select>
                                        :study.sub_type}
                                    </TableCell>
                                    <TableCell>
                                        {study.options.map((option,subindex) => {
                                            return(
                                                (selectedIndex == index)?
                                                <TextField
                                                    label=""
                                                    name="options"
                                                    type="text"
                                                    value={option}
                                                    onChange={(event) => {handleUserInputOptions(event,index,subindex)}}
                                                    fullWidth
                                                    autoFocus
                                                /> 
                                                :<>
                                                    <Chip label={option}/><br/>
                                                </>
                                                
                                            )
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton variant="contained" color="warning" onClick={() => moveUp(index)} disabled={(index == 0)}>
                                            <ArrowUpwardIcon/>
                                        </IconButton>
                                        {study.seq}
                                        <IconButton variant="contained" color="warning" onClick={() => moveDown(index)} disabled={((index+1) == studyVariableList.length)}>
                                            <ArrowDownwardIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        {(selectedIndex == index)
                                        ?
                                            <IconButton variant="contained" color="success" onClick={() => setSelectedIndex(null)}>
                                                <SaveIcon/>
                                            </IconButton>
                                        :   <IconButton variant="contained" color="success" onClick={() => setSelectedIndex(index)}>
                                                <ModeEditIcon/>
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <Button
                        className="previous"
                        size="small"
                        onClick={() => {
                            gotoPreviousPage(studyId);
                        }}
                    >
                        PREVIOUS
                    </Button>
                    <Button
                        size="small"
                        className="next"
                        onClick={() => {
                            gotoNextPage(studyId);
                        }}
                    >
                        NEXT
                    </Button>
                </Box>
            </Paper>
        </React.Fragment>
    );
}