import React,{useRef,useState} from 'react';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import * as appConstants from "../AppConstants";
import { Study } from '../Models/Study';
import get from '../Api/Config/GetConfig';
import xhrGet from '../Api/Config/XhrGetConfig';
import NextPrevButton from "../Components/NextPrevButton";
import { getStudy, runTestsStudy, updateStudy } from '../Api/StudyApi';
import { getUserErrorLog } from '../Api/UserApi';
import Alert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Paper, MenuItem, FormControl, InputLabel } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { getAuthToken, getLoggedUser } from '../Storage/Getter';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import { setLoggedUser } from '../Storage/Setter';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function OutputPage(props){
    const navigate = useNavigate();
    const { studyId } = useParams();
    const [studyObj, setStudyObj] = React.useState(new Study());
    const currentPageCode = appConstants.output_pagecode;
    const [outputUrl, setOutputUrl] = React.useState('');
    const [docs,setDocs] = useState([]);
    const [alertMsg, setAlertMsg] = React.useState({"severity":"","msg":"","msgStrong":"","circularPgr":false});
    const [showOutputError, setShowOutputError] = React.useState(false);
    const [execTests, setExecTests] = React.useState(false);
    const loggedUser = getLoggedUser();
    const [services, setServices] = React.useState([]);
    const [overallPoints, setOverallPoints] = React.useState(0);
    const [openPts, setOpenPts] = React.useState(false);

    const handleClickOpenPts = () => {
        setOpenPts(true);
    };

    const handleClosePts = () => {
        setOpenPts(false);
    };

    React.useEffect(() => {
        get(getStudy(studyId)).then(function (res) {
            res.is_finished = true;
            setStudyObj(res);
        });
        runOutputTest(1);
    },[])

    const runOutputTest = (pointsOverview) => {
        if(!pointsOverview){
            setAlertMsg({"severity":"info","msg":"Running — ","msgStrong":"Statistical Tests! (Max time : 1 min)","circularPgr":true})
            setServices([]);
            handleClosePts();
            props.handleClickCloseIns();
            props.setRunTests(false);
        }
        get(runTestsStudy(studyId,pointsOverview)).then(function (res) {
            let result = res
            if(result?.status == 500)
            {
                setServices([]);
                get(getUserErrorLog(loggedUser.id)).then(function (resp) {
                    setShowOutputError(true);
                    let message = resp["json"]["message"];
                    message = message.replaceAll("src.user_management_module.configs.CustomException","")
                    message = message.replaceAll(".OutputInternalServerError:","")
                    message = message.replaceAll("OutputInternalServerError","")
                    document.getElementById("outputErrorMsg").innerHTML = '<h1>Exception</h1><p>' + message + '</p>';
                    setAlertMsg({"severity":"error","msg":"Test failed.Please check below","msgStrong":"","circularPgr":false});
                });
            }
            if(pointsOverview && result.services.services.length > 0){
                setOverallPoints(result.services.overall_points);
                props.setRequiredPoints(result.services.overall_points);
                let fPoints = props.pointsAvailable - result.services.overall_points;
                props.setFinalPoints(fPoints);
                setServices(result.services.services);
                if(fPoints < 0)
                {
                    props.handleClickOpenIns();
                }
                else
                {
                    handleClickOpenPts();
                }
            }
            else
            {
                setServices([]);
                if(result?.generated)
                {
                    setExecTests(true);
                    let main_url = process.env.REACT_APP_MAIN_URL;
                    let documents = [];
                    let url = main_url+appConstants.DOCS_FOLDER+"/"+studyId+"/"+result.file_name+".docx"
                    setOutputUrl(url);
                    documents.push({ uri: url});
                    setDocs(documents);
                    let user = loggedUser;
                    user.points_balance = result.points_balance;
                    setLoggedUser(JSON.stringify(user));
                    props.setPointsAvailable(result.points_balance);
                }
                else
                {
                    setAlertMsg({"severity":"error","msg":"Test failed","msgStrong":"","circularPgr":false})
                }
            }
        });
    }

    const downloadOutputFile = () => {
        window.open(outputUrl,"_blank");
    }

    const gotoAddPointsPage = () => {
        props.handleClickOpenPointsDialog();
    }

    return(  
        <>
            {(props.runTests) && 
                <Button variant="contained" color="success" onClick={() => {runOutputTest(0)}} sx={{
                    'marginTop': '125px',
                    'marginLeft': '40%'}}>
                    Click here to Run tests
                </Button>
            }
            <Dialog
                open={openPts}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This test will consume {overallPoints} points and will be deducted from your balance. Would you like to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {handleClosePts();navigate('/studyList');}}>No</Button>
                    <Button onClick={() => {runOutputTest(0)}} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
            {(!execTests) && (services.length < 1) &&
                <>
                    <br></br>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity={alertMsg.severity}>
                            <AlertTitle>Tests</AlertTitle>
                                {alertMsg.msg}<strong>{alertMsg.msgStrong}</strong>
                                {(alertMsg.circularPgr)&&
                                <Box sx={{ display: 'flex' }}>
                                    <CircularProgress color="success" />
                                </Box>
                                }    
                        </Alert>
                    </Stack>
                </>
            }
            {(showOutputError) && (services.length < 1) &&
                <>
                    <br/>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity={'error'}>
                            <AlertTitle id="outputErrorMsg"></AlertTitle>    
                        </Alert>
                    </Stack>
                </> 
            }
            {(execTests) && (services.length < 1) &&
                <div className="title-frame">
                    {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item sx={{"display":"inline-flex"}}>
                                <NextPrevButton
                                    currentPageCode={currentPageCode}
                                    data={studyObj}
                                    pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                                    showNext={false}
                                />
                                <Button onClick={downloadOutputFile} variant="text" color="success">
                                    <DownloadIcon/> Download
                                </Button>
                            </Grid>
                            <Grid item>
                                <NextPrevButton
                                    currentPageCode={currentPageCode}
                                    data={studyObj}
                                    pageList={appConstants.project_flow[studyObj?.mode_of_data_entry]}
                                    showNext={true}
                                />
                            </Grid>
                        </Grid>
                    }
                    <DocViewer 
                        documents={docs} 
                        pluginRenderers={DocViewerRenderers} 
                        config={{
                            header: {
                                disableHeader: true,
                                disableFileName: true,
                                retainURLParams: false
                            }
                        }}
                        style={{width: '100%', height: '75vh'}}/>
                </div>
            }
        </>
    )

}