import React from "react";
import "./StudyListCardComponent.css";
import deleteIcon from './../../images/delete.svg';
import downloadIcon from './../../images/download.svg';
import crossSectionalIcon from './../../images/crossSectional.svg'
import { Button } from '@mui/material';
const StudyListCardComponent = ({ data, editFunc, deleteStudyFunc, downloadStudyFunc }) => {
  return (
    <div className="research-title-parent">
      <div className="research-title-label">Research Title</div>
      <div className="research-title">{data.title}</div>
      {/* <div className="test-type">{data.type}</div> */}
      <div className="retinal-vasculature-and">
        {/* {data.type} */}
      </div>
      <img className="frame-item" alt="" src={deleteIcon} onClick={() => { deleteStudyFunc(data) }} />
      <img className="frame-inner" alt="" src={downloadIcon} onClick={() => downloadStudyFunc(data)} />
      <Button className="mainbuttons button-text1" onClick={() => { editFunc(data) }}>
        Edit
      </Button>
      <div className="frame-wrapper">
        <div className="research-type-label">Test Type</div>
        <div className="group-parent">
          <img className="group-icon" alt="" src={crossSectionalIcon} />
          <div className="cross-sectional">{data?.type}</div>
        </div>
      </div>
    </div>
  );
};

export default StudyListCardComponent;
