import { Button, Grid } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import get from '../Api/Config/GetConfig';
import { getStudy } from '../Api/StudyApi';
import { getStudyVariables } from '../Api/StudyVariableApi';
import { Study } from '../Models/Study';
import {Paper} from "@mui/material";

export default function ReviewTestPage(props){

    const [reviewText, setReviewText] = React.useState(null);
    const navigate = useNavigate();
    const { studyId } = useParams();
    const [studyObj,setStudyObj] = React.useState(new Study());
    const [studyVariableList,setStudyVariableList] = React.useState([]);


    const getVariablesList = (variables) => {
        var obj = {"outcomeVariables" : [], "groupVariables" : []}
        for(var i in variables)
        {
            let sv = variables[i];
            if((sv.type == 'outcome') && (sv.sub_type == 'continuous'))
            {
                obj["outcomeVariables"].push(sv.name)
            }
            else if(sv.type == 'group')
            {
                obj["groupVariables"].push(sv.options);
            }
        }
        return obj;
    }

    const goToPrevious = () => {
		navigate("/statTests/" + studyId);
	};
    
    const gotoNextPage = () => {
		navigate("/runTests/" + studyId);
	};

    React.useEffect(() => {
        
        Promise.all([
            get(getStudy(studyId)),
            get(getStudyVariables(studyId)),
        ]).then((res) => {
            setStudyObj(res[0]);
            setStudyVariableList(res[1]);
            var obj = getVariablesList(res[1]);
            console.log(obj)
            var msg = ''
            if(studyObj.statistical_test_between_group_two == 'independent'){
                msg += 'Independent t test - to examine the difference in '
            }
            else if(studyObj.statistical_test_between_group_two == 'mann-whitney'){
                msg += 'Mann Whitney test - to examine the difference in '
            }
            if(obj['outcomeVariables'].length > 0)
            {
                msg += '{ '+obj['outcomeVariables'].join()+' }'
            }
            if(obj['groupVariables'].length > 0)
            {
                msg += " between "
                msg += '{ '+obj['groupVariables'].join(" AND ")+' }'
            }
            setReviewText(msg)
        });
    },[])

    return (
        <>
            <Paper sx={{ my: 1, p: 2 }} elevation={8}>
                <p>{reviewText}</p>
                <Grid container>
                    <Grid item xs={12}>
                        <Button
                            className="previous"
                            size="small"
                            onClick={goToPrevious}
                        >
                            PREVIOUS
                        </Button>
                        <Button size="small" className="next" onClick={gotoNextPage}>
                            NEXT
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );

}