export const required = { validators: ['required'], errorMessages: ['Required'] }

export const alphabets = { validators: ['matchRegexp:^[A-Za-z]+$'], errorMessages: ['Only Alphabets allowed'] }
export const email = { validators: ['isEmail'], errorMessages: ['Email is not valid'] }
export const numbers = { validators: ['matchRegexp:^[0-9]+$'], errorMessages: ['Only number allowed'] }
export const otp = { validators: ['matchRegexp:^[0-9]{6}$'], errorMessages: ['Only numbers allowed with 6 characters'] }
export const short_name = { validators: ['matchRegexp:^[a-zA-Z0-9_]{0,7}$'], errorMessages: ['Only alphabets,numbers and underscore allowed with 7 characters'] }

export const requiredAlphabets = { validators: ['required', 'matchRegexp:^[A-Za-z ]+$'], errorMessages: ['Required', 'Only Alphabets allowed'] }
export const requiredPassword = { validators: ['required', 'matchRegexp:(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'], errorMessages: ['Required', 'Min 8 character required.Must contain Lowercase,Uppercase,Number,Special Character $@!%*?&.'] }
export const emailRequired = { validators: ['required', 'isEmail'], errorMessages: ['Required', 'Email is not valid'] }
export const requiredNumbers = { validators: ['required', 'matchRegexp:^[0-9]+$'], errorMessages: ['Required', 'Only Numbers allowed'] }