import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getAuthToken } from './Storage/Getter';

const ProtectedRoute = () => {
    
    const authToken = getAuthToken();
    
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return authToken ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedRoute