import * as React from "react";
import { Chip, FormControl, InputLabel, MenuItem, Select, IconButton, selectClasses, Button } from "@mui/material";
import { Study } from "../../Models/Study";
import { StudyTest } from "../../Models/StudyTest";
import { useNavigate, useParams } from "react-router-dom";
import get from "../../Api/Config/GetConfig";
import post from "../../Api/Config/PostConfig";
import { getStudy } from "../../Api/StudyApi";
import { getStudyTestByStudyId, createStudyTests, updateStudyTest } from "../../Api/StudyTestApi";
import put from "../../Api/Config/PutConfig";
import * as appConstants from "../../AppConstants";
import NextPrevButton from "../../Components/NextPrevButton";
import './StatisticalTestVariablesPage.css'
import { getStudyOutcomeContinuousVariables, getStudyVariables } from './../../Api/StudyVariableApi';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';

export default function StatisticalTestVariablesPage(props) {
    const navigate = useNavigate();
    const { studyId } = useParams();
    const [studyObj, setStudyObj] = React.useState(new Study());
    const [studyVariables, setStudyVariables] = React.useState([]);
    const [studyTest, setStudyTest] = React.useState(new StudyTest());
    const currentPageCode = appConstants.stat_test_var_pagecode;
    const [main, setMain] = React.useState([]);
    const [grouping, setGrouping] = React.useState([]);
    const [explanatory, setExplanatory] = React.useState([]);
    const [outcome, setOutcome] = React.useState([]);
    const [testList, setTestList] = React.useState([[], [], [], []])
    const [outComeVariables, setOutComeVariables] = React.useState([]);
    const [showPairedVariables, setShowPairedVariables] = React.useState(false);
    const defaultPairValues = { pair1: null, pair1Name: null, pair2: null, pair2Name: null }
    const [selOutcomeVar, setSelOutcomeVar] = React.useState(defaultPairValues)
    const variableLabels = { 0: 'Unassigned variables', 1: 'Grouping variables', 2: 'Outcome variables', 3: 'Explanatory variables' }

    React.useEffect(() => {
        Promise.all([
            get(getStudy(studyId)),
            get(getStudyVariables(studyId)),
            get(getStudyTestByStudyId(studyId)),
            get(getStudyOutcomeContinuousVariables(studyId)),
        ]).then((res) => {
            let study = res[0];
            let studyVars = res[1];
            let studyTests = res[2];
            let outcomeVariables = res[3]
            setStudyObj(study);
            setStudyVariables(studyVars);
            setOutComeVariables(outcomeVariables);
            if ((study.type === appConstants.study_type[1].value ||
                (study.statistical_test_group == 'longitudinal' && (study.test_pairedttest) || (study.test_wilcoxon))) && outcomeVariables.length > 1) {
                setShowPairedVariables(true)
            }
            if (studyTests && studyTests["id"]) {
                if (((studyTests[appConstants.STUDY_TYPE_OUTCOME] && studyTests[appConstants.STUDY_TYPE_OUTCOME].length > 0) || (studyTests[appConstants.STUDY_TYPE_EXPLANATORY] && studyTests[appConstants.STUDY_TYPE_EXPLANATORY].length > 0))) {
                    setGrouping(studyTests[appConstants.STUDY_TYPE_GROUP]);
                    setOutcome(studyTests[appConstants.STUDY_TYPE_OUTCOME]);
                    setExplanatory(studyTests[appConstants.STUDY_TYPE_EXPLANATORY]);
                    testList[1] = studyTests[appConstants.STUDY_TYPE_GROUP];
                    testList[2] = studyTests[appConstants.STUDY_TYPE_OUTCOME];
                    testList[3] = studyTests[appConstants.STUDY_TYPE_EXPLANATORY];
                    // setStudyVariablesToStudy(studyTests["group"], studyTests["outcome"], studyTests["explanatory"], study);
                }
                let result = [];
                studyVars.forEach(element => {
                    if (!studyTests[appConstants.STUDY_TYPE_GROUP].includes(parseInt(element.id)) && !studyTests[appConstants.STUDY_TYPE_OUTCOME].includes(parseInt(element.id)) && !studyTests[appConstants.STUDY_TYPE_EXPLANATORY].includes(parseInt(element.id)) && element.type != appConstants.STUDY_TYPE_IDENTIFIER) {
                        result.push(element.id)
                    }
                });
                setMain(result);
                testList[0] = result;
                setStudyTest(studyTests);
            } else {
                const groupedVariables = _.groupBy(studyVars, item => item?.type);
                let mainList = [];
                if (groupedVariables[""] && groupedVariables[""].length > 0) {
                    mainList = mainList.concat(_.map(groupedVariables[""], 'id'));
                    testList[0] = mainList
                }
                /*if (groupedVariables[appConstants.STUDY_TYPE_IDENTIFIER] && groupedVariables[appConstants.STUDY_TYPE_IDENTIFIER].length > 0) {
                    mainList = mainList.concat(_.map(groupedVariables[appConstants.STUDY_TYPE_IDENTIFIER], 'id'));
                    testList[0] = mainList
                }*/
                setMain(mainList);
                if (groupedVariables[appConstants.STUDY_TYPE_GROUP] && groupedVariables[appConstants.STUDY_TYPE_GROUP].length > 0) {
                    setGrouping(_.map(groupedVariables[appConstants.STUDY_TYPE_GROUP], 'id'));
                    testList[1] = _.map(groupedVariables[appConstants.STUDY_TYPE_GROUP], 'id')
                }

                if (groupedVariables[appConstants.STUDY_TYPE_OUTCOME] && groupedVariables[appConstants.STUDY_TYPE_OUTCOME].length > 0) {
                    setOutcome(_.map(groupedVariables[appConstants.STUDY_TYPE_OUTCOME], 'id'));
                    testList[2] = _.map(groupedVariables[appConstants.STUDY_TYPE_OUTCOME], 'id')
                }

                if (groupedVariables[appConstants.STUDY_TYPE_EXPLANATORY] && groupedVariables[appConstants.STUDY_TYPE_EXPLANATORY].length > 0) {
                    setExplanatory(_.map(groupedVariables[appConstants.STUDY_TYPE_EXPLANATORY], 'id'));
                    testList[3] = _.map(groupedVariables[appConstants.STUDY_TYPE_EXPLANATORY], 'id')
                }
            }
            setTestList([...testList])

        })
    }, []);

    const saveTestVariables = (path) => {
        studyTest.group_variable = testList[1];
        studyTest.outcome_variable = testList[2];
        studyTest.explanatory_variable = testList[3];
        studyTest.study_id = parseInt(studyId);
        setStudyTest({
            ...studyTest,
            "study_id": parseInt(studyId),
            "group_variable": grouping,
            "explanatory_variable": explanatory,
            "outcome_variable": outcome,
        });
        if (studyTest && studyTest['id']) {
            put(updateStudyTest(parseInt(studyTest['id'])), studyTest).then(function (result) {
                if (result) {
                    navigate(path + '/' + studyId);
                }
            });
        } else {
            post(createStudyTests(), studyTest).then(function (result) {
                if (result) {
                    navigate(path + '/' + studyId);
                }
            });
        }
    }

    const getName = (element) => {
        let temp = _.find(studyVariables, ['id', element]);
        if (temp)
            return temp?.name;
        else
            return '';
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        console.log('in res', result);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: grid * 2,

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : '#fff',

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;
        return result;
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(testList[sInd], source.index, destination.index);
            const newState = [...testList];
            testList[sInd] = items;
            setTestList(testList);
        } else {
            const result = move(testList[sInd], testList[dInd], source, destination);
            const newState = [...testList];
            testList[sInd] = result[sInd];
            testList[dInd] = result[dInd];
            setTestList([...testList]);
        }
    };

    const handleChnage = (e) => {
        const { name, value } = e.target;
        const selectedOption = outComeVariables.find(ov => ov.id === value);
        setSelOutcomeVar({ ...selOutcomeVar, [name]: selectedOption?.id, [`${name}Name`]: selectedOption?.name })
    }

    const handleAddPair = () => {
        if (!studyTest?.paired_variables?.length > 0) {
            studyTest.paired_variables = []
        }
        if ((selOutcomeVar?.pair1 > 0 && selOutcomeVar?.pair2 > 0) === false) {
            props.triggerToastMessage({ isOpen: true, text: "Please input both the pairs", level: 'error' });
            return
        }
        if (selOutcomeVar.pair1 === selOutcomeVar.pair2) {
            props.triggerToastMessage({ isOpen: true, text: "The pairs should be different from one another", level: 'error' });
            return
        }
        const checkIfExists = studyTest.paired_variables.some(pv => (pv.pair1 === selOutcomeVar.pair1 && pv.pair2 == selOutcomeVar.pair2) || (pv.pair1 === selOutcomeVar.pair2 && pv.pair2 == selOutcomeVar.pair1))
        if (checkIfExists === true) {
            props.triggerToastMessage({ isOpen: true, text: "The selected pair already exists in the list", level: 'error' });
        } else {
            studyTest.paired_variables = [...studyTest.paired_variables, selOutcomeVar]
            setStudyTest({ ...studyTest, paired_variables: studyTest.paired_variables });
            setSelOutcomeVar(defaultPairValues)
        }

    }

    const handleDelete = (item) => {
        let tempArr = studyTest.paired_variables;
        let deleteIndex = tempArr.findIndex(ov => ov.id === item?.id);
        if (deleteIndex >= 0) {
            tempArr.splice(deleteIndex, 1);
            console.log(tempArr)
            setStudyTest({ ...studyTest, paired_variables: tempArr })
        }
    }

    return (
        <div className="title-frame">
            {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={appConstants.project_flow[studyObj?.mode_of_data_entry]}
                            saveFunc={saveTestVariables}
                            showNext={false}
                        />
                    </Grid>
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={appConstants.project_flow[studyObj?.mode_of_data_entry]}
                            saveFunc={saveTestVariables}
                            showNext={true}
                        />
                    </Grid>
                </Grid>
            }

            <div className="content-frame">
                <div className="what-is-the">{appConstants.stats_test_variables}</div>
                <div className="a-brief-description">
                    All unassigned variables are listed at the top. You can drag and drop them into the appropriate box below to assign them.
                </div>
            </div>
            <div className="dragdrop">
                <div className="unassignedvar-parent">
                    <div className="unassignedvar">
                        {/* <div className="unassigned-variables">
                            Unassigned variables
                        </div> */}
                        <DragDropContext onDragEnd={onDragEnd}>

                            <Droppable droppableId="0" direction="vertical">
                                {(provided, snapshot) => (
                                    <div
                                        style={{ margin: 'auto', textAlign: 'center' }}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className="groupvar"
                                    >
                                        <div className="blueframe">
                                            {testList?.[0]?.map((item, index) => (
                                                <Draggable key={item} draggableId={`${item}`} index={index}>
                                                    {(provided, snapshot) => (
                                                        <Chip
                                                            className='draggablechip'
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            label={getName(item)}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            variant="outlined" />
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                        {provided.placeholder}
                                        <div className="grouping-variables">{variableLabels[0]}</div>
                                    </div>
                                )}
                            </Droppable>
                            <br />
                            <div className="var-buckets">
                                {[...testList].splice(1,)?.map((bucket, index) => (
                                    <Droppable droppableId={`${index + 1}`} direction="vertical">
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className="groupvar">
                                                <div className="blueframe">
                                                    {bucket?.map((item, index) => (
                                                        <Draggable key={item} draggableId={`${item}`} index={index}>
                                                            {(provided, snapshot) => (
                                                                <Chip
                                                                    className='draggablechip'
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    label={getName(item)}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                    variant="outlined" />
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                </div>
                                                {provided.placeholder}

                                                <div className="grouping-variables">{variableLabels[index + 1]}</div>
                                            </div>
                                        )}
                                    </Droppable>

                                ))}
                            </div>
                        </DragDropContext>
                        {showPairedVariables &&
                            <React.Fragment>
                                <br /><hr />
                                <div className="unassigned-variables">
                                    Define Pairs
                                </div>
                                <Grid ml={2} mt={2} container style={{ alignItems: 'center', gap: '2rem' }}>
                                    {appConstants.pairing_variables.map((pair, index) => (
                                        <Grid item>
                                            <FormControl fullWidth error={(outComeVariables.length > 0) ? false : true} style={{ width: '100%' }}>
                                                <InputLabel id="demo-simple-select-label">{pair?.lable}</InputLabel>
                                                <Select
                                                    value={selOutcomeVar?.[pair?.name] ?? null}
                                                    name={pair?.name}
                                                    onChange={handleChnage}
                                                    label={pair?.lable}
                                                    className="text-fieldwithicon"
                                                >
                                                    {outComeVariables.map((element) => (
                                                        <MenuItem
                                                            key={element?.id}
                                                            value={element?.id}
                                                        >{element?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    ))}
                                    <Grid item>
                                        <IconButton
                                            onClick={handleAddPair}
                                            title={'Pair'}
                                            className='primaryfilledbuttons'
                                            style={{ width: '102px', padding: '24px' }}
                                        >
                                            <AddIcon />&nbsp;Pair
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                {studyTest.paired_variables?.length > 0 &&
                                    <TableContainer style={{ width: 'fit-content' }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='center'>Pairs</TableCell>
                                                    <TableCell align='center'>Variable 1</TableCell>
                                                    <TableCell align='center'>Variable 2</TableCell>
                                                    <TableCell align='center'>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {studyTest?.paired_variables?.map((pair, index) => (

                                                    <TableRow
                                                        key={1}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{pair?.pair1Name}</TableCell>
                                                        <TableCell>{pair?.pair2Name}</TableCell>
                                                        <TableCell align='center'>
                                                            <Button variant="text" color="error" title="Delete pair" onClick={() => handleDelete(pair)}>
                                                                <DeleteIcon />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}
