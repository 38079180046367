import React from "react";
import { getStudy } from "../../Api/StudyApi";
import { getStudyGroupVariables } from "../../Api/StudyVariableApi";
import { getStudyTestByStudyId, updateStudyTest, createStudyTests } from "../../Api/StudyTestApi";
import { useNavigate, useParams } from "react-router-dom";
import post from "../../Api/Config/PostConfig";
import put from "../../Api/Config/PutConfig";
import get from "../../Api/Config/GetConfig";
import { Study } from "../../Models/Study";
import { StudyTest } from "../../Models/StudyTest";
import { StudyVariables } from "../../Models/StudyVariables";
import { Grid, Paper, MenuItem, FormControl, InputLabel } from "@mui/material";
import * as appConstants from "../../AppConstants";
import { getLoggedUser } from "../../Storage/Getter";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import NextPrevButton from "../../Components/NextPrevButton";
import Select from '@mui/material/Select';
import './GroupVariablePage.css';

function GroupVariablePage() {
    const navigate = useNavigate();
    const { studyId } = useParams();
    const currentPageCode = appConstants.group_variable_pagecode;
    const [studyObj, setStudyObj] = React.useState(new Study());
    const [studyVariables, setStudyVariables] = React.useState(new StudyVariables());
    const [studyTest, setStudyTest] = React.useState(new StudyTest());
    const [studyVariableList, setStudyVariableList] = React.useState([]);
    const loggedUser = getLoggedUser();

    const saveStudyTestAndVariables = (path) => {
        let selectGroup = false;
        if (studyObj.sub_type == "case-control-study" && !studyTest[appConstants.STUDY_TYPE_GROUP]) {
            selectGroup = true;
        }
        if (selectGroup) {
            alert('Please select atleast one group variables')
            return false;
        }
        else {
            Promise.all([
                saveStudyTest(studyTest)
            ]).then((res) => {
                navigate(path + '/' + studyId);
            });
        }
    }

    const saveStudyTest = (studyTestObj) => {
        if (studyTestObj?.id)
            return put(updateStudyTest(studyTestObj.id), studyTestObj);
        else {
            studyTestObj.study_id = parseInt(studyId);
            studyTestObj.user_id = parseInt(loggedUser.id);
            studyTestObj[appConstants.STUDY_TYPE_OUTCOME] = [];
            studyTestObj[appConstants.STUDY_TYPE_EXPLANATORY] = [];
            return post(createStudyTests(), studyTestObj);
        }
    }

    const handleUserInput = (event) => {
        setStudyTest((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    React.useEffect(() => {
        Promise.all([
            get(getStudy(studyId)),
            get(getStudyGroupVariables(studyId)),
            get(getStudyTestByStudyId(studyId))
        ]).then((res) => {
            setStudyObj(res[0]);
            setStudyVariableList(res[1]);
            if (studyObj.sub_type != "case-control-study") {
                res[2][appConstants.STUDY_TYPE_GROUP] = "";
            }
            else if (res[2][appConstants.STUDY_TYPE_GROUP]) {
                res[2][appConstants.STUDY_TYPE_GROUP] = res[2][appConstants.STUDY_TYPE_GROUP][0];
            }
            setStudyTest(res[2]);
            //setDefaultstudyVariableGroupList(res[0].no_of_times, res[1], res[2]);
        });
    }, []);

    return (
        <div className="title-frame">
            {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                            saveFunc={saveStudyTestAndVariables}
                            showNext={false}
                        />
                    </Grid>
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                            saveFunc={saveStudyTestAndVariables}
                            showNext={true}
                        />
                    </Grid>
                </Grid>
            }
            <div className="content-frame">
                <div className="what-is-the">{appConstants.grouping_variable_question}</div>
                {(!studyVariableList?.length > 0) &&
                    <div className="a-brief-description">
                        Looks like you have not marked any variables as grouping variable. Please go back to data-sheet, select the variable you would like to make as 'Grouping Variable' and mark the type of the variable as 'Grouping'
                    </div>

                }
                <br />
            </div>
            <div>
                <FormControl fullWidth error={(studyVariableList.length > 0) ? false : true} style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Variable</InputLabel>
                    <Select
                        value={studyTest[appConstants.STUDY_TYPE_GROUP]}
                        name={appConstants.STUDY_TYPE_GROUP}
                        multiple={false}
                        onChange={handleUserInput}
                        label={"Variable"}
                        className="text-fieldwithicon"
                        style={{ width: '500px' }}
                    >
                        {studyVariableList.map((sv, index) => (
                            <MenuItem key={index} value={sv.id}>{sv.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div >
    );
}

export default GroupVariablePage;
