import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import "../Pages/SignUp/Username.css";
function SingleTextInput(props) {
    return (
        <div className="username">
            <div className="email-address">
                <div className="username1">{props?.title}</div>
            </div>
            <div style={{ width: '100%' }}>
                <TextValidator
                    placeholder={props?.placeholder}
                    variant="outlined"
                    value={props?.value}
                    label={props?.label ?? ''}
                    onChange={props?.onChange}
                    type={props?.type ?? 'text'}
                    name={props?.name}
                    validators={props?.validators}
                    errorMessages={props?.errorMessages}
                    fullWidth
                    multiline={props?.multiLine === true ? true : false}
                    rows={props?.rows}
                    sx={{
                        "& fieldset": { borderColor: "#d6ebfe" },
                        "& .MuiInputBase-root": {
                            height: (props?.multiLine === true ? 'auto' : "56px"),
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                            fontSize: "12px",
                        },
                        "& .MuiInputBase-input": { color: "#878787" },
                    }}
                />

            </div>

        </div>

    )
}

export default SingleTextInput