import { useMemo } from "react";
import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import "./ButtonText.css";
import React from "react";
const ButtonText = ({ enterYourPassword, propHeight }) => {
    const buttonTextStyle = useMemo(() => {
        return {
            height: propHeight,
        };
    }, [propHeight]);

    return (
        <div className="button-text" style={buttonTextStyle}>
            <div className="alreadyhavean-account">
                <div className="enter-your-password">{enterYourPassword}</div>
            </div>
            <TextField
                className="passwordfield"
                placeholder="Password"
                variant="outlined"
                InputProps={{
                    endAdornment: <img width="15px" height="12.1px" src="/group.svg" />,
                }}
                sx={{
                    "& fieldset": { borderColor: "#323232" },
                    "& .MuiInputBase-root": {
                        height: "40px",
                        backgroundColor: "#fff",
                        paddingRight: "20px",
                        borderRadius: "8px",
                        fontSize: "12px",
                    },
                    "& .MuiInputBase-input": { color: "#878787" },
                }}
            />
            <div className="usernameoremailaddress" />
        </div>
    );
};

export default ButtonText;