export const setCurrentPageName = (payload) => {
    return {
      type: "pageName",
      payload
    }
}

export const setCurrentRoutePath = (payload) => {
  return {
    type: "routePath",
    payload
  }
}

