import './App.css';
import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from "react-redux";

import { setCurrentPageName } from './Actions/HomePageAction';
import Dashboard from './Pages/Dashboard/Dashboard';

class App extends Component {
  render() {
    console.log(this.props);
    console.log(process.env.REACT_APP_URL);//printing it to console
    localStorage.setItem("loggedUser", {});
    localStorage.setItem("authToken", '');
    return (
      <Router>
        <Dashboard />
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setCurrentPageName: (payload) => dispatch(setCurrentPageName(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);