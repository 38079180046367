export const createStudyVariable = () =>{
    return process.env.REACT_APP_URL+'studyVariables';
}

export const getStudyVariables = (id) =>{
    return process.env.REACT_APP_URL+'studyVariables/'+id;
}

export const getStudyGroupVariables = (id) =>{
    return process.env.REACT_APP_URL+'studyGroupVariables/'+id;
}

export const getStudyOutcomeVariables = (id) =>{
    return process.env.REACT_APP_URL+'studyOutcomeVariables/'+id;
}

export const getStudyOutcomeContinuousVariables = (id) =>{
    return process.env.REACT_APP_URL+'studyOutcomeContinuousVariables/'+id;
}

export const getStudyExplanatoryVariables = (id) =>{
    return process.env.REACT_APP_URL+'studyExplanatoryVariables/'+id;
}

export const updateStudyVariable = (id) =>{
    return process.env.REACT_APP_URL+'studyVariables/'+id;
}

export const reorderStudyVariables = (study_id) =>{
    return process.env.REACT_APP_URL+'reorderStudyVariables/'+study_id;
}

export const deleteStudyVariable = (id) =>{
    return process.env.REACT_APP_URL+'studyVariables/'+id;
}