import { UserConfig } from "./UserConfig";

export class User {

    email = "";
    //username = "";
    //password = "";
    last_login_at = "";
    current_login_at = "";
    last_login_ip = "";
    current_login_ip = "";
    login_count = 0;
    active = false;
    confirmed_at = "";
    fs_uniquifier = "";
    current_auth_token = "";
    first_name = "";
    last_name = "";
    middle_name = "";
    address = "";
    phone = "";
    created_by = "";
    updated_by = "";
    status = "";
    roles = [];
    login_otp = "";
    otp_verified_on = "";
    forgot_password_otp = "";
    config = new UserConfig();
    google_profile_img = "";
    google_login = false;
    google_access_token = "";
    points_balance = 0;
}