import React, { useCallback } from "react";

export default function RefundPolicy() {

    return (<>
    <div
    className="is-container container is-content-right edge-x-4"
    style={{ maxWidth: 1100,height:'auto',backgroundColor: 'honeydew' }}
    id={1651055173373}
    >
  <div className="row clearfix" id={1651055173373}>
    <div className="column full center" autoComplete="off" id={1651055173374}>
      <h2 className="size-38">Refund Policy</h2>
    </div>
  </div>
  <div className="row clearfix" id={1651055173374}>
    <div className="column full" id={1651055173374}>
      <div className="spacer height-60" id={1651055173374} />
    </div>
  </div>
  <div className="row clearfix" id={1651055173374}>
    <div className="column full" autoComplete="off" id={1651055173374}>
      <p style={{ textAlign: "justify" }}>
        Returns and Refunds Policy. Thank you for shopping at www.shodh.ai
        <br />
        <br />
        Non-tangible irrevocable goods ("Digital products")
        <br />
        <br />
        We do not issue refunds for non-tangible irrevocable goods ("digital
        products") once the order is confirmed and the product is sent.
        <br />
        <br />
        We recommend contacting us for assistance if you experience any issues
        receiving or downloading our products.
        <br />
        <br />
        Contact us for any issues:
        <br />
        <br />
        If you have any questions about our Returns and Refunds Policy, please
        contact us:
        <br />
        <br />- By email: shodhai.summit@gmail.com
      </p>
      <h1>Cancellation Policy</h1>
      <p>
        Cancellation Policy. Thank you for shopping at www.shodh.ai
        <br />
        <br />
        Non-tangible irrevocable goods ("Digital products")
        <br />
        <br />
        We do not allow cancellation for non-tangible irrevocable goods
        ("digital products") once the order is confirmed and the product is
        sent.
        <br />
        <br />
        We recommend contacting us for assistance if you experience any issues
        receiving or downloading our products.
        <br />
        <br />
        Contact us for any issues:
        <br />
        <br />
        If you have any questions about our Cancellation Policy, please contact
        us:
        <br />
        <br />- By email: shodhai.summit@gmail.com
      </p>
    </div>
  </div>
</div>
    </>)

}