import React from 'react'
export const study_type = [
    {
        id: 1,
        label: "Cross sectional",
        value: "cross-sectional",
        content: "Cross Sectional Content",
    },
    {
        id: 2,
        label: "Longitudinal (Follow-up study)",
        value: "longitudional",
        content: "Longitudinal Content",
    },
    {
        id: 3,
        label: "Interventional clinical trial",
        value: "intervential",
        content: "Interventional Content",
    },
];

export const test_selections = [
    {
        id: 1,
        label: "Let SHODH-AI choose the test automatically",
        value: "Automatic SHODH-AI test",
        content: "Let SHODH-AI choose the test automatically"
    },
    {
        id: 2,
        label: "Choose manually",
        value: "Manual",
        content: "Manual test selection"
    }
]

export const study_list_pagecode = "StudyListPage"
export const study_title_pagecode = "StudyCreate/Update";
export const data_entry_pagecode = "Spreadsheet";
export const excel_upload_page_code = "UploadExcelPage";
export const stat_test_pagecode = "StatisticalTestSelectionPage";
export const stat_test_var_pagecode = "StatisticalTestVariablesPage";
export const output_pagecode = "OutputPage";
export const study_type_pagecode = "StudyTypePage";
export const study_subtype_pagecode = "StudySubtypePagecode";
export const study_groups_pagecode = "StudyGroups";
export const group_variable_pagecode = "GroupVariable";
export const outcome_variable_pagecode = "OutcomeVariabels";
export const explanatory_variable_pagecode = "ExplanatoryVariable";
export const examining_pagecode = "StudyExamining";
export const timepoints_pagecode = "TimePoints";

export const manual_urls = [
    { "name": "Study list page", "pageCode": study_list_pagecode, "path": "/studyList" },
    { "name": "Study Create/Update page", "pageCode": study_title_pagecode, "path": "/study" },
    { "name": "Data entry page", "pageCode": data_entry_pagecode, "path": "/spreadSheet" },
    {
        "name": "Statistical test page", "pageCode": stat_test_pagecode, "path": "/statTests",
        "nextPage": true, "nextPageList": [
            { "nextPageCode": study_type_pagecode, "nextPageFieldName": "statistical_test_type", "nextPageValue": 1 },
            { "nextPageCode": stat_test_var_pagecode, "nextPageFieldName": "statistical_test_type", "nextPageValue": 2 }
        ]
    },
    {
        "name": "Study type page", "pageCode": study_type_pagecode, "path": "/studyType",
        "nextPage": true, "nextPageList": [
            { "nextPageCode": study_subtype_pagecode, "nextPageFieldName": "type", "nextPageValue": "cross-sectional" },
            { "nextPageCode": group_variable_pagecode, "nextPageFieldName": "type", "nextPageValue": "longitudional" },
            { "nextPageCode": group_variable_pagecode, "nextPageFieldName": "type", "nextPageValue": "intervential" }
            //{ "nextPageCode": examining_pagecode, "nextPageFieldName": "type", "nextPageValue": "longitudional" },
            //{ "nextPageCode": study_subtype_pagecode, "nextPageFieldName": "type", "nextPageValue": "intervential" }
        ], "prevPageCode": stat_test_pagecode
    },
    /*{
        "name": "Study subtype page", "pageCode": study_subtype_pagecode, "path": "/studySubType",
        "nextPage": true, "nextPageList": [
            { "nextPageCode": study_groups_pagecode, "nextPageFieldName": "sub_type", "nextPageValue": "descriptive-study" },
            { "nextPageCode": study_groups_pagecode, "nextPageFieldName": "sub_type", "nextPageValue": "association-between-clinical-features" },
            { "nextPageCode": study_groups_pagecode, "nextPageFieldName": "sub_type", "nextPageValue": "case-control-study" }
        ], "prevPageCode": study_type_pagecode
    },
    {
        "name": "Study groups page", "pageCode": study_groups_pagecode, "path": "/studyTimes",
        "nextPageCode": group_variable_pagecode,
        "prevPage": true, "prevPageList": [
            { "prevPageCode": study_subtype_pagecode, "prevPageFieldName": "type", "prevPageValue": "cross-sectional" },
            { "prevPageCode": study_type_pagecode, "prevPageFieldName": "type", "prevPageValue": "longitudional" }
        ]
    },*/
    {
        "name": "Study subtype page", "pageCode": study_subtype_pagecode, "path": "/studySubType",
        "nextPageCode": group_variable_pagecode, "prevPageCode": study_type_pagecode
    },
    {
        "name": "Group variable page", "pageCode": group_variable_pagecode, "path": "/groupVariable",

        "nextPageCode": outcome_variable_pagecode,
        "prevPage": true, "prevPageList": [
            { "prevPageCode": study_subtype_pagecode, "prevPageFieldName": "type", "prevPageValue": "cross-sectional" },
            { "prevPageCode": study_type_pagecode, "prevPageFieldName": "type", "prevPageValue": "longitudional" },
            { "prevPageCode": study_type_pagecode, "prevPageFieldName": "type", "prevPageValue": "intervential" }
        ]
    },
    {
        "name": "Outcome variable page", "pageCode": outcome_variable_pagecode, "path": "/outcomeVariable",
        "nextPageCode": explanatory_variable_pagecode, "prevPageCode": group_variable_pagecode
    },
    {
        "name": "Explanatory variable page", "pageCode": explanatory_variable_pagecode, "path": "/explanatoryVariable",
        "nextPageCode": output_pagecode, "prevPageCode": outcome_variable_pagecode
    },
    {
        "name": "Study examining page", "pageCode": examining_pagecode, "path": "/studyExamining",
        "prevPage": true, "prevPageList": [
            { "prevPageCode": study_type_pagecode, "prevPageFieldName": "type", "prevPageValue": "longitudional" },
        ]
    },
    {
        "name": "Timepoints page", "pageCode": timepoints_pagecode, "path": "/timepoints",
        "nextPageCode": stat_test_var_pagecode, "prevPageCode": examining_pagecode
    },
    {
        "name": "Statistical test variables page", "pageCode": stat_test_var_pagecode, "path": "/statTestVariables",
        "prevPage": true, "prevPageList": [
            { "prevPageCode": study_type_pagecode, "prevPageFieldName": "statistical_test_type", "prevPageValue": 1 },
            { "prevPageCode": stat_test_pagecode, "prevPageFieldName": "statistical_test_type", "prevPageValue": 2 }
        ]
    },
    {
        "name": "Output page", "pageCode": output_pagecode, "path": "/runTests",
        "prevPageCode": stat_test_var_pagecode,
        "prevPage": true, "prevPageList": [
            { "prevPageCode": explanatory_variable_pagecode, "prevPageFieldName": "statistical_test_type", "prevPageValue": 1 },
            { "prevPageCode": stat_test_var_pagecode, "prevPageFieldName": "statistical_test_type", "prevPageValue": 2 }
        ]
    }
];

export const project_flow = {
    'Manually': manual_urls,
    'Upload an excel sheet': manual_urls,
    'Use a pre-existing template': [
        { "name": "", "pageCode": "", "path": "" },
        { "name": "", "pageCode": "", "path": "" },
        { "name": "", "pageCode": "", "path": "" },
        { "name": "", "pageCode": "", "path": "" }
    ]
};

export const manualValue = "Manually";
export const uploadFromExcellValue = "Upload an excel sheet";

export const mode_of_data_entry = [
    {
        id: 1,
        label: "Manually",
        value: manualValue,
        content: "Manually"
    },
    /*{
        id: 2,
        label: "Upload an excel sheet",
        value: uploadFromExcellValue,
        content: "Upload an excel sheet"
    },*/
    // {
    //     id: 3,
    //     label: "Use a pre-existing template",
    //     value: "Use a pre-existing template",
    //     content: "Use a pre-existing template"
    // }
];

export const study_title = "What is title of the study ?";
export const study_type_question = "What kind of study are you doing ?";
export const crosssectional_question = "What describes your study best ?";
export const crosssectional_options = [
    {
        id: 1,
        label: "I am doing a descriptive study",
        value: "descriptive-study",
        content: "I am doing a descriptive study",
    },
    {
        id: 2,
        label: "I am doing a study to examine association between clinical features",
        value: "association-between-clinical-features",
        content: "Longitudinal Content",
    },
    {
        id: 3,
        label: "I am doing a case control study",
        value: "case-control-study",
        content: "I am doing a case control study",
    },
];
export const case_control_study_question =
    "How many groups are you studying?";
export const case_control_study_options = [
    {
        id: 1,
        label: "2 groups",
        value: "two-groups",
        content:
            "Eg. 2 group study involving patients with depression vs healthy individuals",
    },
    {
        id: 2,
        label: "> 2 groups",
        value: "more-than-two-groups",
        content:
            "Eg. 3 group study involving patients with depression,Ocd ,Healthy individuals",
    },
];
export const longitudional_study_options = [
    {
        id: 1,
        label: "1 groups",
        value: "one-group",
        content:
            "Eg. 1 group study involving patients with depression vs healthy individuals",
    },
    {
        id: 2,
        label: "> 1 groups",
        value: "more-than-one-group",
        content:
            "Eg. 2 group study involving patients with depression,Ocd ,Healthy individuals",
    },
];
export const examining_study_question =
    "How many times you are examining patients?";
export const examining_study_options = [
    {
        id: 1,
        label: "2 Times",
        value: "two-times",
        content:
            "Eg. 2 group study involving patients with depression vs healthy individuals",
    },
    {
        id: 2,
        label: "> 2 Times",
        value: "more-than-two-times",
        content:
            "Eg. 3 group study involving patients with depression,Ocd ,Healthy individuals",
    },
];
export const discrete_type = [
    {
        id: 1,
        label: "Present",
        value: "present",
    },
    {
        id: 2,
        label: "Absent",
        value: "absent",
    },
];
export const define_groups_heading = "Define groups";
export const define_groups_question = "Name your groups";
export const outcome_variable_heading = "Outcome Variable";
export const outcome_variable_question =
    " Name the measures that you are studying as Outcome Variable";
export const outcome_variable_description =
    "Eg. In a study examining memory performance between depressed vs healthy outcome variable is  memory score. It is also called as dependent variable ";
export const explanatory_variable_heading = "Variable Properties";
export const explanatory_variable_question =
    "Define properties of your Explanatory variable";
//"Please define properties for your explanatory variable ";
export const explanatory_variable_desc1 =
    "Continuous - Can take a value within a range.As the name indicates they are numerical measures. For example Height(in cm), Weight(in kg), Temperature(in celcius), Age (in years)";
export const explanatory_variable_desc2 =
    "Catergorical - Also called as discrete variables. Consist of names and tables that divide data into specific category Eg. Sex(Male, Female)";
export const define_outcome_variable =
    "Choose your outcome measure";
//"Please define properties for your outcome variable";
export const define_outcome_variable_desc1 = 'A discrete variable  can only take on specific, distinct values. These values are typically counted rather than measured. Examples are number of children in a family, socioeconomic status, number of correct answers on a true/false test.';
export const define_outcome_variable_desc2 =
    "A continuous variable can take on any value within a certain range. Continuous variables are typically measured rather than counted. Examples are height, age, total scores on scales of mood or memory.";
export const identifier_variable_heading = "Identifier Variables";
export const identifier_variable_question =
    "Patient identifiers";
//"Please define properties for your identifier variable";
export const identifier_variable_description =
    "Eg. Name, Hospital Id, Study Id, Address, Phone number";
export const variable_definition =
    "This is the variable of intrest - a measure that you are expecting to be different between groups or to change after intervention. In a study examining memory performance between depressed vs healthy, the outcome variable is memory score. It is also called as dependent variable";
export const STUDY_TYPE_OUTCOME = "outcome_variable";
export const STUDY_TYPE_GROUP = "group_variable";
export const STUDY_TYPE_IDENTIFIER = "identifier";
export const SUB_TYPE_CONTINUOUS = "continuous";
export const SUB_TYPE_CATEGORICAL = "categorical";
export const STUDY_TYPE_EXPLANATORY = "explanatory_variable";
export const STUDY_TYPE = [STUDY_TYPE_OUTCOME, STUDY_TYPE_EXPLANATORY, STUDY_TYPE_IDENTIFIER];
export const STUDY_SUB_TYPE = [SUB_TYPE_CATEGORICAL, SUB_TYPE_CONTINUOUS];
export const OUTCOME_SUBCATEGORY = [SUB_TYPE_CONTINUOUS, SUB_TYPE_CATEGORICAL];
export const EXPLANATORY_SUBCATEGORY = [
    SUB_TYPE_CONTINUOUS,
    SUB_TYPE_CATEGORICAL,
];
export const grouping_variable_question = "Choose the grouping variable";
export const var_definition =
    "Definition: This is the variable based on which you want to label the participants. For example, in a descriptive clinical study, the grouping variable is diagnosis";
export const descriptive_variable_definition2 =
    "Descriptive variables are the measures that you have collected in your study to describe the participants. For example, in a clinical descriptive study, the variables could be age, sex, education, age at onset, severity of the illness etc";
export const clinical_variable_definition1 =
    "Definition: This is the variable based on which you want to label the participants. For example, in a descriptive clinical study, the grouping variable is diagnosis";
export const clinical_variable_definition2 =
    "Clinical variables are the measures that you have collected in your study from the participants and want to examine the association between them. For example, in a clinical study examining the association between age and depression severity, the variables could be age, education, age at onset, the severity of the illness, number of episodes etc. Please note that only continuous variables are allowed to examine the association";

export const timepoint_question = "Type the names of your timepoints";
export const spreadsheet_question = "Spreadsheet";
export const stats_question = "Statistical Tests";
export const stats_test_variables = "Assign Variables";

export const study_variable_summary_title = "Preview of study design";
export const study_output_title = "Output of the study";

// Output destination folder
export const DOCS_FOLDER = "prm-docs";
export const DOCS_OUTPUT_FOLDER = "output";

//export const RAZORPAY_LIVE_KEY_ID = "rzp_live_UES9sphUcZGD0J";
//export const RAZORPAY_LIVE_KEY_SECRET = "GUImz4ao4M9cdxag8pHWwWbf";

//export const RAZORPAY_TEST_KEY_ID = "rzp_test_ysFTTLU4TIEfss";
//export const RAZORPAY_TEST_KEY_SECRET = "B5dwZA90n1ZBMROEbuBCuawp";

export const pairing_variables = [
    { name: 'pair1', lable: 'Pairing variable 1' },
    { name: 'pair2', lable: 'Pairing variable 2' }
]
export const outcome_variable_desc = "or dependent variables, are variables in a research study that are of primary interest and are typically the focus of investigation. These variables are what researchers aim to measure, predict, or explain based on the manipulation of one or more independent variables or predictors"
export const explanatory_variable_desc = "or independent variable or predictor variable, is a variable in a statistical model that is believed to influence the response or outcome variable. In an experiment, explanatory variables are manipulated or controlled by the researcher to determine their effect on the outcome variable."
export const group_variable_desc = "aka grouping variable or categorical variable, is a type of variable that categorizes individuals or entities into distinct groups or categories based on some characteristic or attribute."


export const variable_desc = {
    descriptive: [
        // <p><b>Missing variable</b> refers to data that is absent or not recorded for certain individuals or observations within a dataset. This absence of information can occur for various reasons, including:</p>,
        // <p>1. Non-response: Participants or subjects in a study may choose not to provide certain information or may skip particular questions on a survey or questionnaire.</p>,
        // <p>2. Data entry errors: Mistakes made during data collection or data entry can result in missing values. For example, a typographical error might lead to a blank entry in a database.</p>,
        // <p>3. Loss of data: Data can be lost due to technical issues, such as corrupted files, or physical damage to data storage devices.</p>,
        // <p>4. Inapplicable data: Certain variables may not be applicable to all individuals or observations in a dataset. For example, questions about pregnancy history would not apply to male participants.</p>,
        <p><b>Frequency</b> refers to the count of occurrences of a particular value or category within a dataset. It represents how often a specific value appears in the data. The frequencies can be presented in a frequency distribution table or summarized using graphical representations such as histograms or bar charts.</p>,
        <p><b>Mean</b> aka the "average," is a measure of central tendency that represents the typical value of a set of numbers. It is calculated by adding up all the values in a dataset and then dividing the sum by the total number of values. However, it's important to note that the mean can be influenced by outliers or extreme values in the dataset, so it's often used in conjunction with other measures of central tendency, such as the median and mode, to provide a more comprehensive understanding of the data.</p>,
        <p><b>Standard deviation</b> or SD is a measure of the dispersion or spread of a set of values around the mean. It quantifies how much individual values in a dataset vary from the mean value. A low standard deviation indicates that the values tend to be close to the mean, while a high standard deviation indicates that the values are spread out over a wider range.</p>,
        <p><b>Median</b> is a measure of central tendency that represents the middle value of a dataset when it is arranged in ascending or descending order. If there is an odd number of values, the median is the middle value. If there is an even number of values, the median is the average of the two middle values. The median is often used as a measure of central tendency when the dataset contains outliers or skewed distributions because it is less affected by extreme values compared to the mean.</p>,
        <p><b>Range</b> is a measure of dispersion that represents the difference between the highest and lowest values in a dataset. It provides a simple way to understand the spread of values within a dataset.</p>
    ],
    normative_distribution: [
        <p><b>Shapiro–Wilk Test</b> is a statistical test used to assess whether a given sample of data follows a normal distribution. It is commonly used in statistical analysis to determine whether the assumption of normality holds for a dataset before applying certain parametric statistical tests that require normality, such as t-tests or analysis of variance (ANOVA).</p>,
    ],
    crosee_sectional_logitudinal: [
        <p><b>Cross- sectional study</b> is a type of observational research design that examines data from a sample at a single point in time. </p>,
        <p><b>Independent Samples T-Test</b> or he two-sample t-test, is a statistical test used to determine whether there is a significant difference between the means of two independent groups. </p>,
        <p><b>Mann–Whitney <i>U</i>Test</b> or the Wilcoxon rank-sum test, is a non-parametric statistical test used to determine whether there is a significant difference between the medians of two independent groups. It is an alternative to the independent samples t-test when the assumptions of normality and homogeneity of variances are violated or when the data are measured at an ordinal level.</p>,
        <p><b>One-way analysis of variance (ANOVA)</b> is a statistical test used to determine whether there are statistically significant differences between the means of three or more independent groups. It extends the principles of the independent samples t-test, which compares the means of two groups, to the case where there are multiple groups. One-way ANOVA is commonly used in research to compare means across multiple groups, such as comparing the effectiveness of different treatments or interventions, analysing the impact of different conditions or factors.</p>,
        <p><b>Chi-square test</b> is a statistical method used to determine whether there is a significant association between two categorical variables. It's often used to analyse data in contingency tables, where the variables are grouped into categories.</p>,

        <p><b>Longitudinal study:</b> A longitudinal study is a type of research design in which data is collected from the same participants repeatedly over a period of time. </p>,
        <p><b>Paired t-test</b> is a statistical method used to compare the means of two related groups. It's particularly useful when you have paired or matched data points, such as before-and-after measurements, or measurements taken from the same subjects under different conditions.</p>,
        <p><b>Wilcoxon signed-rank test</b> is a non-parametric statistical test used to compare two related samples or paired observations. It's an alternative to the paired t-test when the assumption of normality is not met or when the data is measured on an ordinal scale.</p>,
    ],
    correlational: [
        <p><b>Pearson,</b> is a statistical measure used to examine the relationship between two or more variables. It is commonly used in research to explore whether and to what extent changes in one variable are associated with changes in another variable. It's important to note that correlation does not imply causation. Even if two variables are correlated, it does not necessarily mean that changes in one variable cause changes in the other variable.</p>,
        <p><b>Spearman,</b> Correlation coefficient, often denoted by the symbol \( \rho \) (rho), is a non-parametric measure of correlation between two variables. It assesses the strength and direction of the monotonic relationship between the ranked values of two variables, regardless of the linearity of their relationship. Spearman's rank correlation coefficient is particularly useful when data does not meet the assumptions required for Pearson's correlation coefficient (e.g., when the data is ordinal or when the relationship between variables is not linear). </p>
    ]
}
export const UPLOAD_EXCEL_FILE = { value: 'upload_excel_file', label: 'Upload Excel File' }
export const ADD_MANUALLY = { value: 'add_manually', label: 'Add Manually' }
export const PICK_FROM_TEMPLATE = { value: 'pick_from_template', label: 'Pick From Template' }
export const INPUT_METHODS = [UPLOAD_EXCEL_FILE, ADD_MANUALLY]

export const POINTS_MULTIPLIER = 0.1;
export const POINTS_DENOMINATION = 250;