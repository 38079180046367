export const createStudyData = () =>{
    return process.env.REACT_APP_URL+'studyData';
}

export const getStudyData = (studyId) =>{
    return process.env.REACT_APP_URL+'studyData/'+studyId;
}

export const updateStudyData = (id) =>{
    return process.env.REACT_APP_URL+'studyData/'+id;
}