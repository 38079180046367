import * as React from "react";
import { useParams } from "react-router-dom";
import { render } from "react-dom";
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import { textRenderer, registerRenderer } from 'handsontable/renderers';
import 'handsontable/dist/handsontable.full.min.css';
import { StudyVariables } from "../../Models/StudyVariables";
import {
    Grid, Paper, IconButton, Button, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, ButtonGroup,
    TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, InputLabel, Select, MenuItem
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { TEXT_BOX_COLOR, TEXT_BOX_VARIANT, FORM_BUTTON_VARIANT } from '../../Helper/FormConstants';
import * as validator from '../../Helper/Validator';
import put from "../../Api/Config/PutConfig";
import get from "../../Api/Config/GetConfig";
import post from "../../Api/Config/PostConfig";
import del from "../../Api/Config/DeleteConfig";
import { getStudyData, createStudyData, updateStudyData } from "../../Api/StudyDataApi";
import { getStudy, updateStudy } from "../../Api/StudyApi";
import { getStudyVariables, createStudyVariable, updateStudyVariable, deleteStudyVariable, reorderStudyVariables } from "../../Api/StudyVariableApi";
import { getLoggedUser } from "../../Storage/Getter";
import * as appConstants from "../../AppConstants";
import { StudyData } from "../../Models/StudyData";
import NextPrevButton from './../../Components/NextPrevButton';
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SaveIcon from '@mui/icons-material/Save';
import downloadIcon from './../../images/download.svg';
import saveIcon from './../../images/iconsave.svg';
import deleteIcon from './../../images/delete-outlined.svg';
import { read, utils } from 'xlsx';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DoneIcon from '@mui/icons-material/Done';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const templates = [
    {
        "name": "Template One",
        "json": [
            { "name": "id", "type": appConstants.STUDY_TYPE_IDENTIFIER, "sub_type": "", "options": [] },
            { "name": "phone_no", "type": appConstants.STUDY_TYPE_IDENTIFIER, "sub_type": "", "options": [] },
            { "name": "age", "type": appConstants.STUDY_TYPE_OUTCOME, "sub_type": appConstants.SUB_TYPE_CONTINUOUS, "options": [] },
            { "name": "gender", "type": appConstants.STUDY_TYPE_EXPLANATORY, "sub_type": appConstants.SUB_TYPE_CATEGORICAL, "options": ["male", "female"] }
        ]
    },
    {
        "name": "Template Two",
        "json": [
            { "name": "id", "type": appConstants.STUDY_TYPE_IDENTIFIER, "sub_type": "", "options": [] },
            { "name": "phone_no", "type": appConstants.STUDY_TYPE_IDENTIFIER, "sub_type": "", "options": [] },
            { "name": "age", "type": appConstants.STUDY_TYPE_OUTCOME, "sub_type": appConstants.SUB_TYPE_CONTINUOUS, "options": [] },
            { "name": "gender", "type": appConstants.STUDY_TYPE_EXPLANATORY, "sub_type": appConstants.SUB_TYPE_CATEGORICAL, "options": ["male", "female"] },
            { "name": "diagnosis", "type": appConstants.STUDY_TYPE_GROUP, "sub_type": "", "options": ["healthy control", "bipolar disorder"] },
        ]
    }
]
import * as appService from '../../Services/AppService'
// register Handsontable's modules
registerAllModules();

export default function Spreadsheet() {
    const hotRef = React.useRef(null);
    const navigate = useNavigate();
    const { studyId } = useParams();
    const [cols, setCols] = React.useState([]);
    const [colTypes, setColTypes] = React.useState([]);
    const [selectedColIndex, setSelectedColIndex] = React.useState(-1);
    const [open, setOpen] = React.useState(false);
    const [templateOpen, setTemplateOpen] = React.useState(false);
    const [error, setError] = React.useState({ "open": false, "title": null, "message": null, "type": 0 });
    const formRef = React.useRef(null);
    const [studyVariable, setStudyVariable] = React.useState(new StudyVariables());
    const [studyVariableList, setStudyVariableList] = React.useState([]);
    const [studyData, setStudyData] = React.useState(new StudyData());
    const loggedUser = getLoggedUser();
    const [optionsList, setOptionsList] = React.useState([]);
    const [studyObj, setStudyObj] = React.useState({});
    const currentPageCode = appConstants.data_entry_pagecode;
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [showSpreadSheet, setShowSpreadSheet] = React.useState(false);
    const [templateList, setTemplateList] = React.useState(templates);
    const [pickedTemplate, setPickedTemplate] = React.useState([]);
    const [selectedShortNames, setSelectedShortNames] = React.useState(new Set());
    const [loading, setLoading] = React.useState(true)
    const [excelJsonData, setExcelJsonData] = React.useState([]);

    const formValidation = () => {
        formRef.current.isFormValid(false).then((isValid) => {
            if (isValid) {
                console.log('VALID');
                if ((!studyVariable?.id) && selectedShortNames.has(studyVariable?.short_name)) {
                    alert('Short name cannot be same for more than one column')
                    return
                } else {
                    setSelectedShortNames(selectedShortNames.add(studyVariable?.short_name))
                }

                if (selectedColIndex != -1) {
                    setCol(studyVariable, selectedColIndex)
                }
                else {
                    addCol(studyVariable);
                }
                handleClose();
            }
            else {
                console.log('INVALID');
            }
        });
    }

    const handleSubmit = (event) => {
        formValidation();
    }

    const handleUserInput = (event) => {
        /*let values = ["group","explanatory","outcome","identifier"]
        if(event.target.name == 'name' && values.indexOf(event.target.value) != -1)
        {
            alert("Its a reserved word. Please select a different word.");
            event.target.value = "";
        }*/
        if (event?.target?.name === 'name') {
            studyVariable.short_name = appService.getShortName(event.target.value, selectedShortNames)
            setStudyVariable({ ...studyVariable, short_name: studyVariable.short_name })
        }
        setStudyVariable((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const handleUserInputOptions = (event, index) => {
        let optList = [...optionsList];
        optList[index] = event.target.value;
        setOptionsList([...optList]);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedColIndex(-1);
        setStudyVariable(new StudyVariables());
        setOptionsList([]);
    };

    const errorHandleClose = () => {
        let errorType = error.type;
        setError({ "open": false, "message": "", "title": "", "type": 0 })
        if (errorType === 1) {
            const hot = hotRef.current.hotInstance;
            setTimeout(() => {
                hot.validateCells();
            }, 100);
        }
    };

    const templateHandleClose = () => {
        setTemplateOpen(false);
        setPickedTemplate([]);
    };

    registerRenderer('customStylesRenderer', (hotInstance, TD, ...rest) => {
        textRenderer(hotInstance, TD, ...rest);
        TD.style.fontWeight = 'bold';
        TD.style.color = 'red';
        TD.style.background = '#d7f1e1';
    });

    const exportCsv = () => {
        const hot = hotRef.current.hotInstance;
        const exportPlugin = hot.getPlugin('exportFile');
        exportPlugin.downloadFile('csv', { filename: 'variables-csv-file_[YYYY]-[MM]-[DD]', columnHeaders: true });
    }

    React.useEffect(() => {
        onLoadData(false);
    }, []);

    const onLoadData = (load) => {
        //hot.setDataAtCell(0, 1, 'Ford');
        //hot.loadData(newDataset);
        //hot.updateData(newDataset);
        let studyDataSet = {};
        let studyVariables = [];
        Promise.all([
            get(getStudyVariables(studyId)),
            get(getStudyData(studyId)),
            get(getStudy(studyId))
        ]).then((res) => {
            studyVariables = res[0];
            studyDataSet = res[1];
            setStudyObj(res[2]);
            if (studyVariables.length < 1 && load) {
                let sv = new StudyVariables();
                sv.name = 'ID';
                sv.type = appConstants.STUDY_TYPE_IDENTIFIER;
                sv.study_id = studyId;
                sv.user_id = loggedUser.id;
                sv.seq = 1;
                saveStudyVariable(sv, -1, true);
                setShowSpreadSheet(true);
            }
            else if (studyVariables.length > 0) {
                studyVariables?.forEach(variable => {
                    if (variable?.short_name)
                        setSelectedShortNames(selectedShortNames.add(variable.short_name))
                })
                console.log("selected short names", selectedShortNames)
                setStudyVariableList(studyVariables);
                loadCols(studyVariables);
                setShowSpreadSheet(true);
            }
            setStudyData(studyDataSet);
            setLoading(false)
        })
    }

    const deleteSV = (id, index) => {
        del(deleteStudyVariable(id));
    }

    const saveStudyVariable = (sv, index, init) => {
        sv.study_id = studyId;
        sv.user_id = loggedUser.id;
        sv.options = optionsList
        if (sv.id) {
            put(updateStudyVariable(sv.id), sv).then(function (result) {
                if (result) {
                    let columns = cols;
                    columns[index] = sv.name;
                    setCols(columns);
                    let columnDataType = colTypes;
                    columnDataType[index] = getDataTypeFormat(sv);
                    setColTypes(columnDataType);
                    let svList = [...studyVariableList];
                    svList[index] = sv;
                    setStudyVariableList(svList);
                }
            });
        }
        else {
            post(createStudyVariable(), sv).then(function (result) {
                if (result) {
                    if (init)
                        setCols((prevState) => [...prevState, sv.name])
                    setColTypes((prevState) => [...prevState, getDataTypeFormat(sv)])
                    setStudyVariableList((prevState) => [...prevState, result]);
                }
            });
        }
    }

    const getDataTypeFormat = (sv) => {
        let format = { type: 'text' };
        if ((sv.sub_type == appConstants.SUB_TYPE_CATEGORICAL) || (sv.type == appConstants.STUDY_TYPE_GROUP)) {
            format = { type: 'autocomplete', source: sv.options, strict: true };
        }
        else if (sv.sub_type == appConstants.SUB_TYPE_CONTINUOUS) {
            format = { type: 'numeric' };
        }
        return format;
    }

    const validateVariables = () => {
        let invalidVariables = [];
        studyVariableList.forEach((element, index) => {
            if (!element.type) {
                invalidVariables.push(element.name);
            }
        });
        return invalidVariables;
    }

    const saveData = (path = null) => {
        if (!studyObj?.input_method) {
            setError({ "open": true, "title": "Error", "message": "Please choose data input method", "type": 0 })
            return false;
        }
        if (studyVariableList.length < 1) {
            setError({ "open": true, "title": "Error", "message": "Please provide atleast one variable", "type": 0 })
            return false;
        }
        let vars = validateVariables();
        if (vars.length > 0 && studyObj?.input_method !== appConstants.UPLOAD_EXCEL_FILE.value) {
            setError({ "open": true, "title": "Please assign variable types", "message": vars.join(', '), "type": 0 })
            return false;
        }
        setDialogOpen(true);
        const hot = hotRef.current.hotInstance;
        hot.validateCells((valid) => {
            if (!valid) {
                setDialogOpen(false);
                setError({ "open": true, "title": "Please enter valid data", "message": "There are errors in the data set. Please correct them before proceeding. The erroneous cells are highlighted in color", "type": 1 })
            }
            else {
                var gridData = getFilteredData();
                if (gridData.length > 0) {
                    let stuData = studyData;
                    stuData.json_data = gridData;
                    stuData.user_id = loggedUser.id;
                    if (studyData.id) {
                        put(updateStudyData(stuData.id), stuData).then(function (result) {
                            setStudyData(result);
                            setDialogOpen(false);
                            if (path)
                                navigate(path + '/' + studyId)
                        });
                    } else {
                        post(createStudyData(), stuData).then(function (result) {
                            setStudyData(result);
                            setDialogOpen(false);
                            if (path)
                                navigate(path + '/' + studyId)
                        });
                    }
                }
                else {
                    setDialogOpen(false);
                    setError({ "open": true, "title": "Error", "message": "Please enter atleast single row", "type": 0 })
                }
            }
        });
    };

    const getFilteredData = () => {
        const hot = hotRef.current.hotInstance;
        let data = hot.getData();
        var gridData = [];
        // Clear Empty rows
        data.map((value, key) => {
            let obj_values = Object.values(value);
            obj_values = obj_values.join();
            let str = obj_values.replace(/,/g, "");
            if (str) {
                gridData.push(value)
            }
        });
        return gridData;
    }

    const reorderSv = (cols) => {
        if (studyObj.id) {
            setDialogOpen(true);
            let gridData = getFilteredData();
            let stuData = studyData;
            stuData.json_data = gridData;
            stuData.user_id = loggedUser.id;
            let obj = { "cols": cols, "data": stuData };
            put(reorderStudyVariables(studyObj.id), obj).then(function (result) {
                if (result.length > 0) {
                    setStudyVariableList(result);
                    loadCols(result);
                    setStudyData(stuData);
                    const hot = hotRef.current.hotInstance;
                    hot.loadData(gridData);
                    //hot.render();
                    setDialogOpen(false);
                }
            });
        }
    }

    const loadCols = (svList) => {
        let colsData = [];
        let colsDataTypes = [];
        svList.map((sv, index) => {
            colsData.push(sv.name)
            colsDataTypes.push(getDataTypeFormat(sv))
        })
        setCols(colsData);
        setColTypes(colsDataTypes);
    }


    const selectedHeader = (index) => {
        console.log(index)
    }

    const getHeader = (index) => {
        return '<b>' + cols[index] + '</b>'
    }

    const addCol = (variable) => {
        let columns = cols;
        columns.push(variable.name);
        setCols(columns);
        variable.seq = studyVariableList.length + 1;
        saveStudyVariable(variable, -1, false);
        const hot = hotRef.current.hotInstance;
        hot.updateSettings({ columns: columns })
    }

    const setCol = (variable, index) => {
        let columns = cols;
        columns[index] = variable.name;
        setCols(columns);
        saveStudyVariable(variable, index, false);
        const hot = hotRef.current.hotInstance;
        hot.updateSettings({ columns: columns })
    }

    const addSubcategory = () => {
        setOptionsList((prevState) => [...prevState, ""]);
    };

    const deleteOptions = (index) => {
        setOptionsList((prevRows) => {
            return [
                ...optionsList.slice(0, index),
                ...optionsList.slice(1 + index),
            ];
        });
    };

    const deleteColumn = () => {
        if (confirm("Are you sure want to delete?") == true) {
            const ref = hotRef.current.hotInstance;
            let index = selectedColIndex;
            let data = ref.getData();
            data.filter(function (value) {
                value = value.splice(index, 1)
                return value;
            });
            setStudyData((prevState) => ({
                ...prevState,
                ["json_data"]: data,
            }));
            let colsObj = cols;
            let colsTypeObj = colTypes;
            colsObj.splice(index, 1);
            colsTypeObj.splice(index, 1);
            setCols(colsObj);
            setColTypes(colsTypeObj);
            deleteSV(studyVariableList[index].id);
            handleClose();
        }
    }

    //Upload Excel file
    const getVariableNull = (name, index) => {
        let sv = new StudyVariables();
        sv.name = name;
        sv.type = null;//appConstants.STUDY_TYPE_IDENTIFIER;
        sv.study_id = studyId;
        sv.user_id = loggedUser.id;
        sv.seq = index;
        return sv
    }

    const excelSave = (variableNameList, data) => {
        variableNameList.map((name, index) => {
            let studyVariable = getVariableNull(name, index + 1)
            studyVariable.study_id = studyId;
            studyVariable.user_id = loggedUser.id;
            post(createStudyVariable(), studyVariable).then(function (result) {
                /*if (result) {
                    console.log("Saved", result);
                }*/
            });
        })
        excelSaveData(data);
    }

    const excelSaveData = (data) => {
        let studyData = new StudyData();
        studyData.study_id = studyId;
        studyData.user_id = loggedUser.id;
        studyData.json_data = data;
        post(createStudyData(), studyData).then(function (result) {
            if (result) {
                updateStudyObj(false);
            }


        });
    };
    const updateStudyObj = (type) => {
        put(updateStudy(studyObj?.id), studyObj).then(result => {
            if (result) {
                onLoadData(type);
            }
        })
    }

    const jsonToMultidimensional = (jsonData) => {
        let data = [];
        let headers = [];
        jsonData.map((jd, index) => {
            if (index == 0) {
                headers = Object.keys(jd);
            }
            let values = Object.values(jd);
            data.push(values)
        })
        excelSave(headers, data);
    }


    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = utils.sheet_to_json(worksheet);
                setExcelJsonData(jsonData)
                // if (jsonData.length > 0) {
                //     jsonToMultidimensional(jsonData);
                // }
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const updateExcelData = () => {
        if (studyObj?.input_method === appConstants.UPLOAD_EXCEL_FILE.value) {
            if (excelJsonData.length > 0) {
                jsonToMultidimensional(excelJsonData);
            }
        }
        if (studyObj?.input_method === appConstants.ADD_MANUALLY.value) {
            updateStudyObj(true);
        }
        if (studyObj?.input_method === appConstants.PICK_FROM_TEMPLATE.value) {
            setTemplateOpen(true)
        }
    }

    // Templates functions
    const saveTemplate = () => {
        let templatePicked = pickedTemplate;
        templatePicked.json.map((obj, index) => {
            let studyVariable = new StudyVariables();
            studyVariable.seq = index + 1;
            studyVariable.name = obj.name;
            studyVariable.type = obj.type;
            studyVariable.sub_type = obj.sub_type;
            studyVariable.options = obj.options;
            studyVariable.study_id = studyId;
            studyVariable.user_id = loggedUser.id;
            post(createStudyVariable(), studyVariable).then(function (result) {
                if (result) {
                    onLoadData(false);
                }
            });
        })
        templateHandleClose();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        studyObj[name] = value
        setStudyObj({ ...studyObj, [name]: value })
    }

    return (
        <>
            <div className="title-frame">
                {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <NextPrevButton
                                currentPageCode={currentPageCode}
                                data={studyObj}
                                pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                                showNext={false}
                                saveFunc={saveData}
                            />
                        </Grid>
                        <Grid item>
                            <NextPrevButton
                                currentPageCode={currentPageCode}
                                data={studyObj}
                                pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                                showNext={true}
                                saveFunc={saveData}
                            />
                        </Grid>
                    </Grid>
                }
                <div className="content-frame">
                    <div className="what-is-the">Input Data</div>
                    <div className="a-brief-description">
                        <p>You can either import an Excel file containing the dataset or manually enter the data. To add a variable, click on 'Add Variable.' In the pop-up window, you can specify the properties of the variable. Please note that defining these variable properties is mandatory if you plan to use the automatic test option later.</p>
                        <p>Make sure that values entered for variables are correct and error-free. Rectify the errors, if prompted by the system validations</p>
                    </div>
                    {(loading === false && studyVariableList.length < 1) &&
                        <div style={{ width: '100%' }}>
                            {/* <Paper sx={{ my: 1, p: 2 }} elevation={12}> */}
                            <ValidatorForm ref={formRef} >
                                <Grid container spacing={2} m={2} alignItems={"center"}>
                                    <Grid item xs={12} md={3} lg={3} xl={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Choose Data Input Method</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name={'input_method'}
                                                value={studyObj?.input_method}
                                                label="Choose Data Input Method"
                                                onChange={handleChange}
                                            >
                                                {appConstants?.INPUT_METHODS?.map((option, index) => (
                                                    <MenuItem value={option?.value}>{option?.label}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {studyObj?.input_method === appConstants?.UPLOAD_EXCEL_FILE?.value &&
                                        <Grid item xs={12} md={3} lg={3} xl={3}>
                                            <TextValidator
                                                label="Choose Excel File"
                                                name="upload"
                                                type="file"
                                                fullWidth
                                                content
                                                onChange={readUploadFile}
                                                InputLabelProps={{ shrink: true }} // Add this line
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={12} md={3} lg={3} xl={3}>
                                        <Button variant="contained" color="info" onClick={updateExcelData}>confirm</Button>
                                    </Grid>
                                    {/* <Grid item xs={6} md={6} lg={6}>
                                        <TextValidator
                                            label="Upload Excel File"
                                            name="upload"
                                            type="file"
                                            fullWidth
                                            autoFocus
                                            onChange={readUploadFile}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <IconButton
                                            title={"Add"}
                                            className='primaryfilledbuttons'
                                            onClick={() => { onLoadData(true); }}
                                        >
                                            Add Manually
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <IconButton
                                            title={"Pick from template"}
                                            className='primaryfilledbuttons'
                                            onClick={() => { setTemplateOpen(true); }}
                                        >
                                            Pick from template
                                        </IconButton>
                                    </Grid> */}
                                </Grid>
                            </ValidatorForm>
                            {/* </Paper> */}
                        </div>
                    }
                </div>
                {(showSpreadSheet) &&
                    <div className="white-card-layout-with-shadow" style={{ minWidth: '100%' }} >
                        <div className="right-left-button-group">
                            <div className="back-button-frame">
                                <IconButton
                                    title={"Add"}
                                    className='primaryfilledbuttons'
                                    onClick={() => { handleClickOpen() }}
                                >
                                    <AddIcon /> Add Variables
                                </IconButton>
                            </div>
                            {/*<div className="back-button-frame">
                            <img className="delete-btn" alt="Delete Option" src={downloadIcon}
                                onClick={() => { exportCsv() }} />
                            <img className="delete-btn" alt="Save" src={saveIcon}
                                onClick={() => { saveData() }} />
                        </div>*/}
                        </div>
                        {(cols) &&
                            <HotTable
                                style={{ borderRadius: '5px' }}
                                ref={hotRef}
                                columns={colTypes}
                                colHeaders={(index) => { return getHeader(index) }}
                                rowHeaders={true}
                                data={studyData.json_data}
                                startRows={1000}
                                startCols={2}
                                width="100%"
                                height="500"
                                rowHeights={25}
                                colWidths={100}
                                minSpareRows={1000}
                                contextMenu={['row_above', 'row_below', 'remove_col', 'remove_row', 'copy', 'cut']}
                                //multiColumnSorting={true}
                                filters={true}
                                manualRowMove={true}
                                manualColumnMove={true}
                                //className="custom-table"
                                //stretchH="all"
                                //manualColumnResize={true}
                                cells={[{
                                    row: 0,
                                    //renderer: 'customStylesRenderer',
                                }]}
                                //editor={true}
                                fillHandle={true}
                                licenseKey="non-commercial-and-evaluation"
                                afterChange={function (change, source) {
                                    if (source === 'loadData') {
                                        return; //don't save this change
                                    }
                                }}
                                afterOnCellMouseDown={function (event, cell, td) {
                                    var now = new Date().getTime();
                                    // check if dbl-clicked within 1/5th of a second. change 200 (milliseconds) to other value if you want
                                    if (!(td.lastClick && now - td.lastClick < 200)) {
                                        td.lastClick = now;
                                        return; // no double-click detected
                                    }
                                    if (cell.row == -1) {
                                        setSelectedColIndex(cell.col)
                                        setStudyVariable(studyVariableList[cell.col]);
                                        setOptionsList(studyVariableList[cell.col].options);
                                        handleClickOpen();
                                    }
                                }}
                                afterColumnMove={function (movedColumns, finalIndex, dropIndex, movePossible, orderChanged) {
                                    const hot = hotRef.current.hotInstance;
                                    if (orderChanged && movePossible) {
                                        let cols = hot.getColHeader();
                                        reorderSv(cols);
                                    }
                                }}
                            />}
                    </div>}
            </div >

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={dialogOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={error.open} fullWidth={true} maxWidth="lg">
                <DialogTitle style={{ fontSize: '1rem' }}>
                    <Box sx={{ color: 'error.main' }}>{error.title}</Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={errorHandleClose}>Ok</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={templateOpen} fullScreen>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={templateHandleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Template List
                        </Typography>
                        <Button autoFocus color="inherit" onClick={saveTemplate} disabled={pickedTemplate.length < 1}>
                            Confirm
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={2}>
                            {templates.map((template, index) => {
                                return (
                                    <Grid item xs={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h5" component="div">
                                                    {template.name}
                                                </Typography>
                                                <TableContainer component={Paper}>
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Name</TableCell>
                                                                <TableCell align="right">Type</TableCell>
                                                                <TableCell align="right">Subtype</TableCell>
                                                                <TableCell align="right">Options</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {template.json.map((row) => (
                                                                <TableRow
                                                                    key={row.name}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        {row.name}
                                                                    </TableCell>
                                                                    <TableCell align="right">{row.type}</TableCell>
                                                                    <TableCell align="right">{row.sub_type}</TableCell>
                                                                    <TableCell align="right">{row.options.join(', ')}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </CardContent>
                                            <CardActions>
                                                <Button onClick={() => setPickedTemplate(template)}>
                                                    {(pickedTemplate.name == template.name) && <DoneIcon></DoneIcon>} Pick
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog open={open} fullWidth={true}>
                <DialogTitle style={{ fontSize: '1rem' }}>Add Variable</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ marginBottom: '20px', fontSize: '0.9rem', color: 'rgba(0, 0, 0, 0.87)' }}>
                        {/* Add Columns in Spreadsheet */}
                    </DialogContentText>
                    <ValidatorForm onSubmit={handleSubmit} ref={formRef}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <TextValidator
                                    label="Column Name"
                                    id="outlined-basic" variant="outlined"
                                    name="name"
                                    type="text"
                                    value={studyVariable.name}
                                    validators={validator.required.validators}
                                    errorMessages={validator.required.errorMessages}
                                    onChange={handleUserInput}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextValidator
                                    label="Short Name"
                                    id="outlined-basic" variant="outlined"
                                    name="short_name"
                                    type="text"
                                    value={studyVariable.short_name ?? ''}
                                    validators={validator.short_name.validators}
                                    errorMessages={validator.short_name.errorMessages}
                                    onChange={handleUserInput}
                                    maxlength={7}
                                    fullWidth
                                // autoFocus
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container>
                            <Grid item>
                                <FormLabel className={studyVariable.type == appConstants.STUDY_TYPE_OUTCOME ? "definitions study_type_radio-parent" : "definitions"}>
                                    <span className="side-heading">Outcome - </span>
                                    {appConstants.outcome_variable_desc}
                                </FormLabel>
                            </Grid>

                            <Grid item>
                                <FormLabel className={studyVariable.type == appConstants.STUDY_TYPE_EXPLANATORY ? "definitions study_type_radio-parent" : "definitions"}>
                                    <span className="side-heading">Explanatory - </span>
                                    {appConstants.explanatory_variable_desc}
                                </FormLabel>
                            </Grid>

                            <Grid item>
                                <FormLabel className={studyVariable.type == appConstants.STUDY_TYPE_GROUP ? "definitions study_type_radio-parent" : "definitions"}>
                                    <span className="side-heading">Group - </span>
                                    {appConstants.group_variable_desc}
                                </FormLabel>
                            </Grid>

                        </Grid>

                        <Grid container>
                            <Grid item>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        sx={{ ml: 2 }}
                                        name="type"
                                        value={studyVariable.type}
                                        onChange={handleUserInput}
                                    >
                                        <FormControlLabel
                                            value={
                                                appConstants.STUDY_TYPE_OUTCOME
                                            }
                                            control={<Radio />}
                                            label="Outcome"
                                        />
                                        <FormControlLabel
                                            value={
                                                appConstants.STUDY_TYPE_EXPLANATORY
                                            }
                                            control={<Radio />}
                                            label="Explanatory"
                                        />
                                        <FormControlLabel
                                            value={
                                                appConstants.STUDY_TYPE_IDENTIFIER
                                            }
                                            control={<Radio />}
                                            label="Identifier"
                                        />
                                        <FormControlLabel
                                            value={
                                                appConstants.STUDY_TYPE_GROUP
                                            }
                                            control={<Radio />}
                                            label="Group"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {((studyVariable.type == appConstants.STUDY_TYPE_EXPLANATORY) || (studyVariable.type == appConstants.STUDY_TYPE_OUTCOME)) && (
                            <>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormLabel className="subheading">
                                            {"Type:"}
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <FormLabel className={studyVariable.sub_type === appConstants.SUB_TYPE_CONTINUOUS ? "definitions study_type_radio-parent" : "definitions"}>
                                            <span className="side-heading">Continuos - </span>
                                            {appConstants.define_outcome_variable_desc2}
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <FormLabel className={studyVariable.sub_type === appConstants.SUB_TYPE_CATEGORICAL ? "definitions study_type_radio-parent" : "definitions"}>
                                            <span className="side-heading">Discrete - </span>
                                            <span>{appConstants.define_outcome_variable_desc1}</span>
                                            <p>While adding a discrete variable, please define the subcategories of the variable using the "Add Options button" below</p>
                                        </FormLabel>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid container spacing={2}>
                            {((studyVariable.type == appConstants.STUDY_TYPE_EXPLANATORY) || (studyVariable.type == appConstants.STUDY_TYPE_OUTCOME)) && (
                                <Grid item>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            sx={{ ml: 2 }}
                                            name="sub_type"
                                            value={studyVariable.sub_type}
                                            onChange={handleUserInput}
                                        >
                                            <FormControlLabel
                                                value={
                                                    appConstants.SUB_TYPE_CONTINUOUS
                                                }
                                                control={<Radio />}
                                                label="Continuous"
                                            />
                                            <FormControlLabel
                                                value={
                                                    appConstants.SUB_TYPE_CATEGORICAL
                                                }
                                                control={<Radio />}
                                                label="Discrete"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            )}
                            {((studyVariable.sub_type == appConstants.SUB_TYPE_CATEGORICAL) || (studyVariable.type == appConstants.STUDY_TYPE_GROUP)) && (
                                <React.Fragment>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <FormLabel className="side-heading" style={{ fontSize: '0.9rem' }}>

                                            Provide discrete values of the selected outcome variable of subcategories under {studyVariable.type == appConstants.STUDY_TYPE_GROUP ? 'group' : studyVariable.type == appConstants.STUDY_TYPE_EXPLANATORY ? 'explanatory' : 'outcome'} variable

                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <IconButton
                                            onClick={() => { addSubcategory() }}
                                            className='primaryfilledbuttons'
                                        >
                                            <AddIcon /> Add Options
                                        </IconButton>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={2} style={{ marginTop: '10px' }}>
                            {optionsList.map((option, index) => (
                                <Grid key={"name_" + index} item sm={12} md={12} lg={6} xl={6} >
                                    <TextField
                                        label={"Option " + (index + 1)}
                                        value={
                                            option ? option : ""
                                        }
                                        onChange={(event) => {
                                            handleUserInputOptions(event, index);
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton
                                                        onClick={() => { deleteOptions(index) }}
                                                        edge="end"
                                                    >
                                                        <img src={deleteIcon} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                            )
                            )}
                        </Grid>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <IconButton
                        onClick={() => { handleClose() }}
                        className='primaryoutlinedbuttons'>
                        Cancel
                    </IconButton>
                    <IconButton
                        onClick={() => { deleteColumn() }}
                        className='primaryoutlinedbuttons'
                    >
                        Delete
                    </IconButton>
                    <IconButton
                        onClick={() => { handleSubmit() }}
                        className='primaryfilledbuttons'
                    >
                        Submit
                    </IconButton>
                </DialogActions>
            </Dialog>
        </>
    )
}