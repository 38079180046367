import React, { useCallback } from "react";
import useRazorpay from "react-razorpay";
import { getLoggedUser} from "../Storage/Getter";
import get from "../Api/Config/GetConfig";
import { createSucceededTransaction, createFailedTransaction } from '../Api/UserApi';
import { setLoggedUser } from "../Storage/Setter";
import { Container } from "@mui/system";
import { Typography } from "@mui/material";
import { Card } from "@mui/material";
import { Grid } from "@mui/material";
import { CardHeader } from "@mui/material";
import { CardContent } from "@mui/material";
import { Box } from "@mui/material";
import { CardActions } from "@mui/material";
import { Button } from "@mui/material";
import StarIcon from '@mui/icons-material/StarBorder';
import post from '../Api/Config/PostConfig';
import moment from 'moment';
import { useNavigate, useLocation } from "react-router-dom";
import { POINTS_MULTIPLIER, POINTS_DENOMINATION } from "../AppConstants";
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

export default function AddPointsPage(props) {

    const loggedUser = getLoggedUser();
    const navigate = useNavigate("");
    const [points,setPoints] = React.useState(POINTS_DENOMINATION);
    const location = useLocation();
    
    React.useEffect(() => {
        if(location.pathname.indexOf('runTests') == -1)
        {
            props.setRequiredPoints(0);
            props.setFinalPoints(0);
            props.setRunTests(false);
        }
    }, []);

    const Razorpay = useRazorpay();

    const handlePayment = () => {
        let amount = points * POINTS_MULTIPLIER;
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
            amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: points+" pts",
            description: "Adding "+points+" pts to "+loggedUser.email,
            image: "https://shodh.ai/favicon.ico",
            handler: function (response) {
                let obj = {
                    payment_id : response.razorpay_payment_id,
                    amount : amount,
                    points: points,
                }
                post(createSucceededTransaction(loggedUser.id), obj).then(function (result) {
                    let user = loggedUser;
                    user.points_balance = result.points_balance;
                    setLoggedUser(JSON.stringify(user));
                    props.setPointsAvailable(result.points_balance);
                    if(props.requiredPoints > 0){
                        let fPoints = result.points_balance - props.requiredPoints;
                        props.setFinalPoints(fPoints);
                        if(fPoints >= 0){
                            props.setRunTests(true);
                        }
                        else{
                            props.handleClickOpenIns(true);
                        }    
                    }
                    props.triggerToastMessage({ isOpen: true, text: points+" Points added successfully", level: 'success' });
                    props.handleCloseOpenPointsDialog();
                }); 
            },
            prefill: {
                name: loggedUser.first_name,
                email: loggedUser.email,
                contact: loggedUser.phone
            },
            theme: {
                color: "#3399cc",
            },
        };
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            let obj = {
                "error_code" : response.error.code,
                "error_description" : response.error.description,
                "error_source" : response.error.source,
                "error_step" : response.error.step,
                "error_reason" : response.error.reason,
                "error_payment_id" : response.error.metadata.payment_id,
                "amount" : amount,
                "points" : points
            }
            post(createFailedTransaction(loggedUser.id), obj).then(function (result) {
                props.triggerToastMessage({ isOpen: true, text: "Failed/Cancelled transaction", level: 'error' });
                props.handleCloseOpenPointsDialog();
            });// Capture Failed Transaction

        });
        rzp1.open();
    };

    const addPoints = () => {
        let curPoints = points;
        curPoints += POINTS_DENOMINATION;
        setPoints(curPoints);
    }

    const reducePoints = () => {
        let curPoints = points;
        curPoints -= POINTS_DENOMINATION;
        if(curPoints < POINTS_DENOMINATION)
        {
            props.triggerToastMessage({ isOpen: true, text: "Minimum "+POINTS_DENOMINATION+" points required.", level: 'error' });
            return false;
        }
        setPoints(curPoints);
    }

    return (
        <> 
            <Grid container spacing={2} sx={{"marginTop":"5px"}}>
                <Grid item xs={12}>
                    <Button variant="outlined" sx={{"height":"55px"}} onClick={reducePoints}><RemoveIcon/></Button>
                    <TextField
                        id="outlined-required"
                        label="Points"
                        value={points}
                        type="number"
                        disabled={true}
                    />
                    <Button variant="outlined" sx={{"height":"55px"}} onClick={addPoints}><AddIcon/></Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="div" variant="body1">
                        <Box sx={{ color: 'primary.main' }}>
                            Amount to be paid : INR {points * POINTS_MULTIPLIER}
                        </Box>
                    </Typography>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2} style={{ justifyContent: 'flex-end' }}>
                <Grid item>
                    <Button variant="contained" color="success" onClick={handlePayment}>Pay INR {points * POINTS_MULTIPLIER}</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="error" onClick={props.handleCloseOpenPointsDialog}>Cancel</Button>
                </Grid>
            </Grid>
        </>
    );
}