import * as React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./screens.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureStore from "./store";
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'bootstrap/dist/css/bootstrap.min.css';
const rootElement = document.getElementById("root");

ReactDOM.render(

    <GoogleOAuthProvider clientId={"143447803615-3aobk4d9cc1vglfitliv1r1nk020vung.apps.googleusercontent.com"}>
        <Provider store={configureStore()}>
            <App />
        </Provider>
    </GoogleOAuthProvider>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
