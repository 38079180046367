import React from "react";
import { getStudy } from "../../Api/StudyApi";
import { getStudyExplanatoryVariables } from "../../Api/StudyVariableApi";
import { getStudyTestByStudyId, updateStudyTest, createStudyTests, updateTestsAutomated } from "../../Api/StudyTestApi";
import { useNavigate, useParams } from "react-router-dom";
import post from "../../Api/Config/PostConfig";
import put from "../../Api/Config/PutConfig";
import get from "../../Api/Config/GetConfig";
import { Study } from "../../Models/Study";
import { StudyVariables } from "../../Models/StudyVariables";
import { Grid, InputLabel, MenuItem, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import * as appConstants from "../../AppConstants";
import { getLoggedUser } from "../../Storage/Getter";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import NextPrevButton from "../../Components/NextPrevButton";
import Select from '@mui/material/Select';
import { StudyTest } from "../../Models/StudyTest";
import deleteIcon from '../../images/delete-icon.svg'
import '../outcome-variable/OutcomeVariablePage.css'
import DeleteIcon from '@mui/icons-material/Delete';
function ExplanatoryVariablePage() {
    const navigate = useNavigate();
    const { studyId } = useParams();
    const currentPageCode = appConstants.explanatory_variable_pagecode;
    const [studyObj, setStudyObj] = React.useState(new Study());
    const [studyTest, setStudyTest] = React.useState(new StudyTest());
    const [explanatoryVariables, setExplanatoryVariables] = React.useState([]);
    const [selExplanatoryVar, setSelExplanatoryVar] = React.useState(0);
    const [explanatoryVarList, setExplanatoryVarList] = React.useState([]);
    const [studyVariables, setStudyVariables] = React.useState(new StudyVariables());
    const loggedUser = getLoggedUser();


    const setDefaultstudyVariableExplanatoryList = (svList, st) => {
        let defaultSV = [];
        // If Study test is already saved to study_tests tables set the saved test variables
        if (st && st['id'] && st[appConstants.STUDY_TYPE_EXPLANATORY].length > 0) {
            setExplanatoryVariables(st[appConstants.STUDY_TYPE_EXPLANATORY]);
        }
        // Variables not saved to study_test table yet or 
        // first when they come to this page set group varialbes from study_variables table
        if (svList.length > 0) {
            svList.forEach(element => {
                defaultSV.push(parseInt(element.id));
            });
        }
        setExplanatoryVarList(defaultSV);
    };

    React.useEffect(() => {
        Promise.all([
            get(getStudy(studyId)),
            get(getStudyExplanatoryVariables(studyId)),
            get(getStudyTestByStudyId(studyId))
        ]).then((res) => {
            setStudyObj(res[0]);
            setStudyVariables(res[1]);
            setStudyTest(res[2]);
            setDefaultstudyVariableExplanatoryList(res[1], res[2]);
        });
    }, []);

    const getVariableValue = (colName, ocVarId) => {
        let temp = _.find(studyVariables, ['id', ocVarId]);
        if (temp && temp?.id)
            return temp[colName];
        else return '';
    }

    const removeExplanatoryVariable = (ocId) => {
        // push selected to main explanatory variables list
        let ocVarList = [...explanatoryVarList];
        ocVarList.push(ocId);
        setExplanatoryVarList(ocVarList);

        // remove from explanatory variables
        let ocList = [...explanatoryVariables];
        ocList = _.without(ocList, ocId);
        setExplanatoryVariables(ocList);
    }

    const handleUserInput = (event) => {
        setSelExplanatoryVar(parseInt(event.target.value));
    };

    const addSelExplanatoryVarToList = () => {
        let checkValue = _.find(explanatoryVariables, function (num) {
            if (selExplanatoryVar == num) {
                return true;
            }
        });
        if (!checkValue) {
            // push selected explanatory variable
            let ocVarList = [...explanatoryVariables];
            ocVarList.push(selExplanatoryVar);
            setExplanatoryVariables(ocVarList);

            // remove from main explanatory variables list
            let ocList = [...explanatoryVarList];
            ocList = _.without(ocList, selExplanatoryVar);
            setExplanatoryVarList(ocList);
        }
        else {
            alert('Already inserted');
        }
        // finally reset state variable
        setSelExplanatoryVar(0);
    }

    const saveStudyTestAndVariables = (path) => {
        studyTest[appConstants.STUDY_TYPE_EXPLANATORY] = explanatoryVariables;
        Promise.all([
            saveStudyTest(studyTest),
            updateAutomatedTests()
        ]).then((res) => {
            navigate(path + '/' + studyId);
        });
    }

    const saveStudyTest = (studyTestObj) => {
        if (studyTestObj?.id)
            return put(updateStudyTest(studyTestObj.id), studyTestObj);
        else {
            studyTestObj.study_id = parseInt(studyId);
            studyTestObj.user_id = parseInt(loggedUser.id);
            studyTestObj[appConstants.STUDY_TYPE_GROUP] = [];
            studyTestObj[appConstants.STUDY_TYPE_EXPLANATORY] = [];
            return post(createStudyTests(), studyTestObj);
        }
    }

    const updateAutomatedTests = () => {
        if (studyObj.statistical_test_type == 1) {
            return get(updateTestsAutomated(studyId));
        }
    }

    return (
        <div className="title-frame">
            {
                (studyObj?.title && studyObj?.mode_of_data_entry) &&
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                            saveFunc={saveStudyTestAndVariables}
                            showNext={false}
                        />
                    </Grid>
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                            saveFunc={saveStudyTestAndVariables}
                            showNext={true}
                        />
                    </Grid>
                </Grid>
            }
            <div className="content-frame">
                <div className="what-is-the">{appConstants.explanatory_variable_question}</div>
                <div className="a-brief-description">
                    From the list of variables that you have defined as explanatory, choose the variable you want to add in this test and click 'Add'. You can choose multiple variables also.
                </div>
            </div>
            <br />
            <div className="testlist-cards3">
                <Grid container spacing={2} style={{ alignItems: 'center', gap: '2rem' }}>
                    <Grid item>
                        <FormControl fullWidth style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Variable</InputLabel>
                            <Select
                                value={selExplanatoryVar ? selExplanatoryVar : ''}
                                name={appConstants.STUDY_TYPE_GROUP}
                                onChange={handleUserInput}
                                label={"Variable"}
                                className="text-fieldwithicon"
                            >
                                {explanatoryVarList.map((element) => (
                                    <MenuItem
                                        key={element}
                                        value={element}
                                    >
                                        {getVariableValue('name', element)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={addSelExplanatoryVarToList}
                            title={'Add'}
                            className='primaryfilledbuttons'
                            style={{ width: '102px', padding: '24px' }}
                        >
                            <AddIcon /> Add
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <TableContainer style={{ width: 'fit-content' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>#</TableCell>
                                    <TableCell align='center'>Variable Name</TableCell>
                                    <TableCell align='center'>Measurement Type</TableCell>

                                    <TableCell align='center'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {explanatoryVariables?.map((item, index) => (
                                    <TableRow
                                        key={1}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{getVariableValue('name', item)}</TableCell>
                                        <TableCell>{getVariableValue('sub_type', item)}</TableCell>
                                        <TableCell align='center'>
                                            <Button variant="text" color="error" title="Delete pair" onClick={() => { removeExplanatoryVariable(item) }}>
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table><br />
                    </TableContainer>
                    {/* {explanatoryVariables.map((item, index) => (
                        <Grid item>
                            <div className="white-card-layout-with-shadow">
                                <div class="variable-name-retinal-artery-parent" data-locofyid="470:5520">
                                    <div class="variable-name-retinal-artery-container" data-locofyid="470:5521">
                                        <span class="variable-name" data-locofyid="470:5521-2">Variable name:</span>
                                        <span class="span1" data-locofyid="470:5521-3"> </span>
                                        <span data-locofyid="470:5521-1">
                                            {getVariableValue('name', item)}
                                        </span>
                                    </div>
                                    <div class="variable-name-retinal-artery-container" data-locofyid="470:5522">
                                        <span class="variable-name" data-locofyid="470:5522-1">Measurement type:</span>
                                        <span class="span1" data-locofyid="470:5522-3"> </span>
                                        <span data-locofyid="470:5522-2">
                                            {getVariableValue('sub_type', item)}
                                        </span>
                                    </div>
                                    <div class="secondaryoutlinedbuttons-wrapper" data-locofyid="470:5523" onClick={() => { removeExplanatoryVariable(item) }}>
                                        <div class="secondaryoutlinedbuttons" data-locofyid="470:5524">
                                            <div class="icontrashplain" data-locofyid="I470:5524;470:5073">
                                                <div class="state-layer43" data-locofyid="I470:5524;470:5073;468:3569">
                                                    <img class="icon10" alt="" src={deleteIcon} />
                                                </div>
                                            </div>
                                            <div class="button-text6" data-locofyid="I470:5524;94:5176">
                                                Delete
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    ))} */}
                </Grid>
            </div>
        </div>
    );
}

export default ExplanatoryVariablePage;