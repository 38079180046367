export const createStudy = () =>{
    return process.env.REACT_APP_URL+'study';
}

export const getStudies = (userId) =>{
    return process.env.REACT_APP_URL+'studyList/'+userId;
}

export const getStudy = (id) =>{
    return process.env.REACT_APP_URL+'study/'+id;
}

export const updateStudy = (id) =>{
    return process.env.REACT_APP_URL+'study/'+id;
}

export const runTestsStudy = (id, pointsOverview) =>{
    return process.env.REACT_APP_URL+'study/runTests/'+id+'/'+pointsOverview;
}