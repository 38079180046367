export class StudyVariables{
    name = ""
    type = ""
    type_length = 10
    seq = 0
    data = [] 
    options = []
    diagnosis = []
    outcome = false
    sub_type = ""
    timeline = false
    created_by = ""
    updated_by = ""
    user_id = 0
    study_id = 0
}