import React, { useState } from "react";
import { getStudy } from "../../Api/StudyApi";
import { getStudyOutcomeContinuousVariables } from "../../Api/StudyVariableApi";
import { getStudyTestByStudyId, updateStudyTest, createStudyTests } from "../../Api/StudyTestApi";
import { useNavigate, useParams } from "react-router-dom";
import post from "../../Api/Config/PostConfig";
import put from "../../Api/Config/PutConfig";
import get from "../../Api/Config/GetConfig";
import { Study } from "../../Models/Study";
import { StudyVariables } from "../../Models/StudyVariables";
import { Grid, InputLabel, MenuItem, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import * as appConstants from "../../AppConstants";
import { getLoggedUser } from "../../Storage/Getter";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import NextPrevButton from "../../Components/NextPrevButton";
import Select from '@mui/material/Select';
import { StudyTest } from "../../Models/StudyTest";
import './OutcomeVariablePage.css'
import deleteIcon from '../../images/delete-icon.svg'
import DeleteIcon from '@mui/icons-material/Delete';
function OutcomeVariablePage(props) {
    const navigate = useNavigate();
    const { studyId } = useParams();
    const currentPageCode = appConstants.outcome_variable_pagecode;
    const [studyObj, setStudyObj] = React.useState(new Study());
    const [studyTest, setStudyTest] = React.useState(new StudyTest());
    const [outcomeVariables, setOutcomeVariables] = React.useState([]);
    const [selOutcomeVar, setSelOutcomeVar] = React.useState(0);
    const [outcomeVarList, setOutcomeVarList] = React.useState([]);
    const [studyVariables, setStudyVariables] = React.useState(new StudyVariables());
    const [showPairedVariables, setShowPairedVariables] = React.useState(false);
    const defaultPairValues = { pair1: null, pair1Name: null, pair2: null, pair2Name: null }
    const [selOutcomeVariables, setSelOutcomeVariables] = React.useState(defaultPairValues)
    const [outcomeVariableList, setOutComeVariableList] = useState([])

    const loggedUser = getLoggedUser();

    const setDefaultstudyVariableOutcomeList = (svList, studyTest) => {
        let defaultSV = [];
        // If Study test is already saved to study_tests tables set the saved test variables
        if (studyTest && studyTest['id'] && studyTest[appConstants.STUDY_TYPE_OUTCOME].length > 0) {
            setOutcomeVariables(studyTest[appConstants.STUDY_TYPE_OUTCOME]);
        }
        // Variables not saved to study_test table yet or 
        // first when they come to this page set group varialbes from study_variables table
        if (svList.length > 0) {
            svList.forEach(element => {
                defaultSV.push(parseInt(element.id));
            });
        }
        setOutcomeVarList(defaultSV);
    };

    React.useEffect(() => {
        Promise.all([
            get(getStudy(studyId)),
            get(getStudyOutcomeContinuousVariables(studyId)),
            get(getStudyTestByStudyId(studyId))
        ]).then((res) => {
            let study = res[0];
            setStudyObj(res[0]);
            let outcomeVar = res[1]
            if((study.type === appConstants.study_type[1].value ||
                (study.statistical_test_group == 'longitudinal' && (study.test_pairedttest) || (study.test_wilcoxon))) && outcomeVar.length > 1) {
                    setShowPairedVariables(true)
            }
            setOutComeVariableList(outcomeVar)
            setStudyVariables(res[1]);
            setStudyTest(res[2]);
            setDefaultstudyVariableOutcomeList(res[1], res[2]);
        });
    }, []);

    const getVariableValue = (colName, ocVarId) => {
        let temp = _.find(studyVariables, ['id', ocVarId]);
        if (temp && temp?.id)
            return temp[colName];
        else return '';
    }

    const removeOutcomeVariable = (ocId) => {
        // push selected to main outcome variables list
        let ocVarList = [...outcomeVarList];
        ocVarList.push(ocId);
        setOutcomeVarList(ocVarList);

        // remove from outcome variables
        let ocList = [...outcomeVariables];
        ocList = _.without(ocList, ocId);
        setOutcomeVariables(ocList);
    }

    const handleUserInput = (event) => {
        setSelOutcomeVar(parseInt(event.target.value));
    };

    const addSelOutcomeVarToList = () => {
        let checkValue = _.find(outcomeVariables, function (num) {
            if (selOutcomeVar == num) {
                return true;
            }
        });
        if (!checkValue) {
            // push selected outcome variable
            let ocVarList = [...outcomeVariables];
            ocVarList.push(selOutcomeVar);
            setOutcomeVariables(ocVarList);

            // remove from main outcome variables list
            let ocList = [...outcomeVarList];
            ocList = _.without(ocList, selOutcomeVar);
            setOutcomeVarList(ocList);
        }
        else {
            alert('Already inserted');
        }
        // finally reset state variable
        setSelOutcomeVar(0);
    }

    const saveStudyTestAndVariables = (path) => {
        studyTest[appConstants.STUDY_TYPE_OUTCOME] = outcomeVariables;
        Promise.all([
            saveStudyTest(studyTest)
        ]).then((res) => {
            navigate(path + '/' + studyId);
        });
    }

    const saveStudyTest = (studyTestObj) => {
        if (studyTestObj?.id)
            return put(updateStudyTest(studyTestObj.id), studyTestObj);
        else {
            studyTestObj.study_id = parseInt(studyId);
            studyTestObj.user_id = parseInt(loggedUser.id);
            studyTestObj[appConstants.STUDY_TYPE_GROUP] = [];
            studyTestObj[appConstants.STUDY_TYPE_EXPLANATORY] = [];
            return post(createStudyTests(), studyTestObj);
        }
    }

    const handleAddPair = () => {
        if (!studyTest?.paired_variables?.length > 0) {
            studyTest.paired_variables = []
        }
        if ((selOutcomeVariables?.pair1 > 0 && selOutcomeVariables?.pair2 > 0) === false) {
            props.triggerToastMessage({ isOpen: true, text: "Please input both the pairs", level: 'error' });
            return
        }
        if (selOutcomeVariables.pair1 === selOutcomeVariables.pair2) {
            props.triggerToastMessage({ isOpen: true, text: "The pairs should be different from one another", level: 'error' });
            return
        }
        const checkIfExists = studyTest.paired_variables.some(pv => (pv.pair1 === selOutcomeVariables.pair1 && pv.pair2 == selOutcomeVariables.pair2) || (pv.pair1 === selOutcomeVariables.pair2 && pv.pair2 == selOutcomeVariables.pair1))
        if (checkIfExists === true) {
            props.triggerToastMessage({ isOpen: true, text: "The selected pair already exists in the list", level: 'error' });
        } else {
            studyTest.paired_variables = [...studyTest.paired_variables, selOutcomeVariables]
            setStudyTest({ ...studyTest, paired_variables: studyTest.paired_variables });
            setSelOutcomeVariables(defaultPairValues)
        }
    }

    const handleDelete = (item) => {
        let tempArr = studyTest.paired_variables;
        let deleteIndex = tempArr.findIndex(ov => ov.id === item?.id);
        if (deleteIndex >= 0) {
            tempArr.splice(deleteIndex, 1);
            console.log(tempArr)
            setStudyTest({ ...studyTest, paired_variables: tempArr })
        }
    }
    const handleChnage = (e) => {
        const { name, value } = e.target;
        const selectedOption = outcomeVariableList.find(ov => ov.id === value);
        setSelOutcomeVariables({ ...selOutcomeVariables, [name]: selectedOption?.id, [`${name}Name`]: selectedOption?.name })
    }
    return (
        <div className="title-frame">
            {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                            saveFunc={saveStudyTestAndVariables}
                            showNext={false}
                        />
                    </Grid>
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                            saveFunc={saveStudyTestAndVariables}
                            showNext={true}
                        />
                    </Grid>
                </Grid>
            }
            <div className="content-frame">
                <div className="what-is-the">{appConstants.define_outcome_variable}</div>
                <div className="a-brief-description">
                    From the list of variables that you have defined as outcome, choose the variable you want to measure in this test and click 'Add'. You can choose multiple variables also.
                </div>
            </div><br />
            <div className="testlist-cards3">
                <Grid container spacing={2} style={{ alignItems: 'center', gap: '2rem' }}>
                    <Grid item>
                        <FormControl fullWidth error={(outcomeVarList.length > 0) ? false : true} style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Variable</InputLabel>
                            <Select
                                value={selOutcomeVar ? selOutcomeVar : 0}
                                name={appConstants.STUDY_TYPE_GROUP}
                                onChange={handleUserInput}
                                label={"Variable"}
                                className="text-fieldwithicon"
                            >
                                {outcomeVarList.map((element) => (
                                    <MenuItem
                                        key={element}
                                        value={element}
                                    >
                                        {getVariableValue('name', element)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={addSelOutcomeVarToList}
                            title={'Add'}
                            className='primaryfilledbuttons'
                            style={{ width: '102px', padding: '24px' }}
                        >
                            <AddIcon /> Add
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <TableContainer style={{ width: 'fit-content' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>#</TableCell>
                                    <TableCell align='center'>Variable Name</TableCell>
                                    <TableCell align='center'>Measurement Type</TableCell>

                                    <TableCell align='center'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {outcomeVariables?.map((item, index) => (

                                    <TableRow
                                        key={1}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{getVariableValue('name', item)}</TableCell>
                                        <TableCell>{getVariableValue('sub_type', item)}</TableCell>
                                        <TableCell align='center'>
                                            <Button variant="text" color="error" title="Delete pair" onClick={() => { removeOutcomeVariable(item) }}>
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table><br />
                    </TableContainer>
                    {/* {outcomeVariables.map((item, index) => (
                        <Grid item>
                            <div className="white-card-layout-with-shadow">
                                <div class="variable-name-retinal-artery-parent" data-locofyid="470:5520">
                                    <div class="variable-name-retinal-artery-container" data-locofyid="470:5521">
                                        <span class="variable-name" data-locofyid="470:5521-2">Variable name:</span>
                                        <span class="span1" data-locofyid="470:5521-3"> </span>
                                        <span data-locofyid="470:5521-1">
                                            {getVariableValue('name', item)}
                                        </span>
                                    </div>
                                    <div class="variable-name-retinal-artery-container" data-locofyid="470:5522">
                                        <span class="variable-name" data-locofyid="470:5522-1">Measurement type:</span>
                                        <span class="span1" data-locofyid="470:5522-3"> </span>
                                        <span data-locofyid="470:5522-2">
                                            {getVariableValue('sub_type', item)}
                                        </span>
                                    </div>
                                    <div class="secondaryoutlinedbuttons-wrapper" data-locofyid="470:5523" onClick={() => { removeOutcomeVariable(item) }}>
                                        <div class="secondaryoutlinedbuttons" data-locofyid="470:5524">
                                            <div class="icontrashplain" data-locofyid="I470:5524;470:5073">
                                                <div class="state-layer43" data-locofyid="I470:5524;470:5073;468:3569">
                                                    <img class="icon10" alt="" src={deleteIcon} />
                                                </div>
                                            </div>
                                            <div class="button-text6" data-locofyid="I470:5524;94:5176">
                                                Delete
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    ))} */}
                </Grid>
                {showPairedVariables &&
                    <React.Fragment>
                        <InputLabel id="demo-simple-select-label">Define Pairs</InputLabel>
                        <Grid ml={2} container style={{ alignItems: 'center', gap: '2rem' }}>
                            {appConstants.pairing_variables.map((pair, index) => (
                                <Grid item>
                                    <FormControl fullWidth error={(outcomeVariableList?.length > 0) ? false : true} style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">{pair?.lable}</InputLabel>
                                        <Select
                                            value={selOutcomeVariables?.[pair?.name] ?? null}
                                            name={pair?.name}
                                            onChange={handleChnage}
                                            label={pair?.lable}
                                            className="text-fieldwithicon"
                                        >
                                            {outcomeVariableList?.map((element) => (
                                                <MenuItem
                                                    key={element?.id}
                                                    value={element?.id}
                                                >{element?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ))}
                            <Grid item>
                                <IconButton
                                    onClick={handleAddPair}
                                    title={'Pair'}
                                    className='primaryfilledbuttons'
                                    style={{ width: '102px', padding: '24px' }}
                                >
                                    <AddIcon />&nbsp;Pair
                                </IconButton>
                            </Grid>
                        </Grid>
                        {studyTest.paired_variables?.length > 0 &&
                            <TableContainer style={{ width: 'fit-content' }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center'>Pairs</TableCell>
                                            <TableCell align='center'>Variable 1</TableCell>
                                            <TableCell align='center'>Variable 2</TableCell>
                                            <TableCell align='center'>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {studyTest?.paired_variables?.map((pair, index) => (

                                            <TableRow
                                                key={1}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{pair?.pair1Name}</TableCell>
                                                <TableCell>{pair?.pair2Name}</TableCell>
                                                <TableCell align='center'>
                                                    <Button variant="text" color="error" title="Delete pair" onClick={() => handleDelete(pair)}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table><br /><br />
                            </TableContainer>
                        }
                    </React.Fragment>
                }
            </div>
        </div >
    );
}

export default OutcomeVariablePage;
