import SigninMain from "./SignInMain";
import "./SignInDesktop.css";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LEftGrfx from '../../images/LEftGrfx.png'
import shodh_ai from '../../images/shodh_ai.jpeg'
import mobileLogoFrame from '../../images/mobile_frame.svg'
import summitLogo from './../../images/summitlogo@2x.png'
import { Grid, InputLabel, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import shodh_automatic from '../../images/shodh_automatic.png'
import ml from '../../images/ml.png'
import ready_reports from '../../images/ready_reports.png'
import user_friendly from '../../images/user_friendly.png'
import advanced_analytical from '../../images/advanced_analytical.png'
import dynamic_data_visualization from '../../images/dynamic_data_visualization.png'
import customizable_reports from '../../images/customizable_reports.png'
import cross_platform_compatibility from '../../images/cross_platform_compatibility.png'


const SignInDesktop = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        // props.showNavBar(true)
    }, [])

    const gotoPrivacy = () =>{
        navigate('/privacy')
    }

    const gotoRefund = () =>{
        navigate('/refund')
    }

    const gotoTerms = () =>{
        navigate('/terms')
    }

    const CardComponent = ({ imgSrc, title, description, style = { width: 'auto', margin: 'auto' } }) => {
        return (
            <Card sx={{ maxWidth: 345 }} style={{ height: '100%', width: '100%' }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        image={imgSrc}
                        alt="green iguana"
                        style={style}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }
    return (
        <div className="sign-indesktop">
            <main className="left-graphic-frame">
                <img
                    className="leftgrfx-icon"
                    loading="eager"
                    alt=""
                    src={shodh_ai}
                />
                <img
                    className="mobile_logo_frame"
                    loading="eager"
                    alt=""
                    src={mobileLogoFrame}
                />
                <div className="signin-main">
                    <SigninMain {...props} />

                    <div className="privacy-text">
                        <a href="javascript:void(0)" onClick={gotoPrivacy}>Privacy policy</a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="javascript:void(0)" onClick={gotoTerms}>Terms of use</a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="javascript:void(0)" onClick={gotoRefund}>Refund policy</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className="top-header-frame">
                        <img
                            className="logo-1-icon"
                            loading="eager"
                            alt=""
                            src={summitLogo}
                        />
                    </div>

                </div>

            </main>
            <br />
            <Grid container justifyContent={"center"}>
                <Grid item>
                    <InputLabel><h5><b>Key Features</b></h5></InputLabel>
                </Grid>
            </Grid>
            <Grid container m={'auto'} columnSpacing={2} rowSpacing={2} justifyContent={"center"} className="key_feature_section">
                <Grid item xs={12} md={6} lg={4} className="key_fetures_card">
                    <CardComponent
                        title="Machine Learning Integration"
                        imgSrc={ml}
                        description="Harness the power of machine learning with built-in algorithms for analytics, test selection and report generation"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <CardComponent
                        title="Ready reports"
                        imgSrc={ready_reports}
                        description="Shodh-AI provides you a report that is ready to go into your thesis or paper with printable tables, description of the results and interpretation of the results. Bye bye to the days of writing results manually"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <CardComponent
                        title="User-Friendly Interface"
                        imgSrc={user_friendly}
                        description="Navigate through your data with ease, thanks to a user-friendly dashboard that simplifies complex operations without compromising on functionality."
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <CardComponent
                        title="Advanced Analytical Tools"
                        imgSrc={advanced_analytical}
                        description="Dive deep into your data with a wide range of commonly used statistical tests for hypothesis testing, and time-series data. We are constantly improvising the test list and will soon include regression analysis and covariate correction."
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <CardComponent
                        title="Dynamic Data Visualization"
                        imgSrc={dynamic_data_visualization}
                        description="Create stunning, interactive charts and graphs that bring your data to life."
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <CardComponent
                        title="Customizable Reports"
                        imgSrc={customizable_reports}
                        description="Detailed reports are generated in an editable Word format that allow you to highlight key insights and present your findings professionally."
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <CardComponent
                        title="Cross-Platform Compatibility"
                        imgSrc={cross_platform_compatibility}
                        description="Access your data and continue your work from anywhere, with cloud-based synchronization across desktop, laptop or tablets in Windows, macOS, and Linux systems."
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default SignInDesktop;