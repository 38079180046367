import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { TEXT_BOX_COLOR, TEXT_BOX_VARIANT, FORM_BUTTON_VARIANT } from '../Helper/FormConstants';
import * as validator from '../Helper/Validator';
import { useNavigate, useParams } from "react-router-dom";
import { getUser, updateUser } from '../Api/UserApi';
import put from '../Api/Config/PutConfig';
import get from '../Api/Config/GetConfig';
import { UserProfile } from '../Models/UserProfile';
const theme = createTheme();

export default function UserProfilePage(props) {

    const [formErrors, setFormErrors] = React.useState([]);
    const navigate = useNavigate();
    const { userId } = useParams();
    var user = new UserProfile();
    const [userObj, setUserObj] = React.useState(user);

    React.useEffect(() => {
        if (userId) {
            get(getUser(userId)).then(result => {
                if (result) {
                    Object.keys(userObj)?.forEach(field => {
                        userObj[field] = result[field]
                    })
                    setUserObj(userObj)
                }
            })
        }
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (userId) {
            put(updateUser(userId), userObj).then(result => {
                if (result) {
                    props.triggerToastMessage({ isOpen: true, text: 'User updated', level: 'success' });
                    navigate(-1);
                }
            })
        }
    };

    const handleUserInput = (event) => {
        setUserObj(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <ValidatorForm onSubmit={handleSubmit} onError={errors => setFormErrors(errors)} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    label="Firstname"
                                    name="first_name"
                                    value={userObj.first_name}
                                    validators={validator.requiredAlphabets.validators}
                                    errorMessages={validator.requiredAlphabets.errorMessages}
                                    onChange={handleUserInput}
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    variant={TEXT_BOX_VARIANT}
                                    color={TEXT_BOX_COLOR}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    label="Lastname"
                                    name="last_name"
                                    value={userObj.last_name}
                                    validators={validator.requiredAlphabets.validators}
                                    errorMessages={validator.requiredAlphabets.errorMessages}
                                    onChange={handleUserInput}
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    variant={TEXT_BOX_VARIANT}
                                    color={TEXT_BOX_COLOR}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    label="Email"
                                    name="email"
                                    value={userObj.email}
                                    validators={validator.emailRequired.validators}
                                    errorMessages={validator.emailRequired.errorMessages}
                                    onChange={handleUserInput}
                                    autoFocus
                                    margin="normal"
                                    disabled={true}
                                    fullWidth
                                    variant={TEXT_BOX_VARIANT}
                                    color={TEXT_BOX_COLOR}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant={FORM_BUTTON_VARIANT}
                            color={TEXT_BOX_COLOR}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Update
                        </Button>
                    </ValidatorForm>
                </Box>
            </Container>
        </ThemeProvider>
    );
}