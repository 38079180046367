import "./SignInMain.css";
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import google_login from '../../images/google_login.png'
import post from '../../Api/Config/PostConfig';
import { getUser, loginUser, createUser } from '../../Api/UserApi';
import { useNavigate } from "react-router-dom";
import { setLoggedUser, setAuthToken} from '../../Storage/Setter';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { User } from '../../Models/User';
import { TEXT_BOX_COLOR, TEXT_BOX_VARIANT, FORM_BUTTON_VARIANT } from '../../Helper/FormConstants';
import * as validator from '../../Helper/Validator';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { textAlign } from '@mui/system';
import { Card, CardContent, CardHeader, CardMedia, InputLabel } from '@mui/material';
import SingleTextInput from "../../Components/SingleTextInput";
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { UserConfig } from "../../Models/UserConfig";


var user = new User();

const SigninMain = (props) => {
    const [userObj, setUserObj] = React.useState(user);
    const [formErrors, setFormErrors] = React.useState([]);
    const [showOtp, setShowOtp] = React.useState(false);
    const [incCounter, setIncCounter] = React.useState(0);
    const navigate = useNavigate();

    const googleLogin = useGoogleLogin({
        onSuccess: (user) => {
            getUserProfile(user);
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    const getUserProfile = (user) => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    let data = res.data;
                    let uObj = new User();
                    uObj.email = data["email"]
                    uObj.first_name = data["given_name"]
                    uObj.last_name = data["family_name"]
                    uObj.google_profile_img = data["picture"]
                    uObj.config = new UserConfig()
                    uObj.google_login = true
                    uObj.google_access_token = user.access_token
                    userLogin(uObj);
                })
                .catch((err) => props.triggerToastMessage({ isOpen: true, text: result.message, level: 'error' }));
        }
    }

    const userLogin = (user) => {
        post(loginUser(), user).then(function (result) {
            if (result.statusCode == 200) {
                // Authentication successful and user also activated
                setLoggedUser(JSON.stringify(result.data["user"]));
                setAuthToken(result.data["user"].current_auth_token);
                props.setPointsAvailable(result.data["user"]["points_balance"]);
                props.showNavBar(true);
                props.triggerToastMessage({ isOpen: true, text: 'Login Success', level: 'success' });
                navigate('/studyList');
                /*if (result.data["is_subscribed"]) {
                    navigate('/studyList');
                }
                else {
                    navigate('/plans');
                }*/
            }
            else if (result.statusCode == 400) {
                // Invalid credentials
                props.triggerToastMessage({ isOpen: true, text: result.message, level: 'error' });
            }
        })

    }

    return (
        <>
            {/* <div className="topheader">
                <div className="signintxt">
                    <h1 className="sign-in">Sign in</h1>
                </div>
            </div> */}
            <div>
                <Button
                    variant="contained"
                    onClick={googleLogin}
                    startIcon={<img src={google_login} width={25} />}
                >Sign in with Google</Button>
            </div>
            <Grid container>
                <Grid item className="project_desc">
                    <p><b>"Unleash the Power of Data with integrated artificial intelligence"</b></p>
                    <p>Welcome to Shodh-AI, the cutting-edge statistics software designed for students, researchers, and professionals alike.
                        With its intuitive interface and powerful features, Shodh-AI transforms complex data analysis into a seamless and insightful experience.
                        The power of AI helps you automatically select the appropriate tests, simplifying the statistical analysis and making research an enjoyable journey.</p>
                </Grid>
            </Grid >
        </>
    );
};

export default SigninMain;