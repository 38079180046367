import Username from "./Username";
import ButtonText from "./ButtonText";
import "./SIgnUpBox.css";
import { Link } from "react-router-dom";
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { User } from '../../Models/User';
import { TEXT_BOX_COLOR, TEXT_BOX_VARIANT, FORM_BUTTON_VARIANT } from '../../Helper/FormConstants';
import * as validator from '../../Helper/Validator';
import { useNavigate } from "react-router-dom";
import { createUser } from '../../Api/UserApi';
import post from '../../Api/Config/PostConfig';
import SingleTextInput from "../../Components/SingleTextInput";
var user = new User();

const SIgnUpBox = (props) => {
    const [userObj, setUserObj] = React.useState(user);
    const [formErrors, setFormErrors] = React.useState([]);
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        let signupData = { ...userObj };
        signupData.password = signupData.password;//md5(signupData.password)
        post(createUser(), signupData).then(function (result) {
            if (result.statusCode == 200) {
                props.triggerToastMessage({ isOpen: true, text: 'User registered & Activation mail sent to your email Id', level: 'success' });
                navigate("/");
            }
            else if (result.statusCode == 401) {
                props.triggerToastMessage({ isOpen: true, text: result.message, level: 'error' });
            }
        })
    };

    const handleUserInput = (event) => {
        setUserObj(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    return (
        <ValidatorForm className="signupbox" onSubmit={handleSubmit} onError={errors => setFormErrors(errors)} sx={{ mt: 1 }}>
            <div className="signuptxt">
                <div className="signintxt">
                    <h1 className="sign-up">Sign up</h1>
                </div>
            </div>
            <div className="inputfields">
                <SingleTextInput
                    title="Enter your first name"
                    placeholder='First name'
                    name="first_name"
                    value={userObj.first_name}
                    validators={validator.requiredAlphabets.validators}
                    errorMessages={validator.requiredAlphabets.errorMessages}
                    onChange={handleUserInput}
                />
                <SingleTextInput
                    title='Enter your last name'
                    placeholder="Last name"
                    name="last_name"
                    value={userObj.last_name}
                    validators={validator.requiredAlphabets.validators}
                    errorMessages={validator.requiredAlphabets.errorMessages}
                    onChange={handleUserInput}
                />
                <SingleTextInput
                    title={"Enter your username"}
                    placeholder="Username"
                    name="username"
                    value={userObj.username}
                    validators={validator.requiredAlphabets.validators}
                    errorMessages={validator.requiredAlphabets.errorMessages}
                    onChange={handleUserInput}
                />
                <SingleTextInput
                    title={"Enter your email address"}
                    placeholder="Email address"
                    name="email"
                    value={userObj.email}
                    validators={validator.emailRequired.validators}
                    errorMessages={validator.emailRequired.errorMessages}
                    onChange={handleUserInput}
                />
                <SingleTextInput
                    title={"Enter your password"}
                    placeholder="Password"
                    name="password"
                    value={userObj.password}
                    validators={validator.requiredPassword.validators}
                    errorMessages={validator.requiredPassword.errorMessages}
                    onChange={handleUserInput}
                />
            </div>
            <Button
                className="signinbutton"
                disableElevation={true}
                variant="contained"
                type='submit'
                sx={{
                    textTransform: "none",
                    color: "#fff",
                    fontSize: "14",
                    background: "#1971c2",
                    borderRadius: "8px",
                    "&:hover": { background: "#1971c2" },
                    height: 40,
                }}
            >
                Sign up
            </Button>
            <div className="noaccount">
                <div className="already-have-an-container">
                    <span className="already-have-an">{`Already have an Account ? `}</span>
                    <span className="sign-in"><Link variant="body2" to="/">
                        {"Sign in"}
                    </Link></span>
                </div>
            </div>

        </ValidatorForm>
    );
};

export default SIgnUpBox;