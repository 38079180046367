import * as React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel, Paper } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Checkbox, RadioGroup, Radio } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Study } from "../../Models/Study";
import { useNavigate, useParams } from "react-router-dom";
import get from "../../Api/Config/GetConfig";
import { getStudy } from "../../Api/StudyApi";
import * as appConstants from "../../AppConstants";
import NextPrevButton from "../../Components/NextPrevButton";
import { styled } from '@mui/material/styles';
import './StatisticalTests.css';
import InfoIcon from '@mui/icons-material/Info';
import { Modal } from 'react-bootstrap'
import shodh_manual from '../../images/shodh_manual.png'
import shodh_automatic from '../../images/shodh_ai_logo.png'
import { getStudyVariables } from "../../Api/StudyVariableApi";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.8),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

export default function StatisticalTests(props) {
    const formRef = React.useRef(null);
    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState("");
    const { studyId } = useParams();
    const [studyObj, setStudyObj] = React.useState(new Study());
    const [openInfoModal, setOpenInfoModal] = React.useState({ open: false, type: null })
    const currentPageCode = appConstants.stat_test_pagecode;
    const [error, setError] = React.useState({ open: false, message: null, title: null })
    const [studyVariableList, setStudyVariableList] = React.useState([]);
    const selectionRef = React.useRef(null);
    const setSelected = (type) => {
        setStudyObj((prevState) => ({
            ...prevState,
            statistical_test_type: type,
        }));
        if (type == 1) {
            toggleSelection(false);
        }
        else if (type == 2) {
            toggleSelection(true);
        }
    };

    const toggleSelection = (val) => {
        setStudyObj((prevState) => ({
            ...prevState,
            statistical_test_normality_kolmogorov: val,
            statistical_test_missing_variables: val,
            statistical_test_demographic_frequency: val,
            statistical_test_demographic_mean: val,
            statistical_test_demographic_median: val,
            statistical_test_between_group: "",
            statistical_test_between_single_group: "",
            statistical_test_other_tests: "",
            test_independent: false,
            test_mannwhitney: false,
            test_anova_oneway: false,
            test_kruskal: false,
            test_chi: false,
            test_pearson: false,
            test_spearman: false,
            test_pairedttest: false,
            test_wilcoxon: false,
            test_anova_repeated: false,
            test_friedman: false,
            test_saphiro: false
        }));
    };

    const handleRadioChange = (event) => {
        switch (event.target.name) {
            case "statistical_test_between_group":
                setBetweenGroup(event.target.name, event.target.value);
                break;
            case "statistical_test_group":
                setBetweenSingleGroup(event.target.name, event.target.value);
                break;
            case "statistical_test_between_single_group":
                setBetweenSingleGroup(event.target.name, event.target.value);
                break;
            case "statistical_test_other_tests":
                setStudyObj((prevState) => ({
                    ...prevState,
                    [event.target.name]: event.target.value,
                }));
                break;
        }

        setStudyObj((prevState) => ({
            ...prevState,
            statistical_test_type: 2,
        }));
    };

    const setBetweenGroup = (field, value) => {
        if (value == 'outcome_continous') {
            setStudyObj((prevState) => ({
                ...prevState,
                test_independent: false,
                test_mannwhitney: false,
                test_anova_oneway: false,
                test_kruskal: false,
                test_chi: false,
                statistical_test_between_single_group: "",
                test_pairedttest: false,
                test_wilcoxon: false,
                test_anova_repeated: false,
                test_friedman: false,
                [field]: value,
            }));
        }
        if (value == 'two') {
            setStudyObj((prevState) => ({
                ...prevState,
                test_independent: true,
                test_mannwhitney: true,
                test_anova_oneway: false,
                test_kruskal: false,
                test_chi: false,
                statistical_test_between_single_group: "",
                test_pairedttest: false,
                test_wilcoxon: false,
                test_anova_repeated: false,
                test_friedman: false,
                [field]: value,
            }));
        }
        else if (value == 'higher_two') {
            setStudyObj((prevState) => ({
                ...prevState,
                test_independent: false,
                test_mannwhitney: false,
                test_anova_oneway: true,
                test_kruskal: true,
                test_chi: false,
                statistical_test_between_single_group: "",
                test_pairedttest: false,
                test_wilcoxon: false,
                test_anova_repeated: false,
                test_friedman: false,
                [field]: value,
            }));
        }
        else if (value == 'outcome_categorical') {
            setStudyObj((prevState) => ({
                ...prevState,
                test_independent: false,
                test_mannwhitney: false,
                test_anova_oneway: false,
                test_kruskal: false,
                test_chi: true,
                statistical_test_between_single_group: "",
                test_pairedttest: false,
                test_wilcoxon: false,
                test_anova_repeated: false,
                test_friedman: false,
                [field]: value,
            }));
        }
    };

    const setBetweenSingleGroup = (field, value) => {
        if (value == 'cross_sectional') {
            setStudyObj((prevState) => ({
                ...prevState,
                //test_pearson: true,
                //test_spearman: true,
                test_pairedttest: false,
                test_wilcoxon: false,
                test_anova_repeated: false,
                test_friedman: false,
                statistical_test_between_group: "",
                test_independent: false,
                test_mannwhitney: false,
                test_anova_oneway: false,
                test_kruskal: false,
                test_chi: false,
                [field]: value,
            }));
        }
        else if (value === 'longitudinal') {
            setStudyObj((prevState) => ({
                ...prevState,
                statistical_test_between_single_group: null,
                test_pairedttest: false,
                test_wilcoxon: false,
                [field]: value,
            }))
        }
        else if (value == 'two') {
            setStudyObj((prevState) => ({
                ...prevState,
                //test_pearson: false,
                //test_spearman: false,
                test_pairedttest: true,
                test_wilcoxon: true,
                test_anova_repeated: false,
                test_friedman: false,
                statistical_test_between_group: "",
                test_independent: false,
                test_mannwhitney: false,
                test_anova_oneway: false,
                test_kruskal: false,
                test_chi: false,
                [field]: value,
            }));
        }
        else if (value == 'higher_two') {
            setStudyObj((prevState) => ({
                ...prevState,
                //test_pearson: false,
                //test_spearman: false,
                test_pairedttest: false,
                test_wilcoxon: false,
                test_anova_repeated: true,
                test_friedman: true,
                statistical_test_between_group: "",
                test_independent: false,
                test_mannwhitney: false,
                test_anova_oneway: false,
                test_kruskal: false,
                test_chi: false,
                [field]: value,
            }));
        }
    };

    const handleChange = (event) => {
        if (event.target.name === 'statistical_test_type') {
            setSelected(parseInt(event.target.value));
        }
        else {
            if (event.target.name === 'test_independent' || event.target.name === 'test_mannwhitney') {
                setStudyObj((prevState) => ({
                    ...prevState,
                    ['test_anova_oneway']: false,
                }));
            }
            else if (event.target.name === 'test_anova_oneway') {
                setStudyObj((prevState) => ({
                    ...prevState,
                    ['test_independent']: false,
                    ['test_mannwhitney']: false,
                }));
            }
            setStudyObj((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.checked,
            }));
            setStudyObj((prevState) => ({
                ...prevState,
                statistical_test_type: 2,
            }));
        }
    };

    React.useEffect(() => {
        get(getStudy(studyId)).then(function (res) {
            studyObj.statistical_test_type = res.statistical_test_type;
            setStudyObj(res);
        });
        get(getStudyVariables(studyId)).then(res => {
            setStudyVariableList(res)
        })
    }, []);

    const onOpenInfoModal = (type) => {
        setOpenInfoModal({ open: true, type: type })
    }
    const onCloseInfoModal = () => {
        setOpenInfoModal({})
    }
    const errorHandleClose = () => {
        setError({})
    }
    const validateVariables = () => {
        let invalidVariables = [];
        studyVariableList.forEach((element, index) => {
            if (!element.type) {
                invalidVariables.push(element.name);
            }
        });
        return invalidVariables;
    }
    const intermediateFunc = () => {
        let vars = validateVariables();
        if (studyObj?.input_method === appConstants.UPLOAD_EXCEL_FILE.value && studyObj?.statistical_test_type === 1) {
            if (vars?.length > 0) {
                setError({
                    message: (
                        <div>
                            Data type should be defined for each of the variables used in your dataset, if you are asking Shodh-ai to automatically decide the tests. Please go back to data input sheet, define variable types and other attributes for each of the variables. You can define these attributes by double clicking the column name in the datasheet
                            {/* <br />
                            {vars.join(', ')} */}
                        </div>
                    ),
                    open: true,
                    title: 'Unassigned Variables'
                }); return false
            }
        }
        return true
    }
    const infoModal = () => {
        return (
            <Modal
                show={openInfoModal?.open}
                onHide={onCloseInfoModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Header closeButton>
                    Variable/Paramter Definations
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '70vh' }}>
                    {appConstants.variable_desc?.[openInfoModal?.type]?.map(content => (
                        content
                    ))}
                </Modal.Body>
            </Modal>
        )
    }
    const dialogAlert = () => {
        return (
            <Dialog open={error.open} fullWidth={true} maxWidth="lg">
                <DialogTitle style={{ fontSize: '1rem' }}>
                    <Box sx={{ color: 'error.main' }}>{error.title}</Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='outlined'
                        color='secondary'
                        startIcon={<KeyboardReturnIcon />}
                        onClick={() => navigate('/spreadSheet/' + studyId)}>input data</Button>
                    <Button variant='outlined' color='info' onClick={errorHandleClose}>Ok</Button>

                </DialogActions>
            </Dialog>
        )
    }
    const setSelectedOption = (value) => {
        if (selectionRef)
            selectionRef.current.querySelector(`input[value="${value}"]`).click();
    }
    return (
        <>{infoModal()}{dialogAlert()}
            <div className="title-frame">
                {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <NextPrevButton
                                currentPageCode={currentPageCode}
                                data={studyObj}
                                pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]} showNext={false} />

                        </Grid>
                        <Grid item style={{ "display": "inline-flex" }}>
                            <NextPrevButton
                                currentPageCode={currentPageCode}
                                data={studyObj}
                                intermediateFunc={intermediateFunc}
                                pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                                showNext={true}
                                resetFunction={setSelected}
                            />
                        </Grid>
                    </Grid>
                }
                <div className="content-frame">
                    <div className="what-is-the">{appConstants.stats_question}</div>
                    <div className="a-brief-description">
                        Please indicate whether you would like Shodh-AI to automatically select the appropriate tests for you or if you prefer to choose them manually.
                    </div>
                </div><br />
                {/* <div> */}
                {/* <div className="username"> */}
                <FormControl sx={{ width: 1 }}>
                    <RadioGroup
                        name="statistical_test_type"
                        value={(studyObj?.statistical_test_type) ? (studyObj.statistical_test_type) : ''}
                        color="success"
                        onChange={handleChange}
                    >
                        <div className="optional_box_container" ref={selectionRef}>
                            <div onClick={() => setSelectedOption(1)} className={studyObj?.statistical_test_type === 1 ? "optional_box optional_box_selected" : "optional_box"}>
                                <img src={shodh_automatic} className="optional_box_img" />
                                <FormControlLabel
                                    key={1}
                                    name="statistical_test_type"
                                    value={1}
                                    control={<Radio />}
                                    label={'Let Shodh-AI choose the test automatically'}
                                />

                            </div>
                            <div onClick={() => setSelectedOption(2)} className={studyObj?.statistical_test_type === 2 ? "optional_box optional_box_selected" : "optional_box"}>
                                <img src={shodh_manual} className="optional_box_img" />
                                <FormControlLabel
                                    key={2}
                                    name="statistical_test_type"
                                    value={2}
                                    control={<Radio />}
                                    label={'Choose tests manually'}
                                />
                            </div>
                        </div>

                    </RadioGroup>


                    {/* <RadioGroup
                                name="statistical_test_type"
                                value={(studyObj?.statistical_test_type) ? (studyObj.statistical_test_type) : ''}
                                color="success"
                                onChange={handleChange}
                            >
                                {appConstants.test_selections?.map((option, index) => (
                                    <FormControlLabel
                                        key={option.id}
                                        value={option.id}
                                        control={<Radio />}
                                        label={option.label}
                                    />
                                ))}
                            </RadioGroup> */}
                </FormControl>
                {/* </div> */}
                {/* </div> */}
                {(studyObj?.statistical_test_type != 1) &&
                    <div className="mainContent">

                        <div className="content_section">
                            <div className="card-row">
                                <div className="white-card-layout-with-shadow full-width">
                                    <div style={{ display: 'flex' }}>
                                        <h3 className="sub-head-level-1">Descriptive</h3>&ensp;&ensp;
                                        <InfoIcon className='desc_info_icon' onClick={() => onOpenInfoModal('descriptive')} />
                                    </div>
                                    <div className="checkboxes">
                                        {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{ ml: 2 }}
                                                    color="primary"
                                                    checked={
                                                        studyObj.statistical_test_missing_variables
                                                    }
                                                    onChange={handleChange}
                                                    name="statistical_test_missing_variables"
                                                />
                                            }
                                            label="Missing Variables"
                                        /> */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{ ml: 2 }}
                                                    color="primary"
                                                    checked={
                                                        studyObj.statistical_test_demographic_frequency
                                                    }
                                                    onChange={handleChange}
                                                    name="statistical_test_demographic_frequency"
                                                />
                                            }
                                            label="Frequency (number, percentage)"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{ ml: 2 }}
                                                    color="primary"
                                                    checked={
                                                        studyObj.statistical_test_demographic_mean
                                                    }
                                                    onChange={handleChange}
                                                    name="statistical_test_demographic_mean"
                                                />
                                            }
                                            label="Mean, SD"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{ ml: 2 }}
                                                    color="primary"
                                                    checked={
                                                        studyObj.statistical_test_demographic_median
                                                    }
                                                    onChange={handleChange}
                                                    name="statistical_test_demographic_median"
                                                />
                                            }
                                            label="Median, Range"
                                        />
                                    </div>
                                </div>
                                <div className="white-card-layout-with-shadow full-width">
                                    <div style={{ display: 'flex' }}>
                                        <h3 className="sub-head-level-1">Test of Normality</h3>&ensp;&ensp;
                                        <InfoIcon className='desc_info_icon' onClick={() => onOpenInfoModal('normative_distribution')} />
                                    </div>
                                    {/* <h3 className="sub-head-level-1">Normative Distribution</h3> */}
                                    <div className="checkboxes">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{ ml: 2 }}
                                                    color="primary"
                                                    checked={
                                                        studyObj.test_saphiro
                                                    }
                                                    onChange={handleChange}
                                                    name="test_saphiro"
                                                />
                                            }
                                            label="Shapiro–Wilk Test"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-row">

                                <div className="white-card-layout-with-shadow full-width">
                                    <div style={{ display: 'flex' }}>
                                        <h3 className="sub-head-level-1">Comparison Tests</h3>&ensp;&ensp;
                                        <InfoIcon className='desc_info_icon' onClick={() => onOpenInfoModal('crosee_sectional_logitudinal')} />
                                    </div>

                                    <FormControl>
                                        <RadioGroup
                                            sx={{ ml: 2 }}
                                            name="statistical_test_group"
                                            value={(studyObj?.statistical_test_group) ? (studyObj.statistical_test_group) : ''}
                                            color="success"
                                            onChange={handleRadioChange}
                                        >
                                            <div className="cross_sectional_longitudinal_section">
                                                <Grid container alignItems={"center"}>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value={'cross_sectional'}
                                                            control={<Radio />}
                                                            label={<h3 className="sub-head-level-1">Cross sectional</h3>}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value={'longitudinal'}
                                                            control={<Radio />}
                                                            label={<h3 className="sub-head-level-1">Longitudinal</h3>}
                                                        />
                                                    </Grid>

                                                </Grid>

                                            </div>

                                        </RadioGroup>
                                    </FormControl>
                                    {/* <h3 className="sub-head-level-1">Cross sectional</h3> */}
                                    {studyObj?.statistical_test_group === 'cross_sectional' &&
                                        <FormControl sx={{ ml: 2 }}>
                                            <RadioGroup
                                                sx={{ ml: 2 }}
                                                name="statistical_test_between_group"
                                                value={(studyObj?.statistical_test_between_group) ? (studyObj.statistical_test_between_group) : ''}
                                                color="success"
                                                onChange={handleRadioChange}
                                            >
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            value={'outcome_continous'}
                                                            control={<Radio />}
                                                            label={'Outcome variable - continuous'}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            value={'outcome_categorical'}
                                                            control={<Radio />}
                                                            label={'Outcome variable - categorical'}
                                                            onChange={handleRadioChange}
                                                        />

                                                    </Grid>

                                                </Grid>
                                                {studyObj?.statistical_test_between_group === 'outcome_continous' &&
                                                    <Grid container>
                                                        <Grid item xs={12} lg={12}>
                                                            <div className="card-row">
                                                                <div className="white-card-layout-with-shadow full-width">
                                                                    <h3 className="sub-head-level-1">Two Groups</h3>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <FormControlLabel
                                                                                sx={{ ml: 2 }}
                                                                                control={
                                                                                    <Checkbox
                                                                                        color="primary"
                                                                                        checked={studyObj.test_independent}
                                                                                        onChange={handleChange}
                                                                                        name="test_independent"

                                                                                    />
                                                                                }
                                                                                label="Independent Samples T-Test"
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <FormControlLabel
                                                                                sx={{ ml: 2, alignItems: 'center' }}
                                                                                control={
                                                                                    <Checkbox
                                                                                        color="primary"
                                                                                        checked={studyObj.test_mannwhitney}
                                                                                        onChange={handleChange}
                                                                                        name="test_mannwhitney"
                                                                                    />
                                                                                }
                                                                                label={<div>Mann–Whitney <i>U</i>Test</div>}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} lg={12}>
                                                            <div className="card-row">
                                                                <div className="white-card-layout-with-shadow full-width">
                                                                    <h3 className="sub-head-level-1">{'>'} 2 Groups</h3>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        color="primary"
                                                                                        sx={{ ml: 2 }}
                                                                                        checked={studyObj.test_anova_oneway}
                                                                                        onChange={handleChange}
                                                                                        name="test_anova_oneway"
                                                                                    />
                                                                                }
                                                                                label="One-way Analysis of Variance"
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    {/*<Grid container>
                                                            <Grid item>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            color="primary"
                                                                            sx={{ ml: 3 }}
                                                                            checked={studyObj.test_kruskal}
                                                                            onChange={handleChange}
                                                                            name="test_kruskal"
                                                                        />
                                                                    }
                                                                    label="Kruskal-Wallis Test"
                                                                />
                                                            </Grid>
                                                        </Grid>*/}
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {studyObj?.statistical_test_between_group === 'outcome_categorical' &&
                                                    <Grid container ml={36}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        // sx={{ ml: 3 }}
                                                                        checked={studyObj.test_chi}
                                                                        onChange={handleChange}
                                                                        name="test_chi"
                                                                    />
                                                                }
                                                                label="Chi-Square Test"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    }
                                    {studyObj?.statistical_test_group === 'longitudinal' &&
                                        <FormControl sx={{ ml: 2 }}>
                                            <RadioGroup
                                                sx={{ ml: 2 }}
                                                name="statistical_test_between_single_group"
                                                value={(studyObj?.statistical_test_between_single_group) ? (studyObj.statistical_test_between_single_group) : ''}
                                                color="success"
                                                onChange={handleRadioChange}
                                            >
                                                <Grid container columnSpacing={8} rowSpacing={2} ml={15}>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value={'two'}
                                                            control={<Radio />}
                                                            label={'Two time points'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        {studyObj?.statistical_test_between_single_group === 'two' &&
                                                            <>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    sx={{ ml: 3 }}
                                                                                    color="primary"
                                                                                    checked={studyObj.test_pairedttest}
                                                                                    onChange={handleChange}
                                                                                    name="test_pairedttest"
                                                                                />
                                                                            }
                                                                            label="Paired t test"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    sx={{ ml: 3 }}
                                                                                    color="primary"
                                                                                    checked={studyObj.test_wilcoxon}
                                                                                    onChange={handleChange}
                                                                                    name="test_wilcoxon"
                                                                                />
                                                                            }
                                                                            label="Wilcoxon sign rank"
                                                                        />
                                                                    </Grid>
                                                                </Grid>

                                                            </>
                                                        }
                                                    </Grid>
                                                    {/*<Grid item>
                                        <FormControlLabel
                                            value={'higher_two'}
                                            control={<Radio />}
                                            label={'>2 time points'}
                                            onChange={handleRadioChange}
                                        />
                                        <Grid container>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            sx={{ ml: 3 }}
                                                            color="primary"
                                                            checked={studyObj.test_anova_repeated}
                                                            onChange={handleChange}
                                                            name="test_anova_repeated"
                                                        />
                                                    }
                                                    label="Repeated Measure ANOVA"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            sx={{ ml: 3 }}
                                                            color="primary"
                                                            checked={studyObj.test_friedman}
                                                            onChange={handleChange}
                                                            name="test_friedman"
                                                        />
                                                    }
                                                    label="Friedman Test"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>*/}
                                                </Grid>
                                            </RadioGroup>
                                        </FormControl>
                                    }
                                </div>
                                {/* <div className="white-card-layout-with-shadow">
                                <h3 className="sub-head-level-1">Longitudinal </h3>
                                
                            </div> */}
                            </div>
                            <div className="card-row">
                                <div className="white-card-layout-with-shadow full-width">
                                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                                        <h3 className="sub-head-level-1">Correlation Tests</h3>&ensp;&ensp;
                                        <InfoIcon className='desc_info_icon' onClick={() => onOpenInfoModal('correlational')} />
                                    </div>
                                    {/* <h3 className="sub-head-level-1">Correlational</h3> */}

                                    <FormControl>
                                        <Grid container columnSpacing={8} rowSpacing={2}>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            sx={{ ml: 3 }}
                                                            color="primary"
                                                            checked={studyObj.test_pearson}
                                                            onChange={handleChange}
                                                            name="test_pearson"
                                                        />
                                                    }
                                                    label="Pearson"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            sx={{ ml: 3 }}
                                                            color="primary"
                                                            checked={studyObj.test_spearman}
                                                            onChange={handleChange}
                                                            name="test_spearman"
                                                        />
                                                    }
                                                    label="Spearman"
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </div>
                            </div>

                            {/*<div className="card-row">
                    <div className="white-card-layout-with-shadow full-width">
                        <h3 className="sub-head-level-1">Other Tests</h3>
                        <FormControl>
                            <RadioGroup
                                sx={{ ml: 2 }}
                                name="statistical_test_other_tests"
                                value={(studyObj?.statistical_test_other_tests) ? (studyObj.statistical_test_other_tests) : ''}
                                color="success"
                                onChange={handleRadioChange}
                            >
                                <Grid container columnSpacing={8}>
                                    <Grid item >
                                        <FormControlLabel
                                            value={'linear_regression'}
                                            control={<Radio />}
                                            label={'Linear Regression'}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <FormControlLabel
                                            value={'logistic_regression'}
                                            control={<Radio />}
                                            label={'Logistic Regression'}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <FormControlLabel
                                            value={'other_anova'}
                                            control={<Radio />}
                                            label={'Analysis of co-variance'}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <FormControlLabel
                                            value={'other_two_way_anova'}
                                            control={<Radio />}
                                            label={'Two-way ANOVA'}
                                            onChange={handleRadioChange}
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>*/}
                        </div>
                    </div>
                }
            </div>
            <br /><br />
        </>
    )
}