export const loginUser = () =>{
    return process.env.REACT_APP_URL+'login';
}

export const logoutUser = () =>{
    return process.env.REACT_APP_URL+'logout';
}

export const getUser = (id) =>{
    return process.env.REACT_APP_URL+'users/'+id;
}

export const getUserErrorLog = (id) =>{
    return process.env.REACT_APP_URL+'errorLog/'+id;
}

export const createSucceededTransaction = (user_id) =>{
    return process.env.REACT_APP_URL+'createSucceededTransaction/'+user_id;
}

export const createFailedTransaction = (user_id) =>{
    return process.env.REACT_APP_URL+'createFailedTransaction/'+user_id;
}

export const createUser = () =>{
    return process.env.REACT_APP_URL+'createUser';
}

export const forgotPasswordOtpGenerate = () =>{
    return process.env.REACT_APP_URL+'generateForgotPasswordOtp';
}

export const forgotPasswordOtpVerify = () =>{
    return process.env.REACT_APP_URL+'verifyForgotPasswordOtp';
}

export const changePassword = () =>{
    return process.env.REACT_APP_URL+'changePassword';
}

export const updatePassword = (user_id) =>{
    return process.env.REACT_APP_URL+'updateUserPassword/'+user_id;
}

export const updateUser = (user_id) =>{
    return process.env.REACT_APP_URL+'updateUser/'+user_id;
}

export const getUserPointsTransactions = (user_id) => {
    return process.env.REACT_APP_URL+'userPointsTransactions/'+user_id;
}