import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import get from "../../Api/Config/GetConfig";
import { getStudy } from "../../Api/StudyApi";
import * as appConstants from "../../AppConstants";
import NextPrevButton from '../../Components/NextPrevButton';
import { Study } from "../../Models/Study";
import "../title-of-study/TitleOfStudy.css";
import '../study-type/StudyTypeScreen.css';
import { Grid, Paper } from "@mui/material";

const StudyTimesExaminedPage = () => {
    const { studyId } = useParams();
    const [studyObj, setStudyObj] = React.useState(new Study());
    const [selectOptions, setSelectOptions] = React.useState([]);
    const currentPageCode = appConstants.examining_pagecode;

    React.useEffect(() => {
        if (studyId) {
            get(getStudy(studyId)).then(function (res) {
                setStudyObj(res);
                optionSetting(res);
            });
        }
    }, []);

    const handleChange = (event) => {
        setStudyObj((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const optionSetting = (study) => {
        setSelectOptions(appConstants.examining_study_options);
    }
    return (
        <div className="title-frame">
            {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            showNext={false}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                        />
                    </Grid>
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            showNext={true}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                        />
                    </Grid>
                </Grid>
            }
            <div className="content-frame">
                <div className="what-is-the">{appConstants.examining_study_question}</div>
                <div className="a-brief-description">
                    A brief description of this section can go here
                </div>
            </div>

            <div className="study_type_testlist-cards">
                <div className="study_type_radio-parent">
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="no_of_times_examining"
                        onChange={handleChange}
                        value={studyObj.no_of_times_examining}
                    >
                        {selectOptions.map((option, index) => (
                            <div className="study_type_radio">
                                <FormControlLabel title={option?.content} value={option?.value} control={<Radio />} label={option?.label} />
                            </div>
                        ))}
                    </RadioGroup>
                </div>
            </div>
        </div >
    );
};

export default StudyTimesExaminedPage;
