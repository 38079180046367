import * as React from 'react';
import Button from '@mui/material/Button';
import { createTheme } from '@mui/material/styles';

import post from '../../Api/Config/PostConfig';
import { forgotPasswordOtpGenerate, forgotPasswordOtpVerify, changePassword } from '../../Api/UserApi';
import { useNavigate } from "react-router-dom";
import { ValidatorForm } from 'react-material-ui-form-validator';
import { User } from '../../Models/ForgotPassword';
import * as validator from '../../Helper/Validator';
import './ForgotPasswordPage.css'
import forgotPasswordSvg from '../../images/forgot_password.svg'
import summitlogo from '../../images/summitlogo@2x.png'
import SingleTextInput from '../../Components/SingleTextInput';
var user = new User();

export default function ForgotPasswordPage(props) {

    const [userObj, setUserObj] = React.useState(user);
    const [formErrors, setFormErrors] = React.useState([]);
    const [incCounter, setIncCounter] = React.useState(0);
    const [otpGenerated, setOtpGenerated] = React.useState(false);
    const [otpVerified, setOtpVerified] = React.useState(false);
    const navigate = useNavigate();

    const generateOtp = () => {
        post(forgotPasswordOtpGenerate(), userObj).then(function (result) {
            if (result.statusCode == 200) {
                setOtpGenerated(true);
                props.triggerToastMessage({ isOpen: true, text: result.message, level: 'success' });
            }
            else {
                props.triggerToastMessage({ isOpen: true, text: result.message, level: 'error' });
            }
        })
    }

    const updatePassword = () => {
        if (userObj.password != userObj.re_enter_password) {
            props.triggerToastMessage({ isOpen: true, text: "Password do not match", level: 'error' });
            return false;
        }
        post(changePassword(), userObj).then(function (result) {
            if (result.statusCode == 200) {
                navigate('/');
                props.triggerToastMessage({ isOpen: true, text: result.message, level: 'success' });
            }
            else {
                props.triggerToastMessage({ isOpen: true, text: result.message, level: 'error' });
            }
        })
    }

    const verifyOtp = () => {
        post(forgotPasswordOtpVerify(), userObj).then(function (result) {
            if (result.statusCode == 200) {
                setOtpVerified(true);
                props.triggerToastMessage({ isOpen: true, text: result.message, level: 'success' });
            }
            else if (result.statusCode == 401) {
                // OTP retires with 3 attempts
                let inc = incCounter + 1;
                setIncCounter(inc)
                if (inc > 2) {
                    props.triggerToastMessage({ isOpen: true, text: "OTP attempts expired.Please retry.", level: 'error' });
                    setIncCounter(0);
                    navigate('/');
                }
                else {
                    setUserObj(prevState => ({
                        ...prevState,
                        "forgot_password_otp": ""
                    }))
                    props.triggerToastMessage({ isOpen: true, text: result.message, level: 'error' });
                }
            }
        })

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!otpGenerated) {
            generateOtp();
        }
        else if (otpGenerated && !otpVerified) {
            verifyOtp();
        }
        else if (otpGenerated && otpVerified) {
            updatePassword();
        }
    };

    const handleUserInput = (event) => {
        setUserObj(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    return (
        <div className="forgotpassword">
            <div className="asset-1-1-parent">
                <img className="asset-1-1" alt="" src={forgotPasswordSvg} />
                <div className="forgot-password-parent">
                    <div className="forgot-password1">Forgot Password?</div>
                    <div className="not-to-worry">
                        Not to worry! Please enter your registered email id.
                    </div>
                    <ValidatorForm style={{ alignSelf: 'stretch' }} onSubmit={handleSubmit} onError={errors => setFormErrors(errors)} sx={{ mt: 1 }}>
                        {(!otpGenerated) &&
                            <SingleTextInput
                                label="Email"
                                name="email"
                                value={userObj.email}
                                validators={validator.emailRequired.validators}
                                errorMessages={validator.emailRequired.errorMessages}
                                onChange={handleUserInput}
                            />
                        }
                        {(otpVerified) &&
                            <>
                                <SingleTextInput
                                    label="Password"
                                    name="password"
                                    type="password"
                                    value={userObj.password}
                                    validators={validator.required.validators}
                                    errorMessages={validator.required.errorMessages}
                                    onChange={handleUserInput}
                                />
                                <SingleTextInput
                                    label="Re-enter password"
                                    name="re_enter_password"
                                    type="password"
                                    value={userObj.re_enter_password}
                                    validators={validator.required.validators}
                                    errorMessages={validator.required.errorMessages}
                                    onChange={handleUserInput}
                                />
                            </>
                        }
                        {(otpGenerated && !otpVerified) &&
                            <>
                                <SingleTextInput
                                    label="Enter OTP"
                                    name="forgot_password_otp"
                                    value={userObj.forgot_password_otp}
                                    validators={validator.otp.validators}
                                    errorMessages={validator.otp.errorMessages}
                                    onChange={handleUserInput}
                                />
                                <label>No of OTP attempts remaining : {3 - incCounter}</label>
                            </>
                        }
                        <Button
                            className="mainbuttons1"
                            variant="contained"
                            type='submit'
                            sx={{
                                textTransform: "none",
                                color: "#fff",
                                fontSize: "16",
                                background: "#1971c2",
                                borderRadius: "8px",
                                "&:hover": { background: "#1971c2" },
                                height: 48,
                            }}
                        >
                            {(!otpGenerated) && 'Send OTP'}
                            {(otpGenerated && !otpVerified) && 'Verify OTP'}
                            {(otpGenerated && otpVerified) && 'Change password'}
                        </Button>
                    </ValidatorForm>
                </div>
            </div>
            <div className="forgotpassword-inner">
                <div className="logo-1-frame">
                    <img className="logo-1-icon2" alt="" src={summitlogo} />
                </div>
            </div>
        </div>
    );
}