export const createStudyTests = () => {
    return process.env.REACT_APP_URL + 'studyTests';
}

export const getStudyTest = (id) => {
    return process.env.REACT_APP_URL + 'studyTests/' + id;
}

export const getStudyTestByStudyId = (studyid) => {
    return process.env.REACT_APP_URL + 'studyTests/by-study-id/' + studyid;
}

export const updateStudyTest = (id) => {
    return process.env.REACT_APP_URL + 'studyTests/' + id;
}

export const deleteStudyTest = (id) => {
    return process.env.REACT_APP_URL + 'studyTests/' + id;
}

export const updateTestsAutomated = (study_id) => {
    return process.env.REACT_APP_URL + 'updateTestsAutomated/' + study_id;
}