import React from "react";
import StudyListCardComponent from "./StudyListCardComponent";
import "./StudyListScreen.css";
import { useNavigate } from 'react-router-dom';
import { getLoggedUser } from './../../Storage/Getter';
import put from '../../Api/Config/PutConfig';
import get from '../../Api/Config/GetConfig';
import { getStudies, updateStudy } from '../../Api/StudyApi';
import { Grid } from "@mui/material";
import { IconButton } from '@mui/material';
import shodhai_screen from '../../images/shodhai_screen.svg'

const StudyListScreen = () => {

    const [studyList, setStudyList] = React.useState([]);
    const [enableStudy, setEnableStudy] = React.useState(true);
    const navigate = useNavigate();
    const loggedUser = getLoggedUser();
    React.useEffect(() => {
        getAllStudies();
    }, []);

    const getAllStudies = () => {
        get(getStudies(loggedUser.id)).then(function (result) {
            /*if(result.length < loggedUser.config.max_studies){
                setEnableStudy(true);
            }*/
            if (result.length > 0) {
                setStudyList(result);
            }
        })
    }

    const deleteStudy = (studyObj) => {
        if (confirm('Are you sure want to delete?')) {
            studyObj.is_deleted = true;
            put(updateStudy(studyObj.id), studyObj).then(function (result) {
                if (result) {
                    getAllStudies();
                }
            });
        }
    }

    const gotoCreateStudy = () => {
        navigate('/createStudy')
    }

    const downloadStudy = (studyObj) => {
        navigate('/runTests/' + studyObj.id)
    }

    const gotoEditProject = (data) => {
        navigate('/study/' + data.id);
    }

    return (
        <>
            <div className="my-list-of-test-research-parent" >
                <Grid container justifyContent={"flex-end"} >
                    <Grid item xs={4}>
                        <img src={shodhai_screen} style={{ height: '90vh' }} />
                    </Grid>
                    <Grid item xs={8} className="right-content-space">
                        <Grid container justifyContent={'space-between'} style={{ marginTop: '30px' }}>
                            {(studyList?.length > 0) &&
                                <Grid item >
                                    <div className="my-list-of">My Research List</div>
                                </Grid>
                            }
                            {(enableStudy) &&<Grid item xs={5} sm={3} md={3}>
                                <IconButton
                                    onClick={gotoCreateStudy}
                                    title={"Create Study"}
                                    className='primaryfilledbuttons'
                                >
                                    {(studyList.length < 1) ? "Create your first study" : "Create study"}
                                </IconButton>
                            </Grid>}
                        </Grid><br /><br />
                        {studyList?.length > 0 &&
                            <Grid container gap={2} style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                {studyList?.map((study, index) => (
                                    <Grid item xs={5} key={index}>
                                        {/* <div className="testlist-cards" key={study.title}> */}
                                        <StudyListCardComponent data={study} editFunc={gotoEditProject} deleteStudyFunc={deleteStudy} downloadStudyFunc={downloadStudy} />
                                        {/* </div> */}
                                    </Grid>
                                ))}
                            </Grid>
                        }

                    </Grid>
                </Grid>


            </div>
        </>
    );
};

export default StudyListScreen;
