import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import get from "../../Api/Config/GetConfig";
import { getStudy } from "../../Api/StudyApi";
import * as appConstants from "../../AppConstants";
import NextPrevButton from '../../Components/NextPrevButton';
import { Study } from "../../Models/Study";
import "../title-of-study/TitleOfStudy.css";
import './StudyTypeScreen.css';
import { Grid, Paper } from "@mui/material";
import Box from '@mui/material/Box';
import Blink from 'react-blink-text';
const StudyTypePage = () => {
    const { studyId } = useParams();
    const [studyObj, setStudyObj] = React.useState(new Study());
    const currentPageCode = appConstants.study_type_pagecode;
    React.useEffect(() => {
        if (studyId) {
            get(getStudy(studyId)).then(function (res) {
                setStudyObj(res);
            });
        }
    }, []);
    const handleChange = (event) => {
        setStudyObj((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };
    return (
        <div className="title-frame">
            {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            showNext={false}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                        />
                    </Grid>
                    <Grid item>
                        <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            showNext={true}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                        />
                    </Grid>
                </Grid>
            }
            <div className="content-frame">
                <div className="what-is-the">{appConstants.study_type_question}</div>
                <div className="a-brief-description">
                    <p>Provide description about cross section and longitudinal tests</p>
                </div>
            </div>

            <div className="study_type_testlist-cards">
                <div className="study_type_radio-parent">
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="type"
                        onChange={handleChange}
                        value={studyObj.type}
                    >
                        {appConstants.study_type?.map((option, index) => (
                            <div className="study_type_radio">
                                {option.id == 3 &&
                                    <>
                                        <Blink fontSize={14} color="#ff474c" text={
                                            <Typography style={{ marginLeft: "30px" }} variant="subtitle2" gutterBottom>
                                                {option.label}&nbsp;<Box sx={{ color: 'error.main' }}>Coming soon...</Box>
                                            </Typography>
                                        } />

                                    </>
                                }
                                {option.id != 3 && <FormControlLabel title={option?.content} value={option?.value} control={<Radio />} label={option?.label} />}
                            </div>
                        ))}
                    </RadioGroup>
                </div>
            </div>
        </div >

    );
};

export default StudyTypePage;
