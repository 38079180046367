import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { Link } from 'react-router-dom';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';


export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/studyList">
      <ListItemIcon>
        <ListAltIcon />
      </ListItemIcon>
      <ListItemText primary="Study List" />
    </ListItem>
    {/* <ListItem button component={Link} to="/createStudy">
      <ListItemIcon>
        <MenuBookTwoToneIcon/>
      </ListItemIcon>
      <ListItemText primary="Screens" />
    </ListItem> */}
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Secondary List</ListSubheader>
    <ListItem button component={Link} to="/testSpreadSheet">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Test Spreadsheet" />
    </ListItem>
    <ListItem button component={Link} to="/pricing">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Pricing" />
    </ListItem>
/*<ListItem button component={Link} to="/contact">
  <ListItemIcon>
    <PeopleIcon />
  </ListItemIcon>
  <ListItemText primary="Contact" />
</ListItem>
<ListItem button component={Link} to="/about">
  <ListItemIcon>
    <ShoppingCartIcon />
  </ListItemIcon>
  <ListItemText primary="About Us" />
</ListItem>
</div>
);