import { getLoggedUser } from "../Storage/Getter";
export class StudyTest {
    user_id = parseInt(getLoggedUser().id);
    study_id = 0;
    outcome_variable = [];
    explanatory_variable = [];
    group_variable = [];
    is_active = true;
    status = "Created";
    paired_variables = [];
}