import axios from 'axios';
import { getAuthToken } from '../../Storage/Getter';

export default async function del(url) {
    const token = getAuthToken();
    const defaultOptions = {
        headers: {
            'Authentication-Token': token ? `${token}`:'',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'x-content-type-options': 'nosniff',
            'Referrer-Policy': 'no-referrer'
        },
    };
    const options = {}
    
    return axios.delete(url,{ ...defaultOptions, ...options })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                let response = error.toJSON();
                switch(response.status){
                    case 401: alert("Session expired");
                            break;
                    default: return response;
                }
                console.log("Error: ", response);
            });
}