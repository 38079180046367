import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import put from '../Api/Config/PutConfig';
import { updatePassword } from '../Api/UserApi';
import { useNavigate } from "react-router-dom";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { User } from '../Models/UpdatePassword';
import { TEXT_BOX_COLOR, TEXT_BOX_VARIANT, FORM_BUTTON_VARIANT } from '../Helper/FormConstants';
import * as validator from '../Helper/Validator';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { textAlign } from '@mui/system';
import Logo from './../images/Logo.png';
import { Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import { getLoggedUser } from "../Storage/Getter";

//var md5 = require('md5');
const theme = createTheme();
var user = new User();

export default function ChangePasswordPage(props) {

	const [userObj, setUserObj] = React.useState(user);
	const [formErrors, setFormErrors] = React.useState([]);
	const loggedUser = getLoggedUser();
	const navigate = useNavigate();

	const updateUserPassword = () => {
		if (userObj.password != userObj.re_enter_password) {
			props.triggerToastMessage({ isOpen: true, text: "Password do not match", level: 'error' });
			return false;
		}
		if (userObj.current_password == userObj.password) {
			props.triggerToastMessage({ isOpen: true, text: "New password should not match with current password", level: 'error' });
			return false;
		}
		put(updatePassword(loggedUser.id), userObj).then(function (result) {
			console.log(result);
			if (result.statusCode == 200) {
				props.triggerToastMessage({ isOpen: true, text: result.message, level: 'success' });
				navigate("/studyList")
			}
			else {
				props.triggerToastMessage({ isOpen: true, text: result.message, level: 'error' });
			}
		})
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		updateUserPassword();
	};

	const handleUserInput = (event) => {
		setUserObj(prevState => ({
			...prevState,
			[event.target.name]: event.target.value
		}))
	}

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<Paper style={{
					// padding: '10px',
					textAlign: 'center'
				}} elevation={24}>
					<Card>
						<CardContent>
							<ValidatorForm onSubmit={handleSubmit} onError={errors => setFormErrors(errors)} sx={{ mt: 1 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<>
											<TextValidator
												label="Current password"
												name="current_password"
												type="password"
												value={userObj.current_password}
												validators={validator.required.validators}
												errorMessages={validator.required.errorMessages}
												onChange={handleUserInput}
												margin="normal"
												fullWidth
												color={TEXT_BOX_COLOR}
											/>
											<TextValidator
												label="New password"
												name="password"
												type="password"
												value={userObj.password}
												validators={validator.required.validators}
												errorMessages={validator.required.errorMessages}
												onChange={handleUserInput}
												margin="normal"
												fullWidth
												color={TEXT_BOX_COLOR}
											/>
											<TextValidator
												label="Re-enter new password"
												name="re_enter_password"
												type="password"
												value={userObj.re_enter_password}
												validators={validator.required.validators}
												errorMessages={validator.required.errorMessages}
												onChange={handleUserInput}
												margin="normal"
												fullWidth
												color={TEXT_BOX_COLOR}
											/>
										</>
										<Button
											type="submit"
											fullWidth
											variant={FORM_BUTTON_VARIANT}
											color={TEXT_BOX_COLOR}
											sx={{ mt: 3, mb: 2 }}
										>
											Update password
										</Button>
									</Grid>
								</Grid>
							</ValidatorForm>
						</CardContent>
					</Card>
				</Paper>
			</Container>
		</ThemeProvider>
	);
}