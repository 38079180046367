import * as React from 'react';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import post from "./../Api/Config/PostConfig";
import put from "./../Api/Config/PutConfig";
import { createStudy, updateStudy } from "../Api/StudyApi";
import { useNavigate } from "react-router-dom";
import * as appConstants from "./../AppConstants";

export default function NextPrevButton(props) {


    const navigate = useNavigate();

    React.useEffect(() => {
    }, []);

    // const checkIsFirstPage = () => {
    //     if (getIndexOfCurrentPage() == 0)
    //         return true;
    //     return false;
    // }

    const checkIsLastPage = () => {
        if (getIndexOfCurrentPage() == props.pageList.length - 1) {
            return true;
        }
        return false;
    }

    const getIndexOfCurrentPage = () => {
        return props.pageList.findIndex(page => page.pageCode == props.currentPageCode);
    }

    const prevControlFunc = () => {
        let obj = { ...props.data };
        let currentPage = props.pageList[getIndexOfCurrentPage()];
        if (currentPage?.prevPage === true && currentPage.prevPageList.length > 0) {
            for (let i = 0; i < (currentPage.prevPageList.length); i++) {
                if (obj[currentPage.prevPageList[i].prevPageFieldName] === currentPage.prevPageList[i].prevPageValue) {
                    let prevPageObj = _.find(props.pageList, ["pageCode", currentPage.prevPageList[i].prevPageCode]);
                    if (prevPageObj && prevPageObj?.pageCode) {
                        navigate(prevPageObj.path + '/' + obj.id);
                        break;
                    }
                }
            }
        }
        else if (currentPage?.prevPageCode) {
            let prevPageObj = _.find(props.pageList, ["pageCode", currentPage.prevPageCode]);
            navigate(prevPageObj.path + '/' + obj.id);
        }
        else {
            let url = props.pageList[getIndexOfCurrentPage() - 1].path;
            if (props.currentPageCode != appConstants.study_title_pagecode)
                url += '/' + props.data.id;
            navigate(url);
        }
    }

    const nextControlFunc = () => {
        if (props.hasOwnProperty('intermediateFunc')) {
            let result = props.intermediateFunc()
            if (result !== true) {
                return
            }
        }
        if (props.currentPageCode === appConstants.data_entry_pagecode || props.currentPageCode === appConstants.stat_test_var_pagecode) {
            props.saveFunc(props.pageList[getIndexOfCurrentPage() + 1].path)
        } else {
            if (props.data.id) {
                put(updateStudy(props.data.id), props.data).then(function (result) {
                    if (result) {
                        // console.log(props.data)
                        gotoNextPage(props.data);
                    }
                });
            } else {
                post(createStudy(), props.data).then(function (result) {
                    if (result.statusCode == 400) {
                        props.triggerToastMessage({ isOpen: true, text: result.message, level: 'error' });
                    }
                    else {
                        gotoNextPage(result);
                    }
                });
            }
        }
    }

    const gotoNextPage = (obj) => {
        if (props.currentPageCode === appConstants.output_pagecode) {
            navigate(props.pageList[0].path);
        } else {
            let currentPage = props.pageList[getIndexOfCurrentPage()];
            if (currentPage?.nextPage === true && currentPage.nextPageList.length > 0) {
                for (let i = 0; i < (currentPage.nextPageList.length); i++) {
                    if (obj[currentPage.nextPageList[i].nextPageFieldName] === currentPage.nextPageList[i].nextPageValue) {
                        let nextPageObj = _.find(props.pageList, ["pageCode", currentPage.nextPageList[i].nextPageCode]);
                        if (nextPageObj && nextPageObj?.pageCode) {
                            if (props.currentPageCode === appConstants.group_variable_pagecode)
                                props.saveFunc(nextPageObj.path);
                            else
                                navigate(nextPageObj.path + '/' + obj.id);
                            break;
                        }
                    }
                }
            }
            else if (currentPage?.nextPageCode) {
                let nextPageObj = _.find(props.pageList, ["pageCode", currentPage.nextPageCode]);
                if ([appConstants.group_variable_pagecode, appConstants.outcome_variable_pagecode, appConstants.explanatory_variable_pagecode].includes(props.currentPageCode))
                    props.saveFunc(nextPageObj.path);
                else
                    navigate(nextPageObj.path + '/' + obj.id);
            }
            else {
                if (props.currentPageCode === appConstants.group_variable_pagecode)
                    props.saveFunc(nextPageObj.path);
                else
                    navigate(props.pageList[getIndexOfCurrentPage() + 1].path + '/' + obj.id);
            }
        }
    }

    return (
        <>
            {props?.showNext === false &&
                <React.Fragment>
                    {/* {!checkIsFirstPage() && */}
                    <IconButton
                        color={'primary'}
                        onClick={prevControlFunc}
                        title={props?.pageList[getIndexOfCurrentPage() - 1]?.name}
                        className='primaryplainbuttons'

                    >
                        <ChevronLeftIcon />
                        Back
                    </IconButton>
                    {/* } */}
                </React.Fragment>
            }
            {props?.showReset && <IconButton
                onClick={() => { props.resetFunction(2) }}
                title={"Reset"}
                className='primaryoutlinedbuttons'>
                Reset
            </IconButton>}
            {(props?.showNext === true && !checkIsLastPage()) &&
                <IconButton
                    onClick={(e) => {
                            if(document.getElementById('nextButton'))
                            {
                                document.getElementById('nextButton').disabled = true;
                                document.getElementById('nextButton').style.opacity = 0.5;
                                document.getElementById('nextButton').style.cursor = 'wait';
                            }
                            setTimeout((e) => {
                                if(document.getElementById('nextButton'))
                                {
                                    document.getElementById('nextButton').disabled = false;
                                    document.getElementById('nextButton').style.opacity = 1;
                                    document.getElementById('nextButton').style.cursor = 'pointer';
                                }
                            }, 5000);
                            nextControlFunc();
                        }
                    }
                    id={'nextButton'}
                    title={props?.pageList[getIndexOfCurrentPage() + 1]?.name}
                    className='primaryplainbuttons'
                >
                    Next
                    <ChevronRightIcon />
                </IconButton>
            }
            {props?.showNext === true && checkIsLastPage() &&
                <IconButton
                    onClick={(e) => {
                            if(document.getElementById('finishButton'))
                            {
                                document.getElementById('finishButton').disabled = true;
                                document.getElementById('finishButton').style.opacity = 0.5;
                                document.getElementById('finishButton').style.cursor = 'wait';
                            }
                            setTimeout((e) => {
                                if(document.getElementById('finishButton'))
                                {
                                    document.getElementById('finishButton').disabled = false;
                                    document.getElementById('finishButton').style.opacity = 1;
                                    document.getElementById('finishButton').style.cursor = 'pointer';
                                }
                            }, 5000);
                            nextControlFunc();
                        }
                    }
                    id={'finishButton'}
                    title={"Finish"}
                    className='primaryfilledbuttons'
                >
                    Finish&nbsp;
                    <DoneAllIcon />
                </IconButton>
            }
        </>
    );
}