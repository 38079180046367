import React from "react";
import { getStudy, updateStudy } from "../../Api/StudyApi";
import { useNavigate, useParams } from "react-router-dom";
import put from "../../Api/Config/PutConfig";
import get from "../../Api/Config/GetConfig";
import { Study } from "../../Models/Study";
import { Grid, IconButton } from "@mui/material";
import * as appConstants from "../../AppConstants";
import deleteIcon from './../../images/delete.svg';
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import NextPrevButton from "../../Components/NextPrevButton";

function TimePoints() {
    const navigate = useNavigate();
    const { studyId } = useParams();
    const [studyObj, setStudyObj] = React.useState(new Study());
    const [groupLength, setGroupLength] = React.useState(0);
    const currentPageCode = appConstants.timepoints_pagecode;

    const gotoPreviousPage = (sId) => {
        navigate("/studyExamining/" + sId);
    };

    const gotoNextPage = (sId) => {
        saveStudy();
    };

    const saveStudy = () => {
        put(updateStudy(studyId), studyObj).then(function (result) {
            if (result) {
                navigate("/outcomeVariable/" + studyId);
            }
        }
        );
    };

    const deleteTimepoints = (id, index) => {
        let optList = studyObj.timepoints;
        optList.splice(index, 1);
        setStudyObj((prevState) => ({
            ...prevState,
            "timepoints": optList,
        }));
    };

    const handleUserInputOptions = (event, index) => {
        let optList = studyObj.timepoints;
        optList[index] = event.target.value;
        setStudyObj((prevState) => ({
            ...prevState,
            "timepoints": optList,
        }));
    };

    React.useEffect(() => {
        get(getStudy(studyId)).then((res) => {
            if (res.no_of_times_examining == appConstants.examining_study_options[0].value) {
                if ((!res.timepoints) || (res.timepoints.length < 1)) {
                    res.timepoints = ["", ""];
                }
                setGroupLength(2);
            }
            else if (res.no_of_times_examining == appConstants.examining_study_options[1].value) {
                if ((!res.timepoints) || (res.timepoints.length < 1)) {
                    res.timepoints = ["", "", ""];
                }
                setGroupLength(3);
            }
            setStudyObj(res);
        });
    }, []);

    const addSubcategory = () => {
        let optList = studyObj.timepoints;
        optList.push("");
        setStudyObj((prevState) => ({
            ...prevState,
            "timepoints": optList,
        }));
        console.log("calling", optList)
    };

    return (
        <>
            <div className="title-frame">
                {(studyObj?.title && studyObj?.mode_of_data_entry) &&
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <NextPrevButton
                            currentPageCode={currentPageCode}
                            data={studyObj}
                            pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]} showNext={false} />
                        </Grid>
                        <Grid item>
                            <NextPrevButton
                                currentPageCode={currentPageCode}
                                data={studyObj}
                                pageList={[...appConstants.project_flow[studyObj?.mode_of_data_entry]]}
                                showNext={true}
                            />
                        </Grid>
                    </Grid>
                }
                <div className="content-frame">
                    <div className="what-is-the">{appConstants.timepoint_question}</div>
                    <div className="a-brief-description">
                        {/* A brief description of this section can go here */}
                    </div>
                </div>
                <div className="white-card-layout-with-shadow" style={{ maxWidth: '70vh' }}>
                    <Grid container spacing={2}
                        alignItems="center">
                        {studyObj.timepoints.map((option, index) => {
                            return (
                                <React.Fragment
                                    key={"option_" + index}>
                                    <Grid item xs={10} md={11}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic" variant="outlined"
                                            label={"Option" + (index + 1)}
                                            value={option}
                                            onChange={(event) => {
                                                handleUserInputOptions(
                                                    event,
                                                    index
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2} md={1}>
                                        {(studyObj.timepoints.length > groupLength) &&
                                            <img className="delete-btn" alt="Delete Option" src={deleteIcon} onClick={() =>
                                                deleteTimepoints(
                                                    option.id,
                                                    index
                                                )
                                            } />
                                        }
                                    </Grid>
                                </React.Fragment>
                            )
                        })
                        }
                        {studyObj.no_of_times_examining == appConstants.examining_study_options[1].value && (
                            <Grid item xs={12}>
                                <IconButton
                                    onClick={() => { addSubcategory() }}
                                    title={'Add More Options'}
                                    className='primaryfilledbuttons'
                                >
                                    <AddIcon /> Add More Options
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </div >
            </div >
        </>
    );
}

export default TimePoints;
